import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import MdSearch from "react-ionicons/lib/MdSearch";
import IosAdd from "react-ionicons/lib/IosAdd";

import classes from "./AddCategoryDialog.scss";

import {
  toCaps,
  toLower,
  updateObject,
  checkIsEmail,
  isStringEmpty
} from "../../../share/js/utility";

import {
  dangerColor,
  warningColor,
  primaryBtnColor,
  searchBtnColor
} from "../../../share/style/_variables";

import DialogForm from "../DialogForm/DialogForm";
import Input from "../../Settings/Form/Input/Input";
import Select from "../../Settings/Form/Select/Select";
import InfoDialog from "../../../components/InfoDialog/InfoDialog";
import TextBtn from "../../../components/TextBtn/TextBtn";
import { lang } from "../../../share/js/localization";
import { acCategory } from "../../../stores/actions";

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit
  }
}))(MuiDialogActions);

class AddCategoryDialog extends React.Component {
  constructor() {
    super();

    this.state = {
      info: {
        fieldError: false,
        fieldErrorMessage: ""
      },
      form: {
        name: ""
      }
    };
  }

  handleClickOpen = () => {
    this.props.visibilityHandler();
  };

  handleClose = () => {
    this.props.visibilityHandler();
  };

  addHandler = () => {
    console.log("added info", this.state.form);
    let { name } = this.state.form;

    if (isStringEmpty(name)) {
      this.setState(state => ({
        ...state,
        showCannotAddInfo: true
      }));
      return;
    }

    // POST CATEGORY
    this.props.postCategoryHandler(name);
  };

  nameInputOnChangeHandler = evt => {
    const value = evt.target.value;

    this.setState(state =>
      updateObject(state, {
        form: {
          ...state.form,
          name: value
        }
      })
    );
  };

  resetNameInput = () => {
    this.setState(state =>
      updateObject(state, {
        form: {
          ...state.form,
          name: ""
        }
      })
    );
  };

  okInfoHandler = section => {
    if (section === "fieldError") {
      this.setState({
        info: {
          fieldError: false,
          fieldErrorMessage: ""
        }
      });
      return;
    }

    if (section === "customError") {
      this.props.clearPostCategoryState();
    }

    if (section === "success") {
      this.props.clearPostCategoryState();
      this.resetNameInput();

      this.props.getAllCategories();
    }
  };

  parsedError = () => {
    let error = this.props.error || lang.generalErrorMessage;

    if (error) {
      if (error.includes(`Duplicate entry`)) {
        error = lang.formatString(
          lang.fieldAlreadyExistError,
          this.state.form.name
        );
      }

      if (error.includes("Cannot delete or update")) {
        return lang.formatString(
          lang.CannotDeleteFieldInUseBy,
          lang.category,
          lang.product
        );
      }
    }

    return error;
  };

  render() {
    const { name } = this.state.form;
    const { show } = this.props;

    const { fieldError, fieldErrorMessage } = this.state.info;

    let customError =
      !this.props.postCategorySuccess && !isStringEmpty(this.props.error);
    let success =
      this.props.postCategorySuccess && isStringEmpty(this.props.error);

    console.log(
      this.props.postCategorySuccess,
      isStringEmpty(this.props.error)
    );

    return (
      <div>
        <InfoDialog
          show={fieldError}
          message={fieldErrorMessage}
          okHandler={() => this.okInfoHandler("fieldError")}
        />
        <InfoDialog
          show={customError}
          message={this.parsedError()}
          okHandler={() => this.okInfoHandler("customError")}
        />
        <InfoDialog
          show={success}
          message={lang.generalSuccess}
          okHandler={() => this.okInfoHandler("success")}
        />
        <Dialog
          // onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={show}
          maxWidth="lg"
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            {this.props.title}
          </DialogTitle>
          <DialogContent className={classes.ViewDialog}>
            <DialogForm>
              <Input
                label={lang.name}
                defaultValue={name}
                onChange={this.nameInputOnChangeHandler}
              />
            </DialogForm>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={this.handleClose} color="primary">
              Save changes
            </Button> */}
            <TextBtn
              title={toCaps(lang.add)}
              onClick={this.addHandler}
              fit
              style={{
                grouper: { justifyContent: "center" },
                btn: { background: primaryBtnColor }
              }}
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    categories: state.categoryReducer.categories,
    categoryLoading: state.categoryReducer.loading,
    error: state.categoryReducer.error,
    postCategorySuccess: state.categoryReducer.postCategorySuccess
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearPostCategoryState: _ => dispatch(acCategory.clearPostCategoryState()),
    getAllCategories: _ => dispatch(acCategory.getAllCategories())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddCategoryDialog);
