import React, { Component } from "react";
import { connect } from "react-redux";
import IosMoreOutline from "react-ionicons/lib/IosMoreOutline";
import IosAdd from "react-ionicons/lib/IosAdd";
import IosRemove from "react-ionicons/lib/IosRemove";
import MdCreate from "react-ionicons/lib/MdCreate";

import Aux from "../../hoc/Aux/Aux";

import classes from "./Settings.scss";
import Section from "./Section/Section";
import Form from "./Form/Form";
import Input from "./Form/Input/Input";
import TextBtn from "../../components/TextBtn/TextBtn";
import { FormControl } from "@material-ui/core";
import Select from "./Form/Select/Select";
import { saveObjectToLocalStorage, checkIsEmail } from "../../share/js/utility";
import { lang } from "../../share/js/localization";
import { toCaps } from "../../share/js/utility";
import EnhancedTable from "./Table/EnhancedTable";
import ViewDialog from "./User/ViewDialog/ViewDialog";
import AlertDialog from "./User/AlertDialog/AlertDialog";
import { warningColor } from "../../share/style/_variables";
import AddDialog from "./User/AddDialog/AddDialog";
import ChangePasswordDialog from "./User/ChangePasswordDialog/ChangePasswordDialog";
import { acAuth, acUser } from "../../stores/actions";
import axios from "../../share/js/axios";
import { TOKEN } from "../../share/js/constants";
import InfoDialog from "../../components/InfoDialog/InfoDialog";

class Settings extends Component {
  constructor(props) {
    super(props);

    // console.log("Settings", props.history.location.state);

    this.state = {
      usernameOrEmail: "",
      info: {
        customErrorMessage: "",
        customError: false,
        success: false,
        generalError: false
      },
      currentLanguage: localStorage.getItem("language") || "id",
      choosedLanguage: localStorage.getItem("language") || "id",
      viewedUserObj: {},
      openViewUserDialog: false,
      openAddUserDialog: false,
      openChangePasswordDialog: false,
      userProfile: {
        email: { value: props.email, editedValue: props.email, edit: false },
        username: {
          value: props.username,
          editedValue: props.username,
          edit: false
        }
      }
    };
  }

  editUserProfilehandler = section => {
    if (section === "password") {
      this.setState(state => {
        return {
          ...state,
          openChangePasswordDialog: true
        };
      });

      console.log("PASSWORD", this.state);
      return;
    }

    this.setState(state => {
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          [section]: {
            ...state.userProfile[section],
            edit: !state.userProfile[section].edit
          }
        }
      };
    });
  };

  componentWillMount() {
    // handle addUser clicked from dashboard
    if (this.props.history.location.state) {
      const { openAddUserDialog } = this.props.history.location.state;

      this.setState(state => ({
        ...state,
        openAddUserDialog: openAddUserDialog || false
      }));
    }

    // console.log("test", this.props.history);

    // clear state.someValue from history
    this.props.history.replace({
      pathname: "/settings",
      state: {}
    });
  }

  refetchUsers = () => {
    // FETCH ALL USERS FOR TABLE

    this.props.getAllUsers();
  };

  componentDidMount() {
    // scroll to top of the page
    window.scrollTo(0, 0);

    // FETCH ALL USERS FOR TABLE
    this.props.getAllUsers();
  }

  viewUserDialogVisibilityHandler = () => {
    this.setState(state => ({
      openViewUserDialog: !state.openViewUserDialog
    }));
  };

  changePasswordDialogVisibilityHandler = () => {
    this.setState(state => ({
      openChangePasswordDialog: !state.openChangePasswordDialog
    }));
  };

  addUserDialogVisibilityHandler = () => {
    this.setState(state => ({
      openAddUserDialog: !state.openAddUserDialog
    }));
  };

  changeLanguageHandler = event => {
    this.setState({ choosedLanguage: event.target.value });
  };

  saveLanguageHandler = () => {
    saveObjectToLocalStorage({
      language: this.state.choosedLanguage
    });

    window.location.reload();
  };

  usernameOrEmailHandler = evt => {
    const value = evt.target.value;
    console.log(value);

    this.setState(state => {
      return {
        ...state,
        usernameOrEmail: value
      };
    });
  };

  userProfileSaveChangeshandler = async _ => {
    //TODO: dispatch action to update userProfile

    let { username, email } = this.state.userProfile;
    let usernameValue = username.editedValue;
    let emailValue = email.editedValue;

    if (usernameValue.trim().length < 6) {
      this.setState(state => ({
        info: {
          ...state.info,
          customError: true,
          customErrorMessage: "Username must be minimum 6 characters."
        }
      }));
      return;
    }

    if (!checkIsEmail(emailValue)) {
      this.setState(state => ({
        info: {
          ...state.info,
          customError: true,
          customErrorMessage: "Email is not valid."
        }
      }));
      return;
    }

    this.setState({ loading: true });

    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-auth": localStorage.getItem(TOKEN)
      }
    };

    // CHANGE PASSWORD
    await axios
      .post(
        "/users/update",
        {
          username: usernameValue,
          email: emailValue
        },
        config
      )
      .then(res => {
        const { sqlMessage } = res.data.data;

        if (sqlMessage) {
          this.setState(state => ({
            info: {
              ...state.info,
              customError: true,
              customErrorMessage: sqlMessage
            }
          }));

          return;
        }

        // update user data
        const data = {
          username: usernameValue,
          email: emailValue
        };
        this.props.updateUserData(data);

        // show InfoDialog
        this.setState(state => ({
          info: {
            ...state.info,
            success: true,
            customErrorMessage: ""
          }
        }));
      })
      .catch(err => {
        // console.log("err /changePassword:", err);

        this.setState(state => ({
          info: {
            ...state.info,
            generalError: true
          }
        }));
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  okInfoHandler = section => {
    const { email, username } = this.state.userProfile;
    const emailValue = email.editedValue;
    const usernameValue = username.editedValue;

    let stateObj = state => ({
      info: {
        ...state.info,
        [section]: false
      }
    });

    if (section === "success") {
      stateObj = state => ({
        info: {
          ...state.info,
          success: false
        },
        userProfile: {
          email: { value: emailValue, editedValue: emailValue, edit: false },
          username: {
            value: usernameValue,
            editedValue: usernameValue,
            edit: false
          }
        }
      });

      // re-fetch users
      this.props.getAllUsers();
    }

    this.setState(state => stateObj(state));
  };

  userProfileOnChangeHandler = (section, evt) => {
    const editedValue = evt.target.value;

    this.setState(state => {
      return {
        ...state,
        userProfile: {
          ...state.userProfile,
          [section]: { ...state.userProfile[section], editedValue }
        }
      };
    });
  };

  viewOnClickHandler = userObj => {
    this.setState(state =>
      Object.assign(state, {
        viewedUserObj: userObj
      })
    );
    this.viewUserDialogVisibilityHandler();
  };

  addUserOnClickHandler = () => {
    this.addUserDialogVisibilityHandler();
  };

  render() {
    console.log("authReducer", this.props.authReducer);
    let userProfileSaveChangesBtn = null;

    const {
      customErrorMessage,
      customError,
      success,
      generalError
    } = this.state.info;

    const { email, username } = this.state.userProfile;

    const usersData = this.props.users.filter(user => {
      // console.log(user.username, username.value, user.username == username);
      return user.username != username.value;
    });

    if (
      email.editedValue.trim() !== email.value.trim() ||
      username.editedValue.trim() !== username.value.trim()
    ) {
      userProfileSaveChangesBtn = (
        <Input style={{ justifyContent: "center", alignItems: "center" }}>
          <TextBtn
            title={toCaps(lang.saveChanges)}
            onClick={this.userProfileSaveChangeshandler}
            fit
            style={{
              grouper: { justifyContent: "center", marginTop: "30px" },
              btn: { background: warningColor }
            }}
          />
        </Input>
      );
    }

    let usersLayout = null;

    console.log("this.props.isAdmin::::::", this.props.isAdmin);

    usersLayout = (
      <Aux>
        <Section title={toCaps(lang.userProfile)}>
          <Form>
            <Input
              smallBtns={
                this.props.isAdmin == 1
                  ? [
                      {
                        Symbol: MdCreate,
                        symbolSize: "22px",
                        onClick: () => this.editUserProfilehandler("email")
                      }
                    ]
                  : []
              }
              label="Email"
              defaultValue={this.state.userProfile.email.editedValue}
              onChange={evt => this.userProfileOnChangeHandler("email", evt)}
              disabled={
                this.props.isAdmin == 1
                  ? !this.state.userProfile.email.edit
                  : true
              }
            />

            <Input
              smallBtns={
                this.props.isAdmin == 1
                  ? [
                      {
                        Symbol: MdCreate,
                        symbolSize: "22px",
                        onClick: () => this.editUserProfilehandler("username")
                      }
                    ]
                  : []
              }
              label="Username"
              defaultValue={this.state.userProfile.username.editedValue}
              onChange={evt => this.userProfileOnChangeHandler("username", evt)}
              disabled={
                this.props.isAdmin == 1
                  ? !this.state.userProfile.username.edit
                  : true
              }
            />

            <Input
              bigBtn={{
                title: toCaps(lang.changePassword),
                onClick: () => this.editUserProfilehandler("password")
              }}
              label="Password"
            />
            {userProfileSaveChangesBtn}
          </Form>
        </Section>

        {this.props.isAdmin == 1 && (
          <Section title="Users">
            <div>
              <Form>
                <Input
                  label={lang.usernameOrEmail}
                  onChange={this.usernameOrEmailHandler}
                  autoComplete={false}
                />
                <Input>
                  <TextBtn
                    title={toCaps(lang.addUser)}
                    onClick={this.addUserOnClickHandler}
                    fit
                    style={{}}
                  />
                </Input>
              </Form>
              <EnhancedTable
                toolbarTitle={"Users"}
                usernameOrEmail={this.state.usernameOrEmail || ""}
                viewOnClickHandler={this.viewOnClickHandler}
                userLoading={this.props.userLoading}
                users={usersData}
              />
            </div>
          </Section>
        )}
      </Aux>
    );

    return (
      <div className={classes.Settings}>
        <InfoDialog
          show={generalError}
          message={lang.generalErrorMessage}
          okHandler={() => this.okInfoHandler("generalError")}
        />
        <InfoDialog
          show={customError}
          message={customErrorMessage}
          okHandler={() => this.okInfoHandler("customError")}
        />
        <InfoDialog
          show={success}
          message={lang.updateMessage}
          okHandler={() => this.okInfoHandler("success")}
        />
        <AddDialog
          title={toCaps(lang.addUser)}
          viewedUserObj={this.state.viewedUserObj}
          visibilityHandler={this.addUserDialogVisibilityHandler}
          show={this.state.openAddUserDialog}
          addUser={this.props.addUser}
          history={this.props.history}
          refetchUsers={this.refetchUsers}
        />

        <ViewDialog
          title={toCaps(lang.viewUser)}
          viewedUserObj={this.state.viewedUserObj}
          visibilityHandler={this.viewUserDialogVisibilityHandler}
          show={this.state.openViewUserDialog}
          refetchUsers={this.refetchUsers}
        />
        <ChangePasswordDialog
          title={toCaps(lang.changePassword)}
          viewedUserObj={this.state.viewedUserObj}
          visibilityHandler={this.changePasswordDialogVisibilityHandler}
          show={this.state.openChangePasswordDialog}
          // changePassword={this.props.changePassword}
        />

        {usersLayout}
        <Section title={toCaps(lang.language)}>
          <div
            style={{
              display: "flex",
              flexFlow: "column",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <FormControl className={classes.margin}>
              <Select
                value={this.state.choosedLanguage}
                onChange={this.changeLanguageHandler}
                options={[
                  {
                    text: "Bahasa",
                    value: "id"
                  },
                  {
                    text: "English",
                    value: "en"
                  }
                ]}
              />
            </FormControl>
            {this.state.currentLanguage ===
            this.state.choosedLanguage ? null : (
              <TextBtn
                title={toCaps(lang.saveChanges)}
                onClick={this.saveLanguageHandler}
                fit
                style={{
                  grouper: { justifyContent: "center", marginTop: "30px" },
                  btn: { background: warningColor }
                }}
              />
            )}
          </div>
        </Section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    username: state.authReducer.username || "",
    email: state.authReducer.email || "",
    isAdmin: state.authReducer.isAdmin || 0,
    authReducer: state.authReducer,
    users: state.userReducer.users,
    userLoading: state.userReducer.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // changePassword: (currentPassword, newPassword) =>
    //   dispatch(acAuth.changePassword(currentPassword, newPassword))
    getAllUsers: _ => dispatch(acUser.getAllUsers()),
    updateUserData: data => dispatch(acAuth.success(data)),
    addUser: data => dispatch(acUser.postUser(data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings);
