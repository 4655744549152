import React, { Component } from "react";
import { connect } from "react-redux";
import { PulseLoader } from "react-spinners";

import classes from "./Vendor.scss";
import EnhancedTable from "./Table/EnhancedTable";
import Section from "./Section/Section";
import Form from "../Settings/Form/Form";
import Input from "../Settings/Form/Input/Input";
import {
  toCaps,
  toLower,
  updateObject,
  isStringEmpty
} from "../../share/js/utility";
import TextBtn from "../../components/TextBtn/TextBtn";
import { lang } from "../../share/js/localization";
import Select from "../Settings/Form/Select/Select";
import AddDialog from "./AddDialog/AddDialog";
import { searchBtnColor } from "../../share/style/_variables";
import ViewDialog from "./ViewDialog/ViewDialog";
import Content from "./AddDialog/Content/Content";
import { acVendor } from "../../stores/actions";
import InfoDialog from "../../components/InfoDialog/InfoDialog";
import Aux from "../../hoc/Aux/Aux";
// import ViewDialog from "./Dialog/Dialog_bu";

// NOTE: FormVendor only handle "View", "Add" has its own => "AddVendor"
class FormVendor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      info: {
        fieldError: false,
        fieldErrorMessage: ""
      },
      edit: false,
      form: this.formDefault(),
      initialForm: this.formDefault()
    };
  }

  componentWillMount() {
    if (this.props.data) {
      const {
        name,
        addresses,
        emails,
        phones,
        contactPersons
      } = this.props.data;

      this.setState({
        form: {
          name,
          addresses,
          emails,
          phones,
          contactPersons: JSON.parse(JSON.stringify(contactPersons))
        },
        initialForm: {
          name,
          addresses,
          emails,
          phones,
          contactPersons: JSON.parse(JSON.stringify(contactPersons))
        }
      });
    }
  }

  formDefault = _ => ({
    name: "",
    addresses: [""],
    emails: [""],
    phones: [""],
    contactPersons: [this.contactPersonObj()]
  });

  contactPersonObj = _ => ({
    fname: "",
    lname: "",
    phones: [""],
    emails: [""]
  });

  resetFormInput = _ => {
    this.setState({
      form: this.formDefault(),
      initialForm: this.formDefault()
    });
  };

  addAddressHandler = () => {
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        addresses: [...state.form.addresses].concat("")
      }
    }));
  };

  removeAddressHandler = () => {
    if (this.state.form.addresses.length === 1) return;
    this.setState(state => {
      const copied = [...state.form.addresses];
      copied.pop();

      return {
        ...state,
        form: {
          ...state.form,
          addresses: copied
        }
      };
    });
  };

  addEmailHandler = () => {
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        emails: [...state.form.emails].concat("")
      }
    }));
  };
  removeEmailHandler = () => {
    if (this.state.form.emails.length === 1) return;
    this.setState(state => {
      const copied = [...state.form.emails];
      copied.pop();

      return {
        ...state,
        form: {
          ...state.form,
          emails: copied
        }
      };
    });
  };

  addPhoneHandler = () => {
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        phones: [...state.form.phones].concat("")
      }
    }));
  };
  removePhoneHandler = () => {
    if (this.state.form.phones.length === 1) return;

    this.setState(state => {
      const copied = [...state.form.phones];
      copied.pop();

      return {
        ...state,
        form: {
          ...state.form,
          phones: copied
        }
      };
    });
  };

  addContactPersonHandler = () => {
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        contactPersons: [...state.form.contactPersons].concat(
          this.contactPersonObj()
        )
      }
    }));
  };
  removeContactPersonHandler = () => {
    if (this.state.form.contactPersons.length === 1) return;

    this.setState(state => {
      const copied = [...state.form.contactPersons];
      copied.pop();

      return {
        ...state,
        form: {
          ...state.form,
          contactPersons: copied
        }
      };
    });
  };
  addContactPersonPhoneHandler = index => {
    this.setState(state => {
      const copied = [...state.form.contactPersons];
      copied[index].phones.push("");

      return {
        ...state,
        form: {
          ...state.form,
          contactPersons: copied
        }
      };
    });

    console.log(this.state);
  };
  removeContactPersonPhoneHandler = index => {
    if (this.state.form.contactPersons[index].phones.length === 1) return;

    this.setState(state => {
      const copied = [...state.form.contactPersons];
      copied[index].phones.pop();

      return {
        ...state,
        form: {
          ...state.form,
          contactPersons: copied
        }
      };
    });
  };
  addContactPersonEmailHandler = index => {
    this.setState(state => {
      const copied = [...state.form.contactPersons];
      copied[index].emails.push("");

      return {
        ...state,
        form: {
          ...state.form,
          contactPersons: copied
        }
      };
    });

    console.log(this.state);
  };
  removeContactPersonEmailHandler = index => {
    if (this.state.form.contactPersons[index].emails.length === 1) return;

    this.setState(state => {
      const copied = [...state.form.contactPersons];
      copied[index].emails.pop();

      return {
        ...state,
        form: {
          ...state.form,
          contactPersons: copied
        }
      };
    });
  };

  inputOnChangeHandler = (evt, key) => {
    const value = evt.target.value;
    const splittedKey = key.split("_");

    if (splittedKey.length > 1) {
      const splittedKeyIndex = splittedKey[splittedKey.length - 1];

      if (
        splittedKey[0] === "addresses" ||
        splittedKey[0] === "emails" ||
        splittedKey[0] === "phones"
      ) {
        this.setState(state => {
          const copied = state.form[splittedKey[0]].concat(); // copy of addresses array
          copied[splittedKeyIndex] = value;

          return updateObject(state, {
            form: {
              ...state.form,
              [splittedKey[0]]: copied
            }
          });
        });
      }

      console.log(splittedKey);

      if (splittedKey[0] === "contactPersons") {
        if (splittedKey.length < 4) {
          // ex. ["contactPersons", "0", "fname"]
          const contactPersons = splittedKey[0];
          const parentKey = splittedKey[1];
          const contactPersonChild = splittedKey[2];

          this.setState(state => {
            const copied = state.form[contactPersons].concat(); // copy of contactPersons array
            copied[parentKey][contactPersonChild] = value;

            return updateObject(state, {
              form: {
                ...state.form,
                contactPersons: copied
              }
            });
          });

          return;
        }

        // ex. ["contactPersons", "0", "phones", "0"]
        const contactPersons = splittedKey[0];
        const parentKey = splittedKey[1];
        const contactPersonChild = splittedKey[2];
        const childKey = splittedKey[3];

        this.setState(state => {
          const copied = state.form[contactPersons].concat(); // copy of contactPersons array
          copied[parentKey][contactPersonChild][childKey] = value;

          return updateObject(state, {
            form: {
              ...state.form,
              contactPersons: copied
            }
          });
        });
      }

      return;
    }

    this.setState(state =>
      updateObject(state, {
        form: {
          ...state.form,
          [key]: value
        }
      })
    );
  };

  // okInfoHandler = () => {
  //   this.setState({ showAddedInfo: false });
  //   this.resetFormInput();
  // };

  // addVendorHandler = () => {
  //   alert("add_vendor");
  // };

  deleteVendorHandler = () => {
    const { id: vendorId } = this.props.data;

    // DELETE VENDOR
    this.props.deleteVendorById(vendorId);
  };

  saveChangesHandler = () => {
    let { name } = this.state.form;
    name = name.trim();

    if (name.length < 1) {
      this.setState({
        info: {
          fieldError: true,
          fieldErrorMessage: lang.formatString(
            lang.fieldMustBeFill,
            `*${toCaps(lang.name)}*`
          )
        }
      });
      return;
    }

    const { id: vendorId } = this.props.data;

    // UPDATE VENDOR
    this.props.updateVendorById(vendorId, this.state.form);
  };

  backToVendorHandler = () => {
    this.props.history.replace({
      pathname: "/vendor"
    });
  };

  editHandler = () => {
    this.setState(state => ({
      edit: !state.edit
    }));
  };

  cancelEditHandler = () => {
    this.setState(state => ({
      edit: false,
      form: JSON.parse(JSON.stringify(state.initialForm))
    }));
  };

  okInfoHandler = section => {
    if (section === "fieldError") {
      this.setState({
        info: {
          fieldError: false,
          fieldErrorMessage: ""
        }
      });
      return;
    }

    if (section === "deleteSuccess") {
      this.props.clearDeleteVendorState();
      this.props.history.replace({
        pathname: "/vendor"
      });
      return;
    }

    if (section === "customError") {
      this.props.clearDeleteVendorState();
    }

    if (section === "updateSuccess") {
      this.props.clearUpdateVendorState();
    }
  };

  render() {
    const { fieldError, fieldErrorMessage } = this.state.info;

    let deleteCustomError =
      !this.props.deleteVendorSuccess && !isStringEmpty(this.props.error);
    let deleteSuccess =
      this.props.deleteVendorSuccess && isStringEmpty(this.props.error);
    let updateSuccess =
      this.props.updateVendorSuccess && isStringEmpty(this.props.error);

    let formLayout = (
      <Section>
        <div className={classes.AddDialog}>
          <Content
            mode={this.props.mode}
            handler={{
              inputOnChangeHandler: this.inputOnChangeHandler,
              vendorOnSearchHandler: this.vendorOnSearchHandler,
              categoryOnChangeHandler: this.categoryOnChangeHandler,
              addAddressHandler: this.addAddressHandler,
              removeAddressHandler: this.removeAddressHandler,
              addEmailHandler: this.addEmailHandler,
              removeEmailHandler: this.removeEmailHandler,
              addPhoneHandler: this.addPhoneHandler,
              removePhoneHandler: this.removePhoneHandler,
              addContactPersonHandler: this.addContactPersonHandler,
              removeContactPersonHandler: this.removeContactPersonHandler,
              addContactPersonPhoneHandler: this.addContactPersonPhoneHandler,
              removeContactPersonPhoneHandler: this
                .removeContactPersonPhoneHandler,
              addContactPersonEmailHandler: this.addContactPersonEmailHandler,
              removeContactPersonEmailHandler: this
                .removeContactPersonEmailHandler,
              // addVendorHandler: this.addVendorHandler,
              backToVendorHandler: this.backToVendorHandler,
              editHandler: this.editHandler,
              cancelEditHandler: this.cancelEditHandler,
              deleteVendorHandler: this.deleteVendorHandler,
              saveChangesHandler: this.saveChangesHandler
            }}
            inputValue={{
              name: this.state.form.name,
              addresses: this.state.form.addresses,
              emails: this.state.form.emails,
              phones: this.state.form.phones,
              contactPersons: this.state.form.contactPersons
            }}
            edit={this.state.edit}
          />
        </div>
      </Section>
    );

    if (this.props.loading) {
      formLayout = (
        <PulseLoader
          sizeUnit={"px"}
          size={12}
          color={"#123abc"}
          css={{ textAlign: "center" }}
          // loading={this.props.loading}
        />
      );
    }

    return (
      <div className={classes.Product}>
        <Aux>
          <InfoDialog
            show={fieldError}
            message={fieldErrorMessage}
            okHandler={() => this.okInfoHandler("fieldError")}
          />
          <InfoDialog
            show={deleteCustomError}
            message={this.props.error}
            okHandler={() => this.okInfoHandler("customError")}
          />
          <InfoDialog
            show={deleteSuccess}
            message={lang.generalSuccess}
            okHandler={() => this.okInfoHandler("deleteSuccess")}
          />
          <InfoDialog
            show={updateSuccess}
            message={lang.generalSuccess}
            okHandler={() => this.okInfoHandler("updateSuccess")}
          />
        </Aux>
        {formLayout}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.vendorReducer.loading,
    error: state.vendorReducer.error,
    deleteVendorSuccess: state.vendorReducer.deleteVendorSuccess,
    updateVendorSuccess: state.vendorReducer.updateVendorSuccess
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateVendorById: (id, data) =>
      dispatch(acVendor.updateVendorById(id, data)),
    deleteVendorById: id => dispatch(acVendor.deleteVendorById(id)),
    clearDeleteVendorState: _ => dispatch(acVendor.clearDeleteVendorState()),
    clearUpdateVendorState: _ => dispatch(acVendor.clearUpdateVendorState())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormVendor);
