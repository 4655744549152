import React, { Component } from "react";
import RSelect from "react-select";
import IosAdd from "react-ionicons/lib/IosAdd";
import IosRemove from "react-ionicons/lib/IosRemove";

import Select from "../../../Settings/Form/Select/Select";
import Input from "../../../Settings/Form/Input/Input";
import { toLower } from "../../../../share/js/utility";
import DialogForm from "../../../../components/DialogForm/DialogForm";
import {
  searchBtnColor,
  primaryBtnColor,
  dangerColor
} from "../../../../share/style/_variables";
import { lang } from "../../../../share/js/localization";
import SearchableSelect from "../../../../components/SearchableSelect/SearchableSelect";

const Content = props => {
  return (
    <DialogForm>
      <div>
        {/* <Input label={lang.vendor} searchableSelect noMarginLR>
          <RSelect
            className="basic-single"
            classNamePrefix="select"
            defaultValue={null}
            isClearable={true}
            isSearchable={true}
            name="vendor"
            options={props.parsedVendorForSelect}
            onChange={props.handler.vendorOnChangeHandler}
          />
        </Input> */}
      </div>

      <Input
        label={lang.name}
        defaultValue={props.inputValue.name}
        onChange={evt => props.handler.inputOnChangeHandler(evt, "name")}
        noMarginLR
      />

      <Input
        label={lang.category}
        smallBtns={[
          {
            Symbol: IosAdd,
            onClick: props.handler.showCategoryHandler,
            symbolSize: "30px",
            style: {
              backgroundColor: primaryBtnColor
            }
          },
          {
            Symbol: IosRemove,
            onClick: props.handler.deleteCategoryHandler,
            symbolSize: "30px",
            style: {
              backgroundColor: dangerColor
            }
          }
        ]}
        noMarginLR
      >
        <Select
          // value={toLower(this.state.form.type)}
          value={props.inputValue.categoryId}
          onChange={props.handler.categoryOnChangeHandler}
          options={props.parsedCategoryForSelect}
        />
      </Input>
      <Input
        label={lang.description}
        defaultValue={props.inputValue.description}
        onChange={evt => props.handler.inputOnChangeHandler(evt, "description")}
        style={{
          flexDirection: "column",
          alignItems: "flex-start",
          height: "auto",
          marginBottom: 0
        }}
        noMarginLR
        textarea
        rows={5}
      />
    </DialogForm>
  );
};

export default Content;
