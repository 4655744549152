import React, { Component } from "react";
import RSelect from "react-select";

import Select from "../../../Settings/Form/Select/Select";
import Input from "../../../Settings/Form/Input/Input";
import { toLower, toCaps } from "../../../../share/js/utility";
import DialogForm from "../../../../components/DialogForm/DialogForm";
import {
  searchBtnColor,
  primaryBtnColor,
  warningColor,
  backBtnColor,
  editBtnColor
} from "../../../../share/style/_variables";
import { lang } from "../../../../share/js/localization";
import SearchableSelect from "../../../../components/SearchableSelect/SearchableSelect";
import TextBtn from "../../../../components/TextBtn/TextBtn";
import Aux from "../../../../hoc/Aux/Aux";
import InfoDialog from "../../../../components/InfoDialog/InfoDialog";

const Content = props => {
  const { categories, viewData, edit } = props;
  const {
    // vendor: { id: vendorId },
    category: { id: categoryId },
    description,
    name
  } = viewData;

  // console.log("viewData::::", props.viewData);

  const parsedCategoryForSelect = [
    { value: 0, text: "" },
    ...categories.map(category => ({
      value: category.id,
      text: toCaps(category.name)
    }))
  ];

  // const parsedVendorForSelect = props.vendors.map(vendor => ({
  //   value: vendor.id,
  //   label: `${vendor.name}`
  // }));

  // const selectedVendor = parsedVendorForSelect.filter(
  //   vendor => vendor.value == vendorId
  // )[0];

  // console.log(selectedVendor, parsedVendorForSelect);

  return (
    <DialogForm>
      <div>
        {/* <Input label={lang.vendor} searchableSelect noMarginLR>
          <RSelect
            isDisabled={!edit}
            className="basic-single"
            classNamePrefix="select"
            defaultValue={selectedVendor}
            value={selectedVendor}
            isClearable={true}
            isSearchable={true}
            name="vendor"
            options={parsedVendorForSelect}
            onChange={props.handler.vendorOnChangeHandler}
          />
        </Input> */}
      </div>

      <Input
        label={lang.name}
        defaultValue={name}
        onChange={evt => props.handler.inputOnChangeHandler(evt, "name")}
        noMarginLR
        disabled={!edit}
      />

      <Input label={lang.category} noMarginLR>
        <Select
          value={categoryId}
          onChange={props.handler.categoryOnChangeHandler}
          options={parsedCategoryForSelect}
          disabled={!edit}
        />
      </Input>
      <Input
        label={lang.description}
        defaultValue={description}
        onChange={evt => props.handler.inputOnChangeHandler(evt, "description")}
        style={{
          flexDirection: "column",
          alignItems: "flex-start",
          height: "auto",
          marginBottom: 0
        }}
        noMarginLR
        textarea
        rows={5}
        disabled={!edit}
      />
    </DialogForm>
  );
};

export default Content;
