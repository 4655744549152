export const USER_FETCHING = "USER_FETCHING";
export const SET_USER = "SET_USER";
export const SET_USERS = "SET_USERS";
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";

export const AUTH_AUTHENTICATING = "AUTH_AUTHENTICATING";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_SIGN_IN = "AUTH_SIGN_IN";
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT";
export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";
export const CHECK_AUTH_STATE = "CHECK_AUTH_STATE";
export const AUTH_CLEAR_ERROR = "AUTH_CLEAR_ERROR";

//PRODUCT REPORT STATUSES
export const GET_PRODUCT_REPORT_STATUSES = "GET_PRODUCT_REPORT_STATUSES";
export const GET_PRODUCT_REPORT_STATUSES_SUCCESS =
  "GET_PRODUCT_REPORT_STATUSES_SUCCESS";
export const GET_PRODUCT_REPORT_STATUSES_FAIL =
  "GET_PRODUCT_REPORT_STATUSES_FAIL";
export const CLEAR_GET_PRODUCT_REPORT_STATUSES_STATE =
  "CLEAR_GET_PRODUCT_REPORT_STATUSES_STATE";

//REPORT STATUSES
export const GET_REPORT_STATUSES = "GET_REPORT_STATUSES";
export const GET_REPORT_STATUSES_SUCCESS = "GET_REPORT_STATUSES_SUCCESS";
export const GET_REPORT_STATUSES_FAIL = "GET_REPORT_STATUSES_FAIL";
export const CLEAR_GET_REPORT_STATUSES_STATE =
  "CLEAR_GET_REPORT_STATUSES_STATE";

// REPORT
export const REPORT_FETCHING = "REPORT_FETCHING";
export const GET_ALL_REPORTS_SUCCESS = "GET_ALL_REPORTS_SUCCESS";
export const GET_ALL_REPORTS_FAIL = "GET_ALL_REPORTS_FAIL";
export const GET_REPORT_SUCCESS = "GET_REPORT_SUCCESS";
export const GET_REPORT_FAIL = "GET_REPORT_FAIL";

export const CLEAR_POST_REPORT_STATE = "CLEAR_POST_REPORT_STATE";
export const POST_REPORT = "POST_REPORT";
export const POST_REPORT_SUCCESS = "POST_REPORT_SUCCESS";
export const POST_REPORT_FAIL = "POST_REPORT_FAIL";

export const CLEAR_DELETE_REPORT_STATE = "CLEAR_DELETE_REPORT_STATE";
export const DELETE_REPORT = "DELETE_REPORT";
export const DELETE_REPORT_SUCCESS = "DELETE_REPORT_SUCCESS";
export const DELETE_REPORT_FAIL = "DELETE_REPORT_FAIL";

export const CLEAR_UPDATE_REPORT_STATE = "CLEAR_UPDATE_REPORT_STATE";
export const UPDATE_REPORT = "UPDATE_REPORT";
export const UPDATE_REPORT_SUCCESS = "UPDATE_REPORT_SUCCESS";
export const UPDATE_REPORT_FAIL = "UPDATE_REPORT_FAIL";

// CATEGORY
export const CATEGORY_FETCHING = "CATEGORY_FETCHING";
export const GET_ALL_CATEGORIES_SUCCESS = "GET_ALL_CATEGORIES_SUCCESS";
export const GET_ALL_CATEGORIES_FAIL = "GET_ALL_CATEGORIES_FAIL";
export const GET_CATEGORY_SUCCESS = "GET_CATEGORY_SUCCESS";
export const GET_CATEGORY_FAIL = "GET_CATEGORY_FAIL";

export const CLEAR_POST_CATEGORY_STATE = "CLEAR_POST_CATEGORY_STATE";
export const POST_CATEGORY = "POST_CATEGORY";
export const POST_CATEGORY_SUCCESS = "POST_CATEGORY_SUCCESS";
export const POST_CATEGORY_FAIL = "POST_CATEGORY_FAIL";

export const CLEAR_DELETE_CATEGORY_STATE = "CLEAR_DELETE_CATEGORY_STATE";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAIL = "DELETE_CATEGORY_FAIL";

export const CLEAR_UPDATE_CATEGORY_STATE = "CLEAR_UPDATE_CATEGORY_STATE";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAIL = "UPDATE_CATEGORY_FAIL";

// PRODUCT
export const PRODUCT_FETCHING = "PRODUCT_FETCHING";
export const GET_ALL_PRODUCTS_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS";
export const GET_ALL_PRODUCTS_FAIL = "GET_ALL_PRODUCTS_FAIL";
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS";
export const GET_PRODUCT_FAIL = "GET_PRODUCT_FAIL";

export const CLEAR_POST_PRODUCT_STATE = "CLEAR_POST_PRODUCT_STATE";
export const POST_PRODUCT = "POST_PRODUCT";
export const POST_PRODUCT_SUCCESS = "POST_PRODUCT_SUCCESS";
export const POST_PRODUCT_FAIL = "POST_PRODUCT_FAIL";

export const CLEAR_DELETE_PRODUCT_STATE = "CLEAR_DELETE_PRODUCT_STATE";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAIL = "DELETE_PRODUCT_FAIL";

export const CLEAR_UPDATE_PRODUCT_STATE = "CLEAR_UPDATE_PRODUCT_STATE";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL";

// VENDOR
export const VENDOR_FETCHING = "VENDOR_FETCHING";
export const GET_ALL_VENDORS_SUCCESS = "GET_ALL_VENDORS_SUCCESS";
export const GET_ALL_VENDORS_FAIL = "GET_ALL_VENDORS_FAIL";
export const GET_VENDOR_SUCCESS = "GET_VENDOR_SUCCESS";
export const GET_VENDOR_FAIL = "GET_VENDOR_FAIL";

export const CLEAR_POST_VENDOR_STATE = "CLEAR_POST_VENDOR_STATE";
export const POST_VENDOR = "POST_VENDOR";
export const POST_VENDOR_SUCCESS = "POST_VENDOR_SUCCESS";
export const POST_VENDOR_FAIL = "POST_VENDOR_FAIL";

export const CLEAR_DELETE_VENDOR_STATE = "CLEAR_DELETE_VENDOR_STATE";
export const DELETE_VENDOR = "DELETE_VENDOR";
export const DELETE_VENDOR_SUCCESS = "DELETE_VENDOR_SUCCESS";
export const DELETE_VENDOR_FAIL = "DELETE_VENDOR_FAIL";

export const CLEAR_UPDATE_VENDOR_STATE = "CLEAR_UPDATE_VENDOR_STATE";
export const UPDATE_VENDOR = "UPDATE_VENDOR";
export const UPDATE_VENDOR_SUCCESS = "UPDATE_VENDOR_SUCCESS";
export const UPDATE_VENDOR_FAIL = "UPDATE_VENDOR_FAIL";
