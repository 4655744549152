import React, { Component } from "react";
import RSelect from "react-select";

import Select from "../../../Settings/Form/Select/Select";
import Input from "../../../Settings/Form/Input/Input";
import {
  toLower,
  toCaps,
  toUpper,
  getFormattedDate
} from "../../../../share/js/utility";
import DialogForm from "../../../../components/DialogForm/DialogForm";
import {
  searchBtnColor,
  primaryBtnColor,
  backBtnColor,
  warningColor,
  dangerColor,
  editBtnColor
} from "../../../../share/style/_variables";
import { lang } from "../../../../share/js/localization";
import LabelWithBtn from "./LabelWithBtn/LabelWithBtn";
import MinimizeableInput from "./MinimizeableInput/MinimizeableInput";
import classes from "./Content.scss";
import TextBtn from "../../../../components/TextBtn/TextBtn";
import Aux from "../../../../hoc/Aux/Aux";
import DatePicker from "react-datepicker";
import CustomDatePicker from "../../../Settings/Form/Input/CustomDatePicker/CustomDatePicker";
import SearchableSelect from "../../../../components/SearchableSelect/SearchableSelect";
import InputWithChild from "../../../Vendor/AddDialog/Content/InputWithChild/InputWithChild";
import CustomReactToPrint from "../../../../components/CustomReactToPrint/CustomReactToPrint";
import ReportFormPrint from "../../Dialog/ReportFormPrint/ReportFormPrint";

const Content = props => {
  const {
    addAddressHandler,
    removeAddressHandler,
    addEmailHandler,
    removeEmailHandler,
    addPhoneHandler,
    removePhoneHandler,
    addContactPersonHandler,
    removeContactPersonHandler,
    addContactPersonPhoneHandler,
    removeContactPersonPhoneHandler,
    inputOnChangeHandler,
    addContactPersonEmailHandler,
    removeContactPersonEmailHandler,
    addReportHandler,
    backToReportHandler,
    datePickerOnChangeHandler,
    addProductHandler,
    removeProductHandler,
    changeStatusHandler,
    changeProductStatusHandler,
    productNameOnChangeHandler,
    productSerialNumberOnChangeHandler,
    changeProductBillHandler,
    changeProductInputOnChangeHandler,
    changeProductNameOnChangeHandler,
    editHandler,
    cancelEditHandler,
    saveChangesHandler,
    deleteReportHandler,
    changeProductDescriptionHandler,
    datePickerProductOnChangeHandler,
    productVendorOnChangeHandler
  } = props.handler;

  const { mode, inputValue, edit } = props;
  console.log("inputValue", inputValue);

  const { id, entryDate, technician, statusId, description } = inputValue;

  const parsedReportStatusesForSelect = [
    { value: 0, text: "" },
    ...inputValue.reportStatuses.map(reportStatus => ({
      value: reportStatus.id,
      text: reportStatus.title
    }))
  ];

  const parsedProductReportStatusesForSelect = [
    { value: 0, text: "" },
    ...inputValue.productReportStatuses.map(productReportStatus => ({
      value: productReportStatus.id,
      text: productReportStatus.title
    }))
  ];

  const parsedProductForSelect = [
    ...inputValue.selectProducts.map(product => ({
      value: product.id,
      label: product.name,
      category: product.category
      // vendor: { id: product.vendor.id, name: product.vendor.name }
    }))
  ];

  const parsedVendorForSelect = [
    ...inputValue.selectVendors.map(vendor => ({
      value: vendor.id,
      label: vendor.name,
      name: vendor.name
    }))
  ];

  // helper
  const getProductStatusTitleById = id => {
    let title = "";

    if (inputValue.productReportStatuses.length > 0) {
      const productReportStatus = inputValue.productReportStatuses.filter(
        productReportStatus => productReportStatus.id == id
      )[0];

      if (productReportStatus) {
        title = productReportStatus.title;
      }
    }

    return title;
  };

  let garansiProductLayout = (index, changeProduct) => {
    const { name, serialNumber, category, vendor } = changeProduct;

    // react-select
    // avoid warning: A component is changing an uncontrolled input of type hidden to be controlled.
    changeProduct.value = changeProduct.value || "";
    changeProduct.label = changeProduct.label || "";

    return (
      <div style={{ flexBasis: "50%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <h4>Barang Ganti:</h4>
        </div>
        {/* TODO: index => 1_index? 
        productNameOnChangeHandler(1_index, selectedOption) */}
        <Input label={toCaps(lang.name)} medium searchableSelect>
          <RSelect
            isDisabled={editHandler ? !edit : false}
            className="basic-single"
            classNamePrefix="select"
            isClearable={true}
            isSearchable={true}
            name="productOri"
            options={parsedProductForSelect}
            onChange={selectedOption =>
              changeProductNameOnChangeHandler(index, selectedOption)
            }
            {...(() => {
              // ADDITIONAL PROPERTIES TO RSelect component ONLY IF editHandler exists.
              if (editHandler) {
                return {
                  defaultValue: null,
                  value: changeProduct
                };
              }
            })()}
          />
        </Input>
        <Input
          label={lang.category}
          defaultValue={toCaps(category.name)}
          noMarginLR
          medium
          disabled
        />
        {/* <Input
          label={lang.vendor}
          defaultValue={toCaps(vendor.name)}
          noMarginLR
          medium
          disabled
        /> */}
        <Input
          label="Serial Number"
          defaultValue={serialNumber}
          onChange={evt =>
            changeProductInputOnChangeHandler("serialNumber", index, evt)
          }
          noMarginLR
          medium
          disabled={editHandler ? !edit : false}
        />
      </div>
    );
  };

  let componentToPrint = <div />;
  let componentToCustomerPrint = <div />;

  // CONFIGURE PRINT FORM DATA
  if (inputValue) {
    const parsedInputValueToPrintFormat = () => {
      const {
        id,
        statusId,
        technician,
        entryDate,
        contactPersons,
        description,
        products,

        // for select-options
        selectProducts,
        reportStatuses,
        productReportStatuses
      } = inputValue;

      const parsedProducts = products.map(p => {
        let change = {};
        let original = {};

        const originalStatus = productReportStatuses.filter(
          prs => prs.id == p.status.id
        )[0];

        if (originalStatus && toLower(originalStatus.title) == "garansi") {
          if (p.changeProduct) {
            change = {
              name: p.changeProduct.name,
              category: p.changeProduct.category.name,
              // vendor: p.changeProduct.vendor.name,
              serialNumber: p.changeProduct.serialNumber
            };
          }
        }

        original = {
          name: p.name,
          category: p.category.name,
          vendor: p.vendor.name,
          serialNumber: p.serialNumber,
          status: originalStatus ? originalStatus.title : "",

          buyDate: p.buyDate ? getFormattedDate(new Date(p.buyDate)) : "",
          returnDate: p.returnDate
            ? getFormattedDate(new Date(p.returnDate))
            : "",
          explanationDescription: p.explanationDescription,
          problemDescription: p.problemDescription,
          complementDescription: p.complementDescription
        };

        if (originalStatus && toLower(originalStatus.title) == "servis") {
          original.bill = `Rp ${p.bill || 0}`;
        }

        if (originalStatus && toLower(originalStatus.title) == "garansi") {
          return {
            original,
            change
          };
        }

        return {
          original
        };
      });

      console.log("products:::::::", parsedProducts);

      return {
        id: `${id}`,
        entryDate: getFormattedDate(entryDate),
        technician: technician,
        status: reportStatuses.filter(rs => rs.id == statusId)[0]
          ? reportStatuses.filter(rs => rs.id == statusId)[0].title
          : "",
        description: description,
        products: parsedProducts,
        customer: contactPersons[0]
      };
    };

    const data = parsedInputValueToPrintFormat();

    componentToPrint = (
      <div>
        <ReportFormPrint data={data} />
      </div>
    );
    componentToCustomerPrint = (
      <div>
        <ReportFormPrint data={data} customer />
      </div>
    );
  }

  let editResetBtn = (
    <TextBtn
      title={toCaps(lang.edit)}
      onClick={editHandler}
      fit
      style={{
        grouper: { justifyContent: "center", marginRight: "auto" },
        btn: { background: editBtnColor }
      }}
    />
  );

  let printBtn = (
    <Input style={{ justifyContent: "center", margin: 0, marginLeft: "5px" }}>
      <CustomReactToPrint componentToPrint={componentToPrint} />
    </Input>
  );

  let printCustomerBtn = (
    <Input style={{ justifyContent: "center", margin: 0, marginLeft: "5px" }}>
      <CustomReactToPrint
        title="Print Customer"
        componentToPrint={componentToCustomerPrint}
      />
    </Input>
  );

  let saveChangesBtn = null;

  if (edit) {
    editResetBtn = (
      <TextBtn
        title={toCaps(lang.cancelEdit)}
        onClick={cancelEditHandler}
        fit
        style={{
          grouper: { justifyContent: "center", marginRight: "auto" },
          btn: { background: backBtnColor }
        }}
      />
    );

    saveChangesBtn = (
      <TextBtn
        title={toCaps(lang.saveChanges)}
        onClick={saveChangesHandler}
        fit
        style={{
          grouper: { justifyContent: "center" },
          btn: { background: warningColor }
        }}
      />
    );
  }

  return (
    <div>
      {/* {componentToPrint} */}
      <div className={classes.Header}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%"
          }}
        >
          <TextBtn
            title={toCaps(lang.back)}
            onClick={backToReportHandler}
            fit
            style={{
              grouper: { justifyContent: "center" },
              btn: { background: backBtnColor }
            }}
          />
          <div style={{ display: "flex" }}>
            {mode === "view" && !edit ? <Aux>{printCustomerBtn}</Aux> : null}
            {mode === "view" && !edit ? <Aux>{printBtn}</Aux> : null}
          </div>
        </div>
      </div>
      <div className={classes.Content}>
        <div className={classes.SubContent}>
          <DialogForm style={{ margin: "0" }}>
            <Input
              label={"id"}
              defaultValue={id}
              noMarginLR
              medium
              style={{ margin: "7.5px 0" }}
              disabled
            />

            <CustomDatePicker
              label={toCaps(lang.entryDate)}
              selected={entryDate}
              onChange={datePickerOnChangeHandler}
              style={{ input: { margin: "7.5px 0" } }}
              disabled={editHandler ? !edit : false}
            />
            <Input
              label={toCaps(lang.technician)}
              defaultValue={technician}
              onChange={evt => inputOnChangeHandler(evt, "technician")}
              noMarginLR
              medium
              style={{ margin: "7.5px 0" }}
              disabled={editHandler ? !edit : false}
            />

            <Input label={"Status"} medium>
              <div style={{ flexBasis: "50%" }}>
                <Select
                  value={statusId}
                  onChange={changeStatusHandler}
                  options={parsedReportStatusesForSelect}
                  disabled={editHandler ? !edit : false}
                />
              </div>
            </Input>
          </DialogForm>
        </div>

        <div className={classes.SubContent}>
          <DialogForm style={{ margin: "0" }}>
            <span>Customer:</span>

            {props.inputValue.contactPersons.map((contactPerson, index) => {
              const { fname, lname } = contactPerson;
              const subTitle =
                fname || lname
                  ? `${fname} ${lname}`
                  : `${toUpper(lang.customerName)}`;

              return (
                <MinimizeableInput key={index} title={subTitle}>
                  <Input
                    label={lang.fname}
                    defaultValue={fname}
                    onChange={evt =>
                      inputOnChangeHandler(evt, `contactPersons_${index}_fname`)
                    }
                    noMarginLR
                    medium
                    disabled={editHandler ? !edit : false}
                  />
                  <Input
                    label={lang.lname}
                    defaultValue={lname}
                    onChange={evt =>
                      inputOnChangeHandler(evt, `contactPersons_${index}_lname`)
                    }
                    noMarginLR
                    medium
                    disabled={editHandler ? !edit : false}
                  />

                  <InputWithChild
                    headerTitle={"Emails"}
                    childTitle={"Email"}
                    addHandler={() => addContactPersonEmailHandler(index)}
                    removeHandler={() => removeContactPersonEmailHandler(index)}
                    parentKey={`contactPersons_${index}`}
                    inputOnChangeHandler={inputOnChangeHandler}
                    data={contactPerson.emails}
                    disabled={editHandler ? !edit : false}
                  />
                  <InputWithChild
                    headerTitle={"Phones"}
                    childTitle={"Phone"}
                    addHandler={() => addContactPersonPhoneHandler(index)}
                    removeHandler={() => removeContactPersonPhoneHandler(index)}
                    parentKey={`contactPersons_${index}`}
                    inputOnChangeHandler={inputOnChangeHandler}
                    data={contactPerson.phones}
                    disabled={editHandler ? !edit : false}
                  />
                </MinimizeableInput>
              );
            })}
          </DialogForm>
        </div>
      </div>
      <div>
        <LabelWithBtn
          headerTitle={toCaps(lang.products)}
          addHandler={addProductHandler}
          removeHandler={removeProductHandler}
          data={props.inputValue.products}
          disabled={editHandler ? !edit : false}
        />

        <DialogForm style={{ margin: "0" }}>
          {props.inputValue.products.map((product, index) => {
            const {
              name,
              category,
              vendor,
              status,
              serialNumber,
              bill,
              buyDate,
              returnDate,
              problemDescription,
              explanationDescription,
              complementDescription,
              changeProduct
            } = product;

            // product.vendor = product.vendor
            //   ? parsedVendorForSelect.filter(
            //       vendor => vendor.value == product.vendor.id
            //     )[0]
            //   : { id: 0, value: 0, name: "", label: "" };

            // react-select
            // avoid warning: A component is changing an uncontrolled input of type hidden to be controlled.
            product.value = product.value || "";
            product.label = product.label || "";

            console.log("product???????", product);

            const subTitle =
              (name ? `${name}` : `${toUpper(lang.product)} ${index + 1}`) +
              ` (STATUS: ${toUpper(getProductStatusTitleById(status.id))})`;

            return (
              <MinimizeableInput key={index} title={subTitle}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ flexBasis: "45%" }}>
                    {/* {status === "retur" ? <h4>Barang Awal:</h4> : null} */}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      <h4>
                        Barang{" "}
                        {getProductStatusTitleById(status.id) === "garansi"
                          ? "Awal"
                          : ""}
                        :
                      </h4>
                    </div>

                    <Input label={toCaps(lang.name)} medium searchableSelect>
                      <RSelect
                        isDisabled={editHandler ? !edit : false}
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        isSearchable={true}
                        name="productOri"
                        options={parsedProductForSelect}
                        onChange={selectedOption =>
                          productNameOnChangeHandler(index, selectedOption)
                        }
                        {...(() => {
                          // ADDITIONAL PROPERTIES TO RSelect component ONLY IF editHandler exists.
                          // if (editHandler)
                          return {
                            defaultValue: null,
                            value: product
                          };
                        })()}
                      />
                    </Input>

                    <Input
                      label={lang.category}
                      defaultValue={toCaps(category.name)}
                      noMarginLR
                      medium
                      disabled
                    />

                    <Input label={toCaps(lang.vendor)} medium searchableSelect>
                      <RSelect
                        isDisabled={editHandler ? !edit : false}
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        isSearchable={true}
                        name="productVendorOri"
                        options={parsedVendorForSelect}
                        onChange={selectedOption =>
                          productVendorOnChangeHandler(index, selectedOption)
                        }
                        {...(() => {
                          // ADDITIONAL PROPERTIES TO RSelect component ONLY IF editHandler exists.
                          if (editHandler)
                            return {
                              defaultValue: null,
                              value: vendor
                            };
                        })()}
                      />
                    </Input>
                    {/* <Input
                      label={lang.vendor}
                      defaultValue={toCaps(vendor.name)}
                      noMarginLR
                      medium
                      disabled
                    /> */}
                    <Input
                      label={toCaps(lang.productSerialNumber)}
                      defaultValue={serialNumber}
                      onChange={evt =>
                        productSerialNumberOnChangeHandler(index, evt)
                      }
                      noMarginLR
                      medium
                      disabled={editHandler ? !edit : false}
                    />
                    <Input label={"Status"} medium>
                      <div style={{ flexBasis: "50%" }}>
                        <Select
                          value={status.id || 0}
                          onChange={evt =>
                            changeProductStatusHandler(index, evt)
                          }
                          options={parsedProductReportStatusesForSelect}
                          disabled={editHandler ? !edit : false}
                        />
                      </div>
                    </Input>
                    <CustomDatePicker
                      label={toCaps(lang.buyDate)}
                      selected={buyDate ? new Date(buyDate) : null}
                      onChange={date =>
                        datePickerProductOnChangeHandler("buyDate", index, date)
                      }
                      style={{ input: { margin: "7.5px 0" } }}
                      disabled={editHandler ? !edit : false}
                    />
                    <CustomDatePicker
                      label={toCaps(lang.returnDate)}
                      selected={returnDate ? new Date(returnDate) : null}
                      onChange={date =>
                        datePickerProductOnChangeHandler(
                          "returnDate",
                          index,
                          date
                        )
                      }
                      style={{ input: { margin: "7.5px 0" } }}
                      disabled={editHandler ? !edit : false}
                    />

                    {getProductStatusTitleById(status.id) === "servis" ? (
                      <Input
                        label="Bill (Rp)"
                        defaultValue={bill}
                        onChange={evt => changeProductBillHandler(index, evt)}
                        noMarginLR
                        medium
                        disabled={editHandler ? !edit : false}
                      />
                    ) : null}
                  </div>
                  {getProductStatusTitleById(status.id) === "garansi"
                    ? garansiProductLayout(index, changeProduct)
                    : null}
                </div>
                <Aux>
                  <div>
                    <Input
                      label={toCaps(lang.explanation)}
                      defaultValue={explanationDescription}
                      onChange={evt =>
                        changeProductDescriptionHandler(
                          "explanationDescription",
                          index,
                          evt
                        )
                      }
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        height: "auto",
                        marginBottom: 0
                      }}
                      noMarginLR
                      textarea
                      rows={2}
                      disabled={editHandler ? !edit : false}
                    />
                    <Input
                      label={toCaps(lang.complement)}
                      defaultValue={complementDescription}
                      onChange={evt =>
                        changeProductDescriptionHandler(
                          "complementDescription",
                          index,
                          evt
                        )
                      }
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        height: "auto",
                        marginBottom: 0
                      }}
                      noMarginLR
                      textarea
                      rows={2}
                      disabled={editHandler ? !edit : false}
                    />
                    <Input
                      label={toCaps(lang.problem)}
                      defaultValue={problemDescription}
                      onChange={evt =>
                        changeProductDescriptionHandler(
                          "problemDescription",
                          index,
                          evt
                        )
                      }
                      style={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        height: "auto",
                        marginBottom: 0
                      }}
                      noMarginLR
                      textarea
                      rows={2}
                      disabled={editHandler ? !edit : false}
                    />
                  </div>
                </Aux>
              </MinimizeableInput>
            );
          })}
          <div className={classes.Description}>
            <Input
              label={lang.description}
              defaultValue={description}
              onChange={evt =>
                props.handler.inputOnChangeHandler(evt, "description")
              }
              style={{
                flexDirection: "column",
                alignItems: "flex-start",
                height: "auto",
                marginBottom: 0
              }}
              noMarginLR
              textarea
              rows={7}
              disabled={editHandler ? !edit : false}
            />
          </div>
        </DialogForm>
      </div>

      <div className={classes.Actions}>
        {mode === "view" ? (
          <Aux>
            {editResetBtn}
            <TextBtn
              title={toCaps(lang.delete)}
              onClick={deleteReportHandler}
              fit
              style={{
                grouper: { justifyContent: "center" },
                btn: { background: dangerColor }
              }}
            />
            {saveChangesBtn}
          </Aux>
        ) : (
          <Aux>
            <TextBtn
              title={toCaps(lang.add)}
              onClick={addReportHandler}
              fit
              style={{
                grouper: { justifyContent: "center" },
                btn: { background: primaryBtnColor }
              }}
            />
          </Aux>
        )}
      </div>
    </div>
  );
};

export default Content;
