import React from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
// import IosArrowDown from 'react-ionicons/lib/IosArrowDown';
import IosStatsOutline from "react-ionicons/lib/IosStatsOutline";
import IosBasketOutline from "react-ionicons/lib/IosBasketOutline";
import IosPeopleOutline from "react-ionicons/lib/IosPeopleOutline";
import IosPaperOutline from "react-ionicons/lib/IosPaperOutline";
import IosSettingsOutline from "react-ionicons/lib/IosSettingsOutline";

import Link from "../Link/Link";
import classes from "./Links.scss";
import { textColor } from "../../../../share/style/_variables";
import Aux from "../../../../hoc/Aux/Aux";
import * as actNavigation from "../../../../stores/Navigation/stores/actions";
import { lang } from "../../../../share/js/localization";
import { toCaps } from "../../../../share/js/utility";

const Links = props => {
  let elements = <Link link="/auth">Sign In</Link>;

  if (!props.isAuthenticated) {
    elements = [
      <Link link={"/"} key="0" Symbol={IosStatsOutline} isActive exact>
        {toCaps(lang.dashboard)}
      </Link>,
      <Link link="/product" key="1" Symbol={IosBasketOutline}>
        {toCaps(lang.product)}
      </Link>,
      <Link link="/vendor" key="2" Symbol={IosPeopleOutline}>
        {toCaps(lang.vendor)}
      </Link>,
      <Link link="/report" key="3" Symbol={IosPaperOutline}>
        {toCaps(lang.report)}
      </Link>,
      <Link link="/settings" key="4" Symbol={IosSettingsOutline}>
        {toCaps(lang.settings)}
      </Link>
      // <Link link="/logout" key="1">Sign Out</Link>
    ];
  }

  return (
    <Aux>
      {/* desktop */}
      <ul
        className={[classes.DesktopOnly, classes.NavigationItems].join(" ")}
        style={{
          transform: props.show
            ? "translateX(0)"
            : "translateX(calc(-100% + 22px + 24px + 24px))"
        }}
      >
        {elements}
      </ul>
    </Aux>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    setNavigationPath: obj => dispatch(actNavigation.setNavigationPath(obj))
  };
};

export default withRouter(
  connect(
    null,
    mapDispatchToProps
  )(Links)
);
