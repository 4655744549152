import React, { Component } from "react";
import { connect } from "react-redux";
import MdSearch from "react-ionicons/lib/MdSearch";

import classes from "./AddReport.scss";
import EnhancedTable from "./Table/EnhancedTable";
import Section from "./Section/Section";
import Form from "../Settings/Form/Form";
import Input from "../Settings/Form/Input/Input";
import { toCaps, toLower, updateObject } from "../../share/js/utility";
import TextBtn from "../../components/TextBtn/TextBtn";
import { lang } from "../../share/js/localization";
import Select from "../Settings/Form/Select/Select";
import AddDialog from "./AddDialog/AddDialog";
import { searchBtnColor } from "../../share/style/_variables";
import ViewDialog from "./ViewDialog/ViewDialog";
import Content from "./AddDialog/Content/Content";
import { USER_ID } from "../../share/js/constants";
import {
  acReportStatus,
  acProduct,
  acProductReportStatus,
  acReport,
  acVendor
} from "../../stores/actions";
import { PulseLoader } from "react-spinners";
import InfoDialog from "../../components/InfoDialog/InfoDialog";
import Aux from "../../hoc/Aux/Aux";
// import ViewDialog from "./Dialog/Dialog_bu";

class AddReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldError: false,
      fieldErrorMessage: "",
      form: this.formDefault()
    };
  }

  formDefault = _ => ({
    id: `${Date.now()}${localStorage.getItem(USER_ID)}`,
    entryDate: new Date(),
    technician: "",
    description: "",
    status: {
      id: 0
    },
    contactPersons: [this.contactPersonObj()],
    products: [this.productObj()]
  });

  contactPersonObj = _ => ({
    fname: "",
    lname: "",
    phones: [""],
    emails: [""]
  });

  productObj = _ => ({
    id: 0,
    name: "",
    serialNumber: "",
    category: { id: 0, name: "" },
    vendor: { id: 0, value: 0, name: "", label: "" },
    status: { id: 0 },
    bill: "",
    buyDate: null,
    returnDate: null,
    explanationDescription: "",
    problemDescription: "",
    complementDescription: "",
    changeProduct: this.productServiceObj()
  });

  productServiceObj = _ => ({
    id: 0,
    name: "",
    serialNumber: "",
    category: { id: 0, name: "" }
    // vendor: { id: 0, name: "" }
  });

  resetFormInput = _ => {
    this.setState({
      form: this.formDefault()
    });
  };

  componentDidMount() {
    // scroll to top of the page
    window.scrollTo(0, 0);

    this.props.getReportStatuses();
    this.props.getProductReportStatuses();
    this.props.getAllProducts();
    this.props.getAllVendors();
  }

  addEmailHandler = () => {
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        emails: [...state.form.emails].concat("")
      }
    }));
  };
  removeEmailHandler = () => {
    if (this.state.form.emails.length === 1) return;
    this.setState(state => {
      const copied = [...state.form.emails];
      copied.pop();

      return {
        ...state,
        form: {
          ...state.form,
          emails: copied
        }
      };
    });
  };

  addPhoneHandler = () => {
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        phones: [...state.form.phones].concat("")
      }
    }));
  };
  removePhoneHandler = () => {
    if (this.state.form.phones.length === 1) return;

    this.setState(state => {
      const copied = [...state.form.phones];
      copied.pop();

      return {
        ...state,
        form: {
          ...state.form,
          phones: copied
        }
      };
    });
  };

  addContactPersonHandler = () => {
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        contactPersons: [...state.form.contactPersons].concat(
          this.contactPersonObj()
        )
      }
    }));
  };
  removeContactPersonHandler = () => {
    if (this.state.form.contactPersons.length === 1) return;

    this.setState(state => {
      const copied = [...state.form.contactPersons];
      copied.pop();

      return {
        ...state,
        form: {
          ...state.form,
          contactPersons: copied
        }
      };
    });
  };
  addContactPersonPhoneHandler = index => {
    this.setState(state => {
      const copied = [...state.form.contactPersons];
      copied[index].phones.push("");

      return {
        ...state,
        form: {
          ...state.form,
          contactPersons: copied
        }
      };
    });

    console.log(this.state);
  };
  removeContactPersonPhoneHandler = index => {
    if (this.state.form.contactPersons[index].phones.length === 1) return;

    this.setState(state => {
      const copied = [...state.form.contactPersons];
      copied[index].phones.pop();

      return {
        ...state,
        form: {
          ...state.form,
          contactPersons: copied
        }
      };
    });
  };
  addContactPersonEmailHandler = index => {
    this.setState(state => {
      const copied = [...state.form.contactPersons];
      copied[index].emails.push("");

      return {
        ...state,
        form: {
          ...state.form,
          contactPersons: copied
        }
      };
    });

    console.log(this.state);
  };
  removeContactPersonEmailHandler = index => {
    if (this.state.form.contactPersons[index].emails.length === 1) return;

    this.setState(state => {
      const copied = [...state.form.contactPersons];
      copied[index].emails.pop();

      return {
        ...state,
        form: {
          ...state.form,
          contactPersons: copied
        }
      };
    });
  };

  addProductHandler = () => {
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        products: [...state.form.products].concat(this.productObj())
      }
    }));
  };
  removeProductHandler = () => {
    if (this.state.form.products.length === 1) return;

    this.setState(state => {
      const copied = [...state.form.products];
      copied.pop();

      return {
        ...state,
        form: {
          ...state.form,
          products: copied
        }
      };
    });
  };

  inputOnChangeHandler = (evt, key) => {
    console.log(this.state.form);

    const value = evt.target.value;
    const splittedKey = key.split("_");

    if (splittedKey.length > 1) {
      const splittedKeyIndex = splittedKey[splittedKey.length - 1];

      if (splittedKey[0] === "emails" || splittedKey[0] === "phones") {
        this.setState(state => {
          const copied = state.form[splittedKey[0]].concat(); // copy of emails array
          copied[splittedKeyIndex] = value;

          return updateObject(state, {
            form: {
              ...state.form,
              [splittedKey[0]]: copied
            }
          });
        });
      }

      console.log(splittedKey);

      if (splittedKey[0] === "contactPersons") {
        if (splittedKey.length < 4) {
          // ex. ["contactPersons", "0", "fname"]
          const contactPersons = splittedKey[0];
          const parentKey = splittedKey[1];
          const contactPersonChild = splittedKey[2];

          this.setState(state => {
            const copied = state.form[contactPersons].concat(); // copy of contactPersons array
            copied[parentKey][contactPersonChild] = value;

            return updateObject(state, {
              form: {
                ...state.form,
                contactPersons: copied
              }
            });
          });

          return;
        }

        // ex. ["contactPersons", "0", "phones", "0"]
        const contactPersons = splittedKey[0];
        const parentKey = splittedKey[1];
        const contactPersonChild = splittedKey[2];
        const childKey = splittedKey[3];

        this.setState(state => {
          const copied = state.form[contactPersons].concat(); // copy of contactPersons array
          copied[parentKey][contactPersonChild][childKey] = value;

          return updateObject(state, {
            form: {
              ...state.form,
              contactPersons: copied
            }
          });
        });
      }

      return;
    }

    // this.setState(state =>
    //   updateObject(state, {
    //     form: {
    //       ...state.form,
    //       [key]: value
    //     }
    //   })
    // );

    this.setState(state => {
      return {
        ...state,
        form: {
          ...state.form,
          [key]: value
        }
      };
    });

    console.log("inputed::::::", this.state.form.products);
  };

  okInfoHandler = () => {
    this.setState({ showAddedInfo: false });
    this.resetFormInput();
  };

  okCannotAddInfoHandler = () => {
    this.setState(state => ({
      ...state,
      showCannotAddInfo: false
    }));
  };

  /// title handler ///
  closeHandler = () => {
    this.props.visibilityHandler();
  };

  /// Content handler ///
  changeAddCategoryDialogVisibility = () => {
    this.setState(state => ({
      ...state,
      showAddCategoryDialog: !state.showAddCategoryDialog
    }));
  };

  addCategoryHandler = () => {
    this.changeAddCategoryDialogVisibility();
  };

  addReportHandler = () => {
    // alert("add_report");

    const form = this.state.form;

    console.log("addReport form::::", form);
    let dataToPost = {
      report_id: parseInt(form.id),
      report_status_id: parseInt(form.status.id),
      technician: form.technician,
      description: form.description,
      entryDate: form.entryDate,
      customer: form.contactPersons[0],
      products: [
        ...form.products.map(product => {
          let change = {};

          if (product.changeProduct.id != 0) {
            change = {
              product_id: parseInt(product.changeProduct.id),
              serialNumber: product.changeProduct.serialNumber
            };
          }

          return {
            original: {
              product_id: parseInt(product.id),
              serialNumber: product.serialNumber,
              report_product_status_id: product.status.id,
              bill: product.bill,
              buy_date: product.buyDate,
              return_date: product.returnDate,
              explanation_description: product.explanationDescription,
              problem_description: product.problemDescription,
              complement_description: product.complementDescription,

              vendor_id:
                parseInt(product.vendor.id) == 0
                  ? null
                  : parseInt(product.vendor.id)
            },
            change: change
          };
        })
      ]
    };

    // console.log("dataToPost::::::", dataToPost);
    // return;

    // ADD REPORT
    this.props.postReport(dataToPost);
  };

  backToReportHandler = () => {
    console.log(this.props);

    // this.props.history.goBack();

    this.props.history.replace({
      pathname: "/report"
    });
  };

  datePickerOnChangeHandler = date => {
    this.setState(state => {
      return {
        ...state,
        form: {
          ...state.form,
          entryDate: date
        }
      };
    });
  };

  changeStatusHandler = evt => {
    let value = evt.target.value || 0;

    this.setState(state => ({
      form: { ...state.form, status: { id: value } }
    }));
  };

  changeProductStatusHandler = (productIndex, evt) => {
    let value = evt.target.value || 0;

    this.setState(state => {
      /*
      {
        name: "",
        category: { id: 0, value: "" },
        vendor: { id: 0, value: "" },
        status: { id: 0 }
      }
      */

      const products = [...state.form.products];
      products[productIndex] = {
        ...products[productIndex],
        status: {
          id: value
        }
      };

      return {
        form: { ...state.form, products: products }
      };
    });
  };

  productSerialNumberOnChangeHandler = (productIndex, evt) => {
    let value = evt.target.value || "";

    this.setState(state => {
      /*
      {
        name: "",
        category: { id: 0, value: "" },
        vendor: { id: 0, value: "" },
        status: { id: 0 }
      }
      */

      const products = [...state.form.products];
      products[productIndex] = {
        ...products[productIndex],
        serialNumber: value
      };

      return {
        form: { ...state.form, products: products }
      };
    });
  };

  changeProductInputOnChangeHandler = (section, productIndex, evt) => {
    let value = evt.target.value || "";

    this.setState(state => {
      /*
      {
        name: "",
        category: { id: 0, value: "" },
        vendor: { id: 0, value: "" },
        status: { id: 0 }
      }
      */

      const products = [...state.form.products];
      products[productIndex] = {
        ...products[productIndex],
        changeProduct: {
          ...products[productIndex].changeProduct,
          [section]: value
        }
      };

      return {
        form: { ...state.form, products: products }
      };
    });
  };

  changeProductBillHandler = (productIndex, evt) => {
    let value = evt.target.value || "";

    this.setState(state => {
      /*
      {
        name: "",
        category: { id: 0, value: "" },
        vendor: { id: 0, value: "" },
        status: { id: 0 }
      }
      */

      const products = [...state.form.products];
      products[productIndex] = {
        ...products[productIndex],
        bill: value
      };

      return {
        form: { ...state.form, products: products }
      };
    });
  };

  productNameOnChangeHandler = (productIndex, selectedOption) => {
    selectedOption = selectedOption || {
      value: 0,
      label: "",
      category: { id: 0, name: "" }
      // vendor: { id: 0, name: "" }
    };

    /* selectedOption:
    {
      value: 1
      label: "new name"
      category: {id: 1, name: "hardware"}
      vendor: {id: 58, name: "Vendor 2"}
    }
    */
    const { value: id, label: name, category } = selectedOption;

    // return;
    this.setState(state => {
      /*
      {
        name: "",
        category: { id: 0, value: "" },
        vendor: { id: 0, value: "" },
        status: { id: 0 }
      }
      */

      const products = [...state.form.products];
      products[productIndex] = {
        ...products[productIndex],
        ...selectedOption,
        id,
        name,
        category
        // vendor
      };

      return {
        form: { ...state.form, products: products }
      };
    });

    console.log("new state:::::", this.state);
  };

  productVendorOnChangeHandler = (productIndex, selectedOption) => {
    selectedOption = selectedOption || {
      value: 0,
      label: "",
      name: ""
    };

    const { value: id, name } = selectedOption;

    // return;
    this.setState(state => {
      const products = [...state.form.products];

      products[productIndex] = {
        ...products[productIndex],
        vendor: { ...selectedOption, id, name }
      };

      return {
        form: { ...state.form, products: products }
      };
    });
  };

  changeProductNameOnChangeHandler = (productIndex, selectedOption) => {
    selectedOption = selectedOption || {
      value: 0,
      label: "",
      category: { id: 0, name: "" }
      // vendor: { id: 0, name: "" }
    };

    /* selectedOption:
    {
      value: 1
      label: "new name"
      category: {id: 1, name: "hardware"}
      vendor: {id: 58, name: "Vendor 2"}
    }
    */
    const { value: id, label: name, category } = selectedOption;

    // return;
    this.setState(state => {
      /*
      {
        name: "",
        category: { id: 0, value: "" },
        vendor: { id: 0, value: "" },
        status: { id: 0 }
      }
      */

      const products = [...state.form.products];
      products[productIndex] = {
        ...products[productIndex],
        changeProduct: {
          ...products[productIndex].changeProduct,
          ...selectedOption,
          id,
          name,
          category
        }
      };

      return {
        form: { ...state.form, products: products }
      };
    });
  };

  okInfoHandler = section => {
    if (section === "fieldError") {
      this.setState({
        fieldError: false,
        fieldErrorMessage: ""
      });
      return;
    }

    if (section === "error") {
      this.props.clearPostReportState();
      return;
    }

    this.props.clearPostReportState();
    this.resetFormInput();
  };

  // tgl pembelian, pengembalian, deskripsi produk
  datePickerProductOnChangeHandler = (section, productIndex, date) => {
    let value = date || null;

    this.setState(state => {
      const products = [...state.form.products];
      products[productIndex] = {
        ...products[productIndex],
        [section]: value
      };

      return {
        form: { ...state.form, products: products }
      };
    });
  };

  changeProductDescriptionHandler = (section, productIndex, evt) => {
    let value = evt.target.value || "";

    this.setState(state => {
      const products = [...state.form.products];
      products[productIndex] = {
        ...products[productIndex],
        [section]: value
      };

      return {
        form: { ...state.form, products: products }
      };
    });
  };

  render() {
    let addReportLayout = (
      <div className={classes.AddDialog}>
        <Content
          mode={"add"}
          handler={{
            inputOnChangeHandler: this.inputOnChangeHandler,
            vendorOnSearchHandler: this.vendorOnSearchHandler,
            categoryOnChangeHandler: this.categoryOnChangeHandler,
            addEmailHandler: this.addEmailHandler,
            removeEmailHandler: this.removeEmailHandler,
            addPhoneHandler: this.addPhoneHandler,
            removePhoneHandler: this.removePhoneHandler,
            addContactPersonHandler: this.addContactPersonHandler,
            removeContactPersonHandler: this.removeContactPersonHandler,
            addContactPersonPhoneHandler: this.addContactPersonPhoneHandler,
            removeContactPersonPhoneHandler: this
              .removeContactPersonPhoneHandler,
            addContactPersonEmailHandler: this.addContactPersonEmailHandler,
            removeContactPersonEmailHandler: this
              .removeContactPersonEmailHandler,
            addReportHandler: this.addReportHandler,
            backToReportHandler: this.backToReportHandler,
            datePickerOnChangeHandler: this.datePickerOnChangeHandler,
            addProductHandler: this.addProductHandler,
            removeProductHandler: this.removeProductHandler,
            changeStatusHandler: this.changeStatusHandler,
            changeProductStatusHandler: this.changeProductStatusHandler,
            productNameOnChangeHandler: this.productNameOnChangeHandler,
            productSerialNumberOnChangeHandler: this
              .productSerialNumberOnChangeHandler,
            changeProductBillHandler: this.changeProductBillHandler,

            changeProductDescriptionHandler: this
              .changeProductDescriptionHandler,
            datePickerProductOnChangeHandler: this
              .datePickerProductOnChangeHandler,

            // changeProduct
            changeProductInputOnChangeHandler: this
              .changeProductInputOnChangeHandler,
            changeProductNameOnChangeHandler: this
              .changeProductNameOnChangeHandler,

            productVendorOnChangeHandler: this.productVendorOnChangeHandler
          }}
          inputValue={{
            id: this.state.form.id,
            description: this.state.form.description,
            emails: this.state.form.emails,
            phones: this.state.form.phones,
            contactPersons: this.state.form.contactPersons,
            entryDate: this.state.form.entryDate,
            products: this.state.form.products,
            selectProducts: this.props.products,
            selectVendors: this.props.vendors,
            reportStatuses: this.props.reportStatuses,
            productReportStatuses: this.props.productReportStatuses,
            statusId: this.state.form.status.id,
            technician: this.state.form.technician
          }}
        />
      </div>
    );

    if (this.props.loading) {
      addReportLayout = (
        <span style={{ textAlign: "center" }}>
          <PulseLoader sizeUnit={"px"} size={12} color={"#123abc"} />
        </span>
      );
    }

    let customError = !this.props.postReportSuccess && this.props.error;
    let success = this.props.postReportSuccess && !this.props.error;

    return (
      <div className={classes.AddReport}>
        <Aux>
          <InfoDialog
            show={this.state.fieldError}
            message={this.state.fieldErrorMessage}
            okHandler={() => this.okInfoHandler("fieldError")}
          />
          <InfoDialog
            show={customError}
            message={this.props.error}
            okHandler={() => this.okInfoHandler("error")}
          />
          <InfoDialog
            show={success}
            message={lang.generalSuccess}
            okHandler={() => this.okInfoHandler()}
          />
        </Aux>
        <Section>{addReportLayout}</Section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    productReportStatuses:
      state.productReportStatusReducer.productReportStatuses,
    products: state.productReducer.products,
    reportStatuses: state.reportStatusReducer.reportStatuses,
    vendors: state.vendorReducer.vendors,

    loading: state.reportReducer.loading,
    error: state.reportReducer.error,
    postReportSuccess: state.reportReducer.postReportSuccess
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getReportStatuses: _ => dispatch(acReportStatus.getReportStatuses()),
    getProductReportStatuses: _ =>
      dispatch(acProductReportStatus.getProductReportStatuses()),
    getAllProducts: _ => dispatch(acProduct.getAllProducts()),
    getAllVendors: _ => dispatch(acVendor.getAllVendors()),
    postReport: data => dispatch(acReport.postReport(data)),
    clearPostReportState: _ => dispatch(acReport.clearPostReportState())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddReport);
