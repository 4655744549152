import React, { Component } from "react";
import { connect } from "react-redux";
import { PulseLoader } from "react-spinners";

import classes from "./Vendor.scss";
import EnhancedTable from "./Table/EnhancedTable";
import Section from "./Section/Section";
import Form from "../Settings/Form/Form";
import Input from "../Settings/Form/Input/Input";
import { toCaps, toLower, updateObject } from "../../share/js/utility";
import TextBtn from "../../components/TextBtn/TextBtn";
import { lang } from "../../share/js/localization";
import Select from "../Settings/Form/Select/Select";
import AddDialog from "./AddDialog/AddDialog";
import { searchBtnColor } from "../../share/style/_variables";
import ViewDialog from "./ViewDialog/ViewDialog";
import Content from "./AddDialog/Content/Content";
import { acVendor } from "../../stores/actions";
import InfoDialog from "../../components/InfoDialog/InfoDialog";
// import ViewDialog from "./Dialog/Dialog_bu";

class AddVendor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldError: false,
      fieldErrorMessage: "",
      form: this.formDefault()
    };
  }

  formDefault = _ => ({
    name: "",
    addresses: [""],
    emails: [""],
    phones: [""],
    contactPersons: [this.contactPersonObj()]
  });

  contactPersonObj = _ => ({
    fname: "",
    lname: "",
    phones: [""],
    emails: [""]
  });

  resetFormInput = _ => {
    this.setState({
      form: this.formDefault()
    });
  };

  addAddressHandler = () => {
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        addresses: [...state.form.addresses].concat("")
      }
    }));
  };

  removeAddressHandler = () => {
    if (this.state.form.addresses.length === 1) return;
    this.setState(state => {
      const copied = [...state.form.addresses];
      copied.pop();

      return {
        ...state,
        form: {
          ...state.form,
          addresses: copied
        }
      };
    });
  };

  addEmailHandler = () => {
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        emails: [...state.form.emails].concat("")
      }
    }));
  };
  removeEmailHandler = () => {
    if (this.state.form.emails.length === 1) return;
    this.setState(state => {
      const copied = [...state.form.emails];
      copied.pop();

      return {
        ...state,
        form: {
          ...state.form,
          emails: copied
        }
      };
    });
  };

  addPhoneHandler = () => {
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        phones: [...state.form.phones].concat("")
      }
    }));
  };
  removePhoneHandler = () => {
    if (this.state.form.phones.length === 1) return;

    this.setState(state => {
      const copied = [...state.form.phones];
      copied.pop();

      return {
        ...state,
        form: {
          ...state.form,
          phones: copied
        }
      };
    });
  };

  addContactPersonHandler = () => {
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        contactPersons: [...state.form.contactPersons].concat(
          this.contactPersonObj()
        )
      }
    }));
  };
  removeContactPersonHandler = () => {
    if (this.state.form.contactPersons.length === 1) return;

    this.setState(state => {
      const copied = [...state.form.contactPersons];
      copied.pop();

      return {
        ...state,
        form: {
          ...state.form,
          contactPersons: copied
        }
      };
    });
  };
  addContactPersonPhoneHandler = index => {
    this.setState(state => {
      const copied = [...state.form.contactPersons];
      copied[index].phones.push("");

      return {
        ...state,
        form: {
          ...state.form,
          contactPersons: copied
        }
      };
    });

    console.log(this.state);
  };
  removeContactPersonPhoneHandler = index => {
    if (this.state.form.contactPersons[index].phones.length === 1) return;

    this.setState(state => {
      const copied = [...state.form.contactPersons];
      copied[index].phones.pop();

      return {
        ...state,
        form: {
          ...state.form,
          contactPersons: copied
        }
      };
    });
  };
  addContactPersonEmailHandler = index => {
    this.setState(state => {
      const copied = [...state.form.contactPersons];
      copied[index].emails.push("");

      return {
        ...state,
        form: {
          ...state.form,
          contactPersons: copied
        }
      };
    });

    console.log(this.state);
  };
  removeContactPersonEmailHandler = index => {
    if (this.state.form.contactPersons[index].emails.length === 1) return;

    this.setState(state => {
      const copied = [...state.form.contactPersons];
      copied[index].emails.pop();

      return {
        ...state,
        form: {
          ...state.form,
          contactPersons: copied
        }
      };
    });
  };

  inputOnChangeHandler = (evt, key) => {
    const value = evt.target.value;
    const splittedKey = key.split("_");

    if (splittedKey.length > 1) {
      const splittedKeyIndex = splittedKey[splittedKey.length - 1];

      if (
        splittedKey[0] === "addresses" ||
        splittedKey[0] === "emails" ||
        splittedKey[0] === "phones"
      ) {
        this.setState(state => {
          const copied = state.form[splittedKey[0]].concat(); // copy of addresses array
          copied[splittedKeyIndex] = value;

          return updateObject(state, {
            form: {
              ...state.form,
              [splittedKey[0]]: copied
            }
          });
        });
      }

      // console.log(splittedKey);

      if (splittedKey[0] === "contactPersons") {
        if (splittedKey.length < 4) {
          // ex. ["contactPersons", "0", "fname"]
          const contactPersons = splittedKey[0];
          const parentKey = splittedKey[1];
          const contactPersonChild = splittedKey[2];

          this.setState(state => {
            const copied = state.form[contactPersons].concat(); // copy of contactPersons array
            copied[parentKey][contactPersonChild] = value;

            return updateObject(state, {
              form: {
                ...state.form,
                contactPersons: copied
              }
            });
          });

          return;
        }

        // ex. ["contactPersons", "0", "phones", "0"]
        const contactPersons = splittedKey[0];
        const parentKey = splittedKey[1];
        const contactPersonChild = splittedKey[2];
        const childKey = splittedKey[3];

        this.setState(state => {
          const copied = state.form[contactPersons].concat(); // copy of contactPersons array
          copied[parentKey][contactPersonChild][childKey] = value;

          return updateObject(state, {
            form: {
              ...state.form,
              contactPersons: copied
            }
          });
        });
      }

      return;
    }

    this.setState(state =>
      updateObject(state, {
        form: {
          ...state.form,
          [key]: value
        }
      })
    );
  };

  addVendorHandler = () => {
    console.log("this.state.form::::", this.state.form);

    let { name } = this.state.form;
    name = name.trim();

    if (name.length < 1) {
      this.setState({
        fieldError: true,
        fieldErrorMessage: lang.formatString(
          lang.fieldMustBeFill,
          `*${toCaps(lang.name)}*`
        )
      });
      return;
    }

    // POST VENDOR
    this.props.postVendor(this.state.form);
  };
  backToVendorHandler = () => {
    console.log(this.props);

    // this.props.history.goBack();

    this.props.history.replace({
      pathname: "/vendor"
    });
  };

  okInfoHandler = section => {
    if (section === "fieldError") {
      this.setState({
        fieldError: false,
        fieldErrorMessage: ""
      });
      return;
    }

    if (section === "error") {
      this.props.clearPostVendorState();
      return;
    }

    this.props.clearPostVendorState();
    this.resetFormInput();
  };
  render() {
    let addDialogLayout = (
      <div className={classes.AddDialog}>
        <Content
          mode={"add"}
          handler={{
            inputOnChangeHandler: this.inputOnChangeHandler,
            vendorOnSearchHandler: this.vendorOnSearchHandler,
            categoryOnChangeHandler: this.categoryOnChangeHandler,
            addAddressHandler: this.addAddressHandler,
            removeAddressHandler: this.removeAddressHandler,
            addEmailHandler: this.addEmailHandler,
            removeEmailHandler: this.removeEmailHandler,
            addPhoneHandler: this.addPhoneHandler,
            removePhoneHandler: this.removePhoneHandler,
            addContactPersonHandler: this.addContactPersonHandler,
            removeContactPersonHandler: this.removeContactPersonHandler,
            addContactPersonPhoneHandler: this.addContactPersonPhoneHandler,
            removeContactPersonPhoneHandler: this
              .removeContactPersonPhoneHandler,
            addContactPersonEmailHandler: this.addContactPersonEmailHandler,
            removeContactPersonEmailHandler: this
              .removeContactPersonEmailHandler,
            addVendorHandler: this.addVendorHandler,
            backToVendorHandler: this.backToVendorHandler
          }}
          inputValue={{
            name: this.state.form.name,
            addresses: this.state.form.addresses,
            emails: this.state.form.emails,
            phones: this.state.form.phones,
            contactPersons: this.state.form.contactPersons
          }}
        />
      </div>
    );

    if (this.props.loading) {
      addDialogLayout = (
        <PulseLoader
          sizeUnit={"px"}
          size={12}
          color={"#123abc"}
          css={{ textAlign: "center" }}
          loading={this.props.loading}
        />
      );
    }

    let customError = !this.props.postVendorSuccess && this.props.error;
    let success = this.props.postVendorSuccess && !this.props.error;

    return (
      <div className={classes.Product}>
        <Section>
          <InfoDialog
            show={this.state.fieldError}
            message={this.state.fieldErrorMessage}
            okHandler={() => this.okInfoHandler("fieldError")}
          />
          <InfoDialog
            show={customError}
            message={this.props.error}
            okHandler={() => this.okInfoHandler("error")}
          />
          <InfoDialog
            show={success}
            message={lang.generalSuccess}
            okHandler={() => this.okInfoHandler()}
          />
          {addDialogLayout}
        </Section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.vendorReducer.loading,
    error: state.vendorReducer.error,
    postVendorSuccess: state.vendorReducer.postVendorSuccess
  };
};

const mapDispatchToProps = dispatch => {
  return {
    postVendor: data => dispatch(acVendor.postVendor(data)),
    clearPostVendorState: _ => dispatch(acVendor.clearPostVendorState())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddVendor);
