import React, { Component } from "react";
import { connect } from "react-redux";
import IosMoreOutline from "react-ionicons/lib/IosMoreOutline";
import IosAdd from "react-ionicons/lib/IosAdd";
import IosRemove from "react-ionicons/lib/IosRemove";
import MdCreate from "react-ionicons/lib/MdCreate";

import classes from "./CrudSubSection.scss";
import SubMenu from "./SubMenu/SubMenu";
import { lang } from "../../../share/js/localization";

class CrudSubSection extends Component {
  // state = {
  //     showSideDrawer: false,
  // };

  // changeSideDrawerState = () => {
  //     console.log('backdrop');

  //     this.setState((prevState) => {
  //         return { showSideDrawer: !prevState.showSideDrawer }
  //     });
  // };

  render() {
    let addMenu = null;
    let deleteMenu = null;
    let editMenu = null;

    if (this.props.addHandler) {
      addMenu = (
        <SubMenu
          Symbol={IosAdd}
          title={lang.add}
          className={"Add"}
          clicked={this.props.addHandler}
        />
      );
    }
    if (this.props.deleteHandler) {
      deleteMenu = (
        <SubMenu
          Symbol={IosRemove}
          title={lang.delete}
          className={"Delete"}
          clicked={this.props.deleteHandler}
        />
      );
    }
    if (this.props.editHandler) {
      editMenu = (
        <SubMenu
          Symbol={MdCreate}
          title={lang.edit}
          className={"Edit"}
          clicked={this.props.editHandler}
        />
      );
    }

    return (
      <div className={classes.SubSection}>
        <div className={classes.Content}>
          <div className={classes.Header}>
            <span className={classes.Title}>{this.props.title}</span>
            <span className={classes.Goto} onClick={this.props.goToHandler}>
              <IosMoreOutline fontSize="22px" />
            </span>
          </div>
          <ul className={classes.Menu}>
            {addMenu}
            {deleteMenu}
            {editMenu}
          </ul>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(CrudSubSection);
