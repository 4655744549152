import React, { Component } from "react";
import MdCreate from "react-ionicons/lib/MdCreate";

import classes from "./DialogForm.scss";

class Form extends Component {
  render() {
    return <div className={classes.Form}>{this.props.children}</div>;
  }
}

export default Form;
