import React, { Component } from "react";
import { lang } from "../../../../share/js/localization";
import Aux from "../../../../hoc/Aux/Aux";
import TextBtn from "../../../../components/TextBtn/TextBtn";
import { toCaps } from "../../../../share/js/utility";
import {
  primaryBtnColor,
  dangerColor,
  warningColor
} from "../../../../share/style/_variables";
import { Button } from "@material-ui/core";

const Actions = props => {
  return (
    <Aux>
      <TextBtn
        title={toCaps(lang.delete)}
        onClick={props.handler.deleteProductHandler}
        fit
        style={{
          grouper: { justifyContent: "center" },
          btn: { background: dangerColor }
        }}
      />
      {props.fieldChanged ? null : (
        <TextBtn
          title={toCaps(lang.saveChanges)}
          onClick={props.handler.saveChangesHandler}
          fit
          style={{
            grouper: { justifyContent: "center" },
            btn: { background: warningColor }
          }}
        />
      )}
    </Aux>
  );
};

export default Actions;
