import React from "react";
import {
  getFormattedDate,
  toCaps,
  toString
} from "../../../../share/js/utility";
import Aux from "../../../../hoc/Aux/Aux";
import classes from "./ReportFormPrint.scss";
import { lang } from "../../../../share/js/localization";

const ReportFormPrint = props => {
  let { data } = props;
  data = [data];

  const customerKeyFormatter = key => {
    switch (key) {
      case "fname":
        return toCaps(lang.fname);
      case "lname":
        return toCaps(lang.lname);
      case "phones":
        return toCaps(lang.phones);
      case "emails":
        return toCaps(lang.emails);
      default:
        return key;
    }
  };

  const reportKeyFormatter = key => {
    switch (key) {
      case "entryDate":
        return toCaps(lang.entryDate);
      case "technician":
        return toCaps(lang.technician);
      default:
        return key;
    }
  };
  const productKeyFormatter = key => {
    switch (key) {
      case "serialNumber":
        return toCaps(lang.productSerialNumber);
      default:
        return lang[key] || key;
    }
  };

  console.log("data::::", data);
  if (data.length <= 0) {
    console.log("data::::", data);
    return <div />;
  }

  return (
    <div>
      {data.map((report, index) => {
        const {
          id,
          entryDate,
          technician,
          status,
          description,
          products,
          customer
        } = report;

        const reportDetail = { id, entryDate, technician, status };

        const reportHeaderLayout = (
          <div className={classes.ReportHeaderLayout}>
            <h3>ID: {id}</h3>
          </div>
        );
        const reportDetailLayout = (
          <Aux>
            <div className={classes.ReportDetailLayout}>
              {Object.keys(reportDetail).map((key, i1) => {
                // console.log(key, key == "status");
                if (props.customer && key == "status") {
                  return;
                }

                return (
                  <div
                    key={"reportDetail_" + i1}
                    className={classes.Report__FieldGroup}
                  >
                    <span>{toCaps(reportKeyFormatter(key))}</span>
                    <span>{reportDetail[key]}</span>
                  </div>
                );
              })}
            </div>
          </Aux>
        );

        /// CUSTOMER LAYOUT ///
        const customerDetailLayout = (
          <Aux>
            <div className={classes.ReportDetailLayout}>
              <span>Customer:</span>
              {Object.keys(customer).map((key, i1) => {
                if (toString(customer[key]) === "[object String]") {
                  return (
                    <div
                      key={`${customer[key]}1_` + i1}
                      className={classes.Customer__FieldGroup}
                    >
                      <span>{toCaps(customerKeyFormatter(key))}</span>
                      <span>{toCaps(customer[key])}</span>
                    </div>
                  );
                }

                if (toString(customer[key]) === "[object Array]") {
                  const arr = customer[key];
                  return (
                    <div
                      key={`${customer[key]}2_` + i1}
                      className={classes.Customer__FieldWrapper}
                    >
                      <span>{toCaps(lang[key] || key)}:</span>
                      <ul>
                        {arr.map((value, i2) => {
                          return (
                            <li
                              key={"customer_info_" + i2}
                              className={classes.Customer__FieldGroup}
                            >
                              <span>
                                {toCaps(customerKeyFormatter(key)) +
                                  ` ${i2 + 1}`}
                              </span>
                              <span>{value}</span>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                }
              })}
            </div>
          </Aux>
        );

        const productLayout = (
          <div className={classes.ProductLayout}>
            <span>Products:</span>
            <div>
              {// [{original, change}]
              products.map((product, i0) => {
                console.log("product:::::::::", product);

                let productDescriptionLayout = [
                  <div key={0} style={{ marginTop: "5px" }}>
                    <span>{toCaps(lang.complement)}:</span>
                    <div className={classes.DescriptionTextArea}>
                      <pre>{product["original"].complementDescription}</pre>
                    </div>
                  </div>,

                  <div key={1} style={{ marginTop: "5px" }}>
                    <span>{toCaps(lang.problem)}:</span>
                    <div className={classes.DescriptionTextArea}>
                      <pre>{product["original"].problemDescription}</pre>
                    </div>
                  </div>
                ];

                // alert(props.customer);

                if (!props.customer) {
                  productDescriptionLayout.push(
                    <div key={2} style={{ marginTop: "5px" }}>
                      <span>{toCaps(lang.explanation)}:</span>
                      <div className={classes.DescriptionTextArea}>
                        <pre>{product["original"].explanationDescription}</pre>
                      </div>
                    </div>
                  );
                }

                return (
                  <div
                    key={"product_oc_" + i0}
                    className={classes.ProductWrapper}
                  >
                    <div className={classes.Product}>
                      {/* <span>{`Product ${i0 + 1}`}</span> */}
                      {// original, change
                      Object.keys(product).map((key, i1) => {
                        const productOriOrChange = product[key];
                        const hasChangeProduct =
                          Object.keys(product).length > 1;
                        const oriOrChange = i1 === 0 ? "Awal" : "Ganti";

                        return (
                          <div
                            key={"product_oc_oc" + i1}
                            className={classes.Product__OriOrChange}
                          >
                            {hasChangeProduct ? (
                              <span>Barang {oriOrChange}:</span>
                            ) : (
                              <span>Barang:</span>
                            )}
                            {Object.keys(productOriOrChange).map((key, i2) => {
                              const value = productOriOrChange[key];

                              if (
                                key == "explanationDescription" ||
                                key == "problemDescription" ||
                                key == "complementDescription"
                              ) {
                                return;
                              }

                              if (key == "vendor" && props.customer) {
                                return;
                              }

                              if (key == "buyDate" || key == "returnDate")
                                return (
                                  <Aux key={"productOriOrChange_" + i2}>
                                    <div
                                      className={classes.Product__FieldGroup}
                                    >
                                      <span>
                                        {toCaps(productKeyFormatter(key))}
                                      </span>
                                      <span>{value}</span>
                                    </div>
                                  </Aux>
                                );

                              return (
                                <div
                                  key={"productOriOrChange_" + i2}
                                  className={classes.Product__FieldGroup}
                                >
                                  <span>
                                    {toCaps(productKeyFormatter(key))}
                                  </span>
                                  <span>{value}</span>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                    {productDescriptionLayout}
                  </div>
                );
              })}
            </div>
          </div>
        );

        const descriptionLayout = (
          <div className={classes.DescriptionLayout}>
            <span>{toCaps(lang.description)}:</span>
            <div
              className={classes.DescriptionTextArea}
              rows={7}
              value={description}
            >
              <pre>{description}</pre>
            </div>
            <Aux>
              <div
                style={{
                  justifyContent: "space-around",
                  marginTop: "35px",
                  marginBottom: "50px",
                  display: "flex"
                }}
              >
                <div>
                  <span>
                    <p>Tanda tangan,</p>
                    <p>(Nama lengkap Penerima)</p>
                  </span>
                  <div
                    style={{
                      borderBottom: "1px solid black",

                      marginLeft: "auto",
                      marginTop: "75px"
                    }}
                  />
                </div>
                <div>
                  <span>
                    <p>Tanda tangan,</p>
                    <p>(Nama lengkap Customer)</p>
                  </span>
                  <div
                    style={{
                      borderBottom: "1px solid black",

                      marginLeft: "auto",
                      marginTop: "75px"
                    }}
                  />
                </div>
              </div>
            </Aux>
          </div>
        );
        return (
          <div key={index} className={classes.ReportFormPrintWrapper}>
            <div className={classes.ReportFormPrint}>
              {reportHeaderLayout}

              <div className={classes.WrapperAfterHeader}>
                <div className={classes.DetailLayout}>
                  {reportDetailLayout}
                  {customerDetailLayout}
                </div>
                {productLayout}
                {descriptionLayout}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ReportFormPrint;
//<div style={{}}>Entry Date</div>
