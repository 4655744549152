import React, { Component } from "react";
import MdCreate from "react-ionicons/lib/MdCreate";

import classes from "./Section.scss";

class Section extends Component {
  render() {
    return (
      <div className={classes.Section}>
        <div
          className={classes.Section__Grouper}
          style={!this.props.title && { marginTop: "15px" }}
        >
          {/* wrapper for .Section__Title for inline-block(auto width) to work */}
          {this.props.title ? (
            <div>
              (
              <div className={classes.Section__Title}>
                <h4>{this.props.title}</h4>
              </div>
              )
            </div>
          ) : null}
          <div className={classes.Section__Content}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default Section;
