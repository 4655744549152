import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import IosEyeOffOutline from "react-ionicons/lib/IosEyeOffOutline";
import IosEyeOutline from "react-ionicons/lib/IosEyeOutline";

import classes from "./Auth.scss";
import { updateObject } from "../../share/js/utility";
import { acAuth } from "../../stores/actions";
import { baseUrl } from "../../share/js/config.json";
import InfoDialog from "../../components/InfoDialog/InfoDialog";

class Dashboard extends Component {
  state = {
    usernameOrEmailInputId: { touched: false, focusing: false, value: "" },
    passwordInputId: { touched: false, focusing: false, show: false, value: "" }
  };

  componentDidMount() {
    // scroll to top of the page
    window.scrollTo(0, 0);
  }

  inputChangeHandler = (evt, inputId) => {
    console.log("input");
    //NOTE: we MUST clone the object because object is PASS by REFERENCE.
    const value = evt.target.value;
    const updateForm = updateObject(this.state, {
      [inputId]: updateObject(this.state[inputId], {
        touched: true,
        value
      })
    });

    // let formIsValid = true;
    // for (const element in updateForm) {
    //   formIsValid = formIsValid && updateForm[element].valid;
    // }

    // console.log(formIsValid);

    // this.setState({
    //   formIsValid: formIsValid,
    //   form: updateForm
    // });

    this.setState({
      ...updateForm
    });
  };

  setInputFocus = (val, id) => {
    this.setState(state => {
      return {
        ...state,
        [id]: {
          ...state[id],
          focusing: val
        }
      };
    });
  };

  passwordBtnHandler = () => {
    this.setState(state => {
      return {
        ...state,
        passwordInputId: {
          ...state.passwordInputId,
          show: !state.passwordInputId.show
        }
      };
    });
  };

  handleSubmit = evt => {
    evt.preventDefault();

    const { usernameOrEmailInputId, passwordInputId } = this.state;
    const usernameOrEmail = usernameOrEmailInputId.value;
    const password = passwordInputId.value;
    // console.log(usernameOrEmail, password);

    this.props.onSignIn(usernameOrEmail, password);
  };

  render() {
    console.log(this.props.error);
    const usernameOrEmailInputId = "usernameOrEmailInputId";
    const passwordInputId = "passwordInputId";

    // let authRedirect = this.props.isAuthenticated ? (
    //   <Redirect to={this.props.authRedirectPath} />
    // ) : null;

    console.log(this.props.history);
    console.log(this.props.history.location);
    console.log(this.props.history.location.pathname);
    let authRedirect = this.props.isAuthenticated ? (
      <Redirect to={"/"} />
    ) : null;

    return (
      <div className={classes.Auth}>
        <InfoDialog
          show={this.props.error}
          message={this.props.error}
          okHandler={() => this.props.clearAuthError()}
        />

        {authRedirect}
        <div className={classes.Form}>
          <div>
            <div className={classes.Title}>
              <h2>Sign In</h2>
            </div>
            <div
              className={[
                classes.InputWrapper,
                this.state.usernameOrEmailInputId.focusing ? classes.active : ""
              ].join(" ")}
            >
              <label
                htmlFor={usernameOrEmailInputId}
                style={{
                  display:
                    this.state.usernameOrEmailInputId.touched &&
                    this.state.usernameOrEmailInputId.value !== ""
                      ? "none"
                      : "flex"
                }}
              >
                Username or Email
              </label>
              <input
                className={classes.Input}
                id={usernameOrEmailInputId}
                name="usernameOrEmail"
                onChange={evt =>
                  this.inputChangeHandler(evt, usernameOrEmailInputId)
                }
                autoComplete="off"
                onFocus={() => this.setInputFocus(true, usernameOrEmailInputId)}
                onBlur={() => this.setInputFocus(false, usernameOrEmailInputId)}
              />
            </div>

            <div
              className={[
                classes.InputWrapper,
                this.state.passwordInputId.focusing ? classes.active : ""
              ].join(" ")}
            >
              <label
                htmlFor={passwordInputId}
                style={{
                  display:
                    this.state.passwordInputId.touched &&
                    this.state.passwordInputId.value !== ""
                      ? "none"
                      : "flex"
                }}
              >
                Password
              </label>
              <input
                className={[classes.Input, classes.InputPassword].join(" ")}
                id={passwordInputId}
                name="password"
                onChange={evt => this.inputChangeHandler(evt, passwordInputId)}
                autoComplete="off"
                onFocus={() => this.setInputFocus(true, passwordInputId)}
                onBlur={() => this.setInputFocus(false, passwordInputId)}
                type={this.state.passwordInputId.show ? "text" : "password"}
              />
              <span
                className={classes.InputIcon}
                onClick={this.passwordBtnHandler}
              >
                {this.state.passwordInputId.show ? (
                  <IosEyeOutline />
                ) : (
                  <IosEyeOffOutline />
                )}
              </span>
            </div>

            <div className={classes.FormButton}>
              <button onClick={this.handleSubmit}>Sign In</button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.authReducer.loading,
    isAuthenticated: state.authReducer.token !== null,
    authRedirectPath: state.authReducer.authRedirectPath,
    error: state.authReducer.error
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onSignIn: (usernameOrEmail, password) =>
      dispatch(acAuth.signIn(usernameOrEmail, password)),
    setAuthRedirectPath: path => dispatch(acAuth.setAuthRedirectPath(path)),
    clearAuthError: () => dispatch(acAuth.clearError())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard);
