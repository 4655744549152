import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { lang } from "../../../../share/js/localization";

class AlertDialog extends React.Component {
  //   state = {
  //     open: true
  //   };

  yesHandler = () => {
    this.setState({ open: true });
    this.props.yesHandler();
  };
  noHandler = () => {
    this.props.noHandler();
  };

  render() {
    return (
      <div>
        {/* <Button
          variant="outlined"
          color="primary"
          onClick={this.handleClickOpen}
        >
          Open alert dialog
        </Button> */}
        <Dialog
          open={this.props.show}
          onClose={this.noHandler}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {this.props.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.noHandler} color="primary">
              {lang.cancel}
            </Button>
            <Button onClick={this.yesHandler} color="primary" autoFocus>
              {lang.yes}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default AlertDialog;
