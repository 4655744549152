import React, { Component } from "react";
import { connect } from "react-redux";

import classes from "./AddDialog.scss";
import Dialog from "../Dialog/Dialog";
import { lang } from "../../../share/js/localization";
import Aux from "../../../hoc/Aux/Aux";
import InfoDialog from "../../../components/InfoDialog/InfoDialog";
import { toCaps, checkIsEmail, updateObject } from "../../../share/js/utility";
import AddCategoryDialog from "../AddCategoryDialog/AddCategoryDialog";
import Actions from "./Actions/Actions";
import Content from "./Content/Content";

class AddDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddedInfo: false,
      showCannotAddInfo: false,
      showAddCategoryDialog: false,
      form: this.formDefault()
    };
  }

  formDefault = _ => ({
    name: "",
    addresses: ["alamat 1"],
    emails: ["email 1"],
    phones: ["0"],
    contactPersons: [this.contactPersonObj()]
  });

  contactPersonObj = _ => ({
    fname: "",
    lname: "",
    phones: ["0"]
  });

  resetFormInput = _ => {
    this.setState({
      form: this.formDefault()
    });
  };

  addAddressHandler = () => {
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        addresses: [...state.form.addresses].concat("")
      }
    }));
  };

  removeAddressHandler = () => {
    if (this.state.form.addresses.length === 1) return;
    this.setState(state => {
      const copied = [...state.form.addresses];
      copied.pop();

      return {
        ...state,
        form: {
          ...state.form,
          addresses: copied
        }
      };
    });
  };

  addEmailHandler = () => {
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        emails: [...state.form.emails].concat("")
      }
    }));
  };
  removeEmailHandler = () => {
    if (this.state.form.emails.length === 1) return;
    this.setState(state => {
      const copied = [...state.form.emails];
      copied.pop();

      return {
        ...state,
        form: {
          ...state.form,
          emails: copied
        }
      };
    });
  };

  addPhoneHandler = () => {
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        phones: [...state.form.phones].concat("")
      }
    }));
  };
  removePhoneHandler = () => {
    if (this.state.form.phones.length === 1) return;

    this.setState(state => {
      const copied = [...state.form.phones];
      copied.pop();

      return {
        ...state,
        form: {
          ...state.form,
          phones: copied
        }
      };
    });
  };

  addContactPersonHandler = () => {
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        contactPersons: [...state.form.contactPersons].concat(
          this.contactPersonObj()
        )
      }
    }));
  };
  removeContactPersonHandler = () => {
    if (this.state.form.contactPersons.length === 1) return;

    this.setState(state => {
      const copied = [...state.form.contactPersons];
      copied.pop();

      return {
        ...state,
        form: {
          ...state.form,
          contactPersons: copied
        }
      };
    });
  };
  addContactPersonPhoneHandler = index => {
    this.setState(state => {
      const copied = [...state.form.contactPersons];
      copied[index].phones.push("0");

      return {
        ...state,
        form: {
          ...state.form,
          contactPersons: copied
        }
      };
    });

    console.log(this.state);
  };
  removeContactPersonPhoneHandler = index => {
    if (this.state.form.contactPersons[index].phones.length === 1) return;

    this.setState(state => {
      const copied = [...state.form.contactPersons];
      copied[index].phones.pop();

      return {
        ...state,
        form: {
          ...state.form,
          contactPersons: copied
        }
      };
    });
  };

  inputOnChangeHandler = (evt, key) => {
    const value = evt.target.value;
    const splittedKey = key.split("_");

    if (splittedKey.length > 1) {
      const splittedKeyIndex = splittedKey[splittedKey.length - 1];

      if (
        splittedKey[0] === "addresses" ||
        splittedKey[0] === "emails" ||
        splittedKey[0] === "phones"
      ) {
        this.setState(state => {
          const copied = state.form[splittedKey[0]].concat(); // copy of addresses array
          copied[splittedKeyIndex] = value;

          return updateObject(state, {
            form: {
              ...state.form,
              [splittedKey[0]]: copied
            }
          });
        });
      }

      console.log(splittedKey);

      if (splittedKey[0] === "contactPersons") {
        if (splittedKey.length < 4) {
          // ex. ["contactPersons", "0", "fname"]
          const contactPersons = splittedKey[0];
          const parentKey = splittedKey[1];
          const contactPersonChild = splittedKey[2];

          this.setState(state => {
            const copied = state.form[contactPersons].concat(); // copy of contactPersons array
            copied[parentKey][contactPersonChild] = value;

            return updateObject(state, {
              form: {
                ...state.form,
                contactPersons: copied
              }
            });
          });

          return;
        }

        // ex. ["contactPersons", "0", "phones", "0"]
        const contactPersons = splittedKey[0];
        const parentKey = splittedKey[1];
        const contactPersonChild = splittedKey[2];
        const childKey = splittedKey[3];

        this.setState(state => {
          const copied = state.form[contactPersons].concat(); // copy of contactPersons array
          copied[parentKey][contactPersonChild][childKey] = value;

          return updateObject(state, {
            form: {
              ...state.form,
              contactPersons: copied
            }
          });
        });
      }

      return;
    }

    this.setState(state =>
      updateObject(state, {
        form: {
          ...state.form,
          [key]: value
        }
      })
    );
  };

  okInfoHandler = () => {
    this.setState({ showAddedInfo: false });
    this.resetFormInput();
  };

  okCannotAddInfoHandler = () => {
    this.setState(state => ({
      ...state,
      showCannotAddInfo: false
    }));
  };

  /// title handler ///
  closeHandler = () => {
    this.props.visibilityHandler();
  };

  /// actions handler ///
  addProductHandler = () => {
    let {
      name,
      category: { value: category },
      vendor: { value: vendor }
    } = this.state.form;
    name = name.trim();

    if ((name.split(" ").length < 1 || !category, !vendor)) {
      this.setState({ showCannotAddInfo: true });
      return;
    }

    this.setState({ showAddedInfo: true });
  };

  /// Content handler ///
  changeAddCategoryDialogVisibility = () => {
    this.setState(state => ({
      ...state,
      showAddCategoryDialog: !state.showAddCategoryDialog
    }));
  };

  addCategoryHandler = () => {
    this.changeAddCategoryDialogVisibility();
  };

  vendorOnSearchHandler = () => {};

  render() {
    const {
      showAddedInfo,
      showCannotAddInfo,
      showAddCategoryDialog,
      form
    } = this.state;
    // const {
    //   name,
    //   description,
    //   vendor: { value: vendor },
    // } = form;

    const { show } = this.props;

    return (
      <Aux>
        <Dialog
          // show={show || true}
          show={show}
          closeHandler={this.closeHandler}
          title={toCaps(lang.addVendor)}
          Actions={() => (
            <Actions
              handler={{
                addProductHandler: this.addProductHandler
              }}
            />
          )}
          Content={() => (
            <div className={classes.AddDialog}>
              <Content
                handler={{
                  inputOnChangeHandler: this.inputOnChangeHandler,
                  vendorOnSearchHandler: this.vendorOnSearchHandler,
                  categoryOnChangeHandler: this.categoryOnChangeHandler,
                  addAddressHandler: this.addAddressHandler,
                  removeAddressHandler: this.removeAddressHandler,
                  addEmailHandler: this.addEmailHandler,
                  removeEmailHandler: this.removeEmailHandler,
                  addPhoneHandler: this.addPhoneHandler,
                  removePhoneHandler: this.removePhoneHandler,
                  addContactPersonHandler: this.addContactPersonHandler,
                  removeContactPersonHandler: this.removeContactPersonHandler,
                  addContactPersonPhoneHandler: this
                    .addContactPersonPhoneHandler,
                  removeContactPersonPhoneHandler: this
                    .removeContactPersonPhoneHandler
                }}
                inputValue={{
                  // name: name,
                  // description: description,
                  // vendor: vendor,
                  // category: category,
                  addresses: this.state.form.addresses,
                  emails: this.state.form.emails,
                  phones: this.state.form.phones,
                  contactPersons: this.state.form.contactPersons
                }}
              />
            </div>
          )}
        />
        <InfoDialog
          show={showAddedInfo}
          title={toCaps(lang.product)}
          // message={lang.formatString(
          //   lang.addMessage
          //   `(Product: ${name}, Vendor: ${vendor}, Category: ${category})`
          // )}
          okHandler={this.okInfoHandler}
          closeHandler={() => {}}
        />
        <InfoDialog
          show={showCannotAddInfo}
          title={toCaps(lang.product)}
          message={"Cannot add product. Please re-check and try again."}
          okHandler={this.okCannotAddInfoHandler}
          closeHandler={() => {}}
        />
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    categories: { key: { value: "" } }
  };
};

export default connect(mapStateToProps)(AddDialog);
