import React from "react";
import MaterialDialog from "@material-ui/core/Dialog";
import Title from "./Title/Title";
import Content from "./Content/Content";
import Actions from "./Actions/Actions";

const Dialog = props => {
  const {
    show,
    closeHandler,
    title,
    Content: ContentComp,
    Actions: ActionsComp
  } = props;

  return (
    <div>
      <MaterialDialog
        onClose={closeHandler}
        aria-labelledby="customized-dialog-title"
        open={show}
        maxWidth="lg"
      >
        <Title id="customized-dialog-title" onClose={closeHandler}>
          {title}
        </Title>
        <Content>{ContentComp()}</Content>
        <Actions>{ActionsComp()}</Actions>
      </MaterialDialog>
    </div>
  );
};

Dialog.propTypes = {};

export default Dialog;
