import * as actionTypes from "../actions/types";
import { updateObject } from "../../share/js/utility";

const initialState = {
  users: [],
  loading: false
};

const getAllUsers = (state, action) => {
  const { users } = action.payload;

  return updateObject(state, {
    users,
    loading: false
  });
};

const fetching = (state, action) => {
  return updateObject(state, {
    loading: true
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_USERS_SUCCESS:
      return getAllUsers(state, action);
    case actionTypes.USER_FETCHING:
      return fetching(state, action);
    default:
      break;
  }

  return state;
};

// const reducer = (state = initialState, action) => {
//   switch (action.type) {
//     case actionTypes.CHECK_AUTH_STATE:
//       return checkAuthState(state, action);
//     case actionTypes.SET_AUTH_REDIRECT_PATH:
//       return setAuthRedirectPath(state, action);
//     case actionTypes.AUTH_ON_PROCESS:
//       return authOnProcess(state, action);
//     case actionTypes.AUTH_SUCCESS:
//       return authSuccess(state, action);
//     case actionTypes.AUTH_FAIL:
//       return authFail(state, action);
//     case actionTypes.AUTH_SIGN_OUT:
//       return authSignOut(state, action);
//     default:
//       break;
//   }

//   return state;
// };

export default reducer;
