import React, { Component } from "react";

import classes from "./TextBtn.scss";
import { joinClassNames } from "../../share/js/utility";

const TextBtn = props => {
  // console.log(props);
  return (
    <div
      className={classes.TextBtn}
      style={props.style ? props.style.grouper : null}
    >
      <div
        className={joinClassNames(classes.Btn)}
        onClick={props.onClick}
        style={Object.assign(
          { width: props.fit ? "auto" : "100%" },
          props.style ? props.style.btn : null
        )}
      >
        {props.Symbol ? (
          <props.Symbol
            style={props.style ? props.style.icon : null}
            fontSize={
              props.style
                ? props.style.icon
                  ? props.style.icon.fontSize
                  : "22px"
                : "22px"
            }
          />
        ) : null}
        {props.title}
      </div>
    </div>
  );
};

export default TextBtn;
