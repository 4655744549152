import React, { Component } from "react";
import { connect } from "react-redux";

import classes from "./ViewDialog.scss";
import Dialog from "../Dialog/Dialog";
import { lang } from "../../../share/js/localization";
import Aux from "../../../hoc/Aux/Aux";
import InfoDialog from "../../../components/InfoDialog/InfoDialog";
import { toCaps, checkIsEmail, updateObject } from "../../../share/js/utility";
import AddCategoryDialog from "../AddCategoryDialog/AddCategoryDialog";
import Actions from "./Actions/Actions";
import Content from "./Content/Content";

class ViewDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAddedInfo: false,
      showCannotAddInfo: false,
      showAddCategoryDialog: false,
      form: this.formDefault()
    };
  }

  componentWillReceiveProps(props) {
    const { name, description, category, vendor } = props.data;

    this.setState({
      form: {
        name: name,
        description: description,
        category: {
          id: "",
          value: category
        },
        vendor: {
          id: "",
          value: vendor
        }
      }
    });
  }

  formDefault = _ => ({
    name: "",
    description: "",
    category: {
      id: "",
      value: ""
    },
    vendor: {
      id: "",
      value: ""
    }
  });

  resetFormInput = _ => {
    this.setState({
      form: this.formDefault()
    });
  };

  inputOnChangeHandler = (evt, key) => {
    const value = evt.target.value;

    if (key === "category" || key === "vendor") {
      // this.setState(state =>
      //   updateObject(state, {
      //     form: {
      //       ...state.form,
      //       [key]: { id: value, value: this.props.categories[value].value }
      //     }
      //   })
      // );

      // before mysql
      this.setState(state =>
        updateObject(state, {
          form: {
            ...state.form,
            [key]: { id: 0, value }
          }
        })
      );
    } else {
      this.setState(state =>
        updateObject(state, {
          form: {
            ...state.form,
            [key]: value
          }
        })
      );
    }
  };

  okInfoHandler = () => {
    this.setState({ showAddedInfo: false });
    this.resetFormInput();
  };

  okCannotAddInfoHandler = () => {
    this.setState(state => ({
      ...state,
      showCannotAddInfo: false
    }));
  };

  /// title handler ///
  closeHandler = () => {
    this.props.visibilityHandler();
  };

  /// actions handler ///
  addProductHandler = () => {
    let {
      name,
      category: { value: category },
      vendor: { value: vendor }
    } = this.state.form;
    name = name.trim();

    if ((name.split(" ").length < 1 || !category, !vendor)) {
      this.setState({ showCannotAddInfo: true });
      return;
    }

    this.setState({ showAddedInfo: true });
  };

  /// Content handler ///
  changeAddCategoryDialogVisibility = () => {
    this.setState(state => ({
      ...state,
      showAddCategoryDialog: !state.showAddCategoryDialog
    }));
  };

  addCategoryHandler = () => {
    this.changeAddCategoryDialogVisibility();
  };

  vendorOnSearchHandler = () => {};

  render() {
    const {
      showAddedInfo,
      showCannotAddInfo,
      showAddCategoryDialog,
      form
    } = this.state;
    const {
      name,
      description,
      vendor: { value: vendor },
      category: { value: category }
    } = form;

    const { show } = this.props;

    return (
      <Aux>
        <Dialog
          show={show}
          closeHandler={this.closeHandler}
          title={lang.viewProduct}
          Actions={() => (
            <Actions
              handler={{
                addProductHandler: this.addProductHandler
              }}
            />
          )}
          Content={() => (
            <div className={classes.ViewDialog}>
              <Content
                handler={{
                  inputOnChangeHandler: this.inputOnChangeHandler,
                  vendorOnSearchHandler: this.vendorOnSearchHandler,
                  categoryOnChangeHandler: this.categoryOnChangeHandler
                }}
                inputValue={{
                  name: name,
                  description: description,
                  vendor: vendor,
                  category: category
                }}
              />
            </div>
          )}
        />
        <InfoDialog
          show={showAddedInfo}
          title={toCaps(lang.product)}
          message={lang.formatString(
            lang.addMessage,
            `(Product: ${name}, Vendor: ${vendor}, Category: ${category})`
          )}
          okHandler={this.okInfoHandler}
          closeHandler={() => {}}
        />
        <InfoDialog
          show={showCannotAddInfo}
          title={toCaps(lang.product)}
          message={"Cannot add product. Please re-check and try again."}
          okHandler={this.okCannotAddInfoHandler}
          closeHandler={() => {}}
        />
        {/* <AddCategoryDialog
          show={showAddCategoryDialog}
          title={toCaps(lang.addCategory)}
          visibilityHandler={this.addCategoryDialogVisibilityHandler}
        /> */}
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    categories: { key: { value: "" } }
  };
};

export default connect(mapStateToProps)(ViewDialog);
