import React, { Component } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import MdArrowRoundUp from "react-ionicons/lib/MdArrowRoundUp";

import classes from "./Toolbar.scss";
import DrawerToggle from "../Drawer/DrawerToggle/DrawerToggle";
import NavigationItems from "../Navigation/NavigationItems/NavigationItems";
import MenuList from "../Navigation/Account/MenuList/MenuList";
import Aux from "../../hoc/Aux/Aux";
import SideDrawer from "../Drawer/SideDrawer/SideDrawer";
import { extractPathBeforeFirstSlash } from "../../share/js/utility";

// const currentMenu = "Dashboard";

class ScrollButton extends Component {
  constructor() {
    super();

    this.state = { showDisplay: false, showOpacity: false, intervalId: 0 };
  }

  scrollStep() {
    const scrollStepInPx = this.props.scrollStepInPx || 50;

    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId); // clear the "interval"
    }

    window.scroll(0, window.pageYOffset - scrollStepInPx);
  }

  scrollUpHandler = () => {
    const delayInMs = this.props.delayInMs || 16.66;
    let intervalId = setInterval(this.scrollStep.bind(this), delayInMs);
    this.setState({ intervalId: intervalId });
  };

  setShowDisplayState = val => {
    this.setState(state => ({
      ...state,
      showDisplay: val
    }));

    console.log(val);
  };

  setShowOpacityState = val => {
    this.setState(state => ({
      ...state,
      showOpacity: val
    }));
  };

  // show or hide btn
  handleScroll = evt => {
    let scrollTop = evt.srcElement.scrollingElement.scrollTop;

    if (scrollTop > 200 && !this.state.showDisplay) {
      this.setShowDisplayState(true);
      setTimeout(() => this.setShowOpacityState(true), 200); // play opacity after display
    } else if (scrollTop < 200 && this.state.showOpacity) {
      this.setShowOpacityState(false);
      setTimeout(() => this.setShowDisplayState(false), 200); // play display after opacity
    }
  };

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
  };

  render() {
    return (
      <div
        className={classes.GoToTop}
        onClick={this.scrollUpHandler}
        style={{
          display: this.state.showDisplay ? "flex" : "none",
          opacity: this.state.showOpacity ? 1 : 0
        }}
      >
        <this.props.Symbol color="#fff" />
      </div>
    );
  }
}

export default ScrollButton;
