import React from "react";
import MdClose from "react-ionicons/lib/MdClose";
import styled, { keyframes } from "styled-components";
import { merge, fadeIn, fadeOut } from "react-animations";

import classes from "./SideDrawer.scss";
import Links from "./Links/Links";
import { textColor } from "../../../share/style/_variables";
import Aux from "../../../hoc/Aux/Aux";
import MobileLinks from "./MobileLinks/MobileLinks";

let fadeInAnimation = keyframes`${fadeIn}`;
const FadeInDiv = styled.div`
  animation: 0.2s ${fadeInAnimation};
  animation-fill-mode: forwards;
  animation-timing-function: ease-in;
`;

const SideDrawer = props => {
  let mobileSideDrawer = null;

  if (props.show) {
    mobileSideDrawer = (
      <FadeInDiv
        className={classes.MobileOnly}
        onClick={props.sideDrawerCloseHandler}
      >
        <span
          className={classes.CloseBtn}
          onClick={e => {
            e.stopPropagation();
            return props.sideDrawerCloseHandler();
          }}
        >
          <MdClose color={textColor} fontSize="22px" />
        </span>
        <MobileLinks show={props.show} />
      </FadeInDiv>
    );
  }

  return (
    <Aux>
      {/* Desktop */}
      <div className={classes.SideDrawer}>
        {/* <div className={classes.Backdrop} /> */}
        <Links show={props.show} />
      </div>
      {mobileSideDrawer}
    </Aux>
  );
};

export default SideDrawer;
