import * as actionTypes from "../actions/types";
import { updateObject } from "../../share/js/utility";

const initialState = {
  vendors: [],
  vendor: {},
  loading: false,
  error: null,
  postVendorSuccess: false,
  deleteVendorSuccess: false,
  updateVendorSuccess: false
};

// GET VENDOR
const fetching = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
    postVendorSuccess: false,
    deleteVendorSuccess: false
  });
};

const getAllVendorsSuccess = (state, action) => {
  const { vendors } = action.payload;

  return updateObject(state, {
    vendors,
    loading: false,
    error: null
  });
};

const getAllVendorsFail = (state, action) => {
  const { error } = action.payload;

  return updateObject(state, {
    vendors: [],
    error: error,
    loading: false
  });
};
const getVendorSuccess = (state, action) => {
  const { vendor } = action.payload;

  return updateObject(state, {
    vendor,
    loading: false,
    error: null
  });
};

const getVendorFail = (state, action) => {
  const { error } = action.payload;

  return updateObject(state, {
    vendor: {},
    error: error,
    loading: false
  });
};

// DELETE VENDOR
const deleteVendor = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
    deleteVendorSuccess: false
  });
};

const deleteVendorSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    deleteVendorSuccess: true
  });
};

const deleteVendorFail = (state, action) => {
  const { error } = action.payload;

  return updateObject(state, {
    loading: false,
    error: error,
    deleteVendorSuccess: false
  });
};

const clearDeleteVendorState = state => {
  return updateObject(state, {
    loading: false,
    error: null,
    deleteVendorSuccess: false
  });
};

// UPDATE VENDOR
const updateVendor = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
    updateVendorSuccess: false
  });
};

const updateVendorSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    updateVendorSuccess: true
  });
};

const updateVendorFail = (state, action) => {
  const { error } = action.payload;

  return updateObject(state, {
    loading: false,
    error: error,
    updateVendorSuccess: false
  });
};

const clearUpdateVendorState = state => {
  return updateObject(state, {
    loading: false,
    error: null,
    updateVendorSuccess: false
  });
};

// POST VENDOR
const postVendor = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
    postVendorSuccess: false
  });
};

const postVendorSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    postVendorSuccess: true
  });
};
const postVendorFail = (state, action) => {
  const { error } = action.payload;

  return updateObject(state, {
    loading: false,
    error: error,
    postVendorSuccess: false
  });
};

const clearPostVendorState = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    postVendorSuccess: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_VENDORS_SUCCESS:
      return getAllVendorsSuccess(state, action);
    case actionTypes.GET_ALL_VENDORS_FAIL:
      return getAllVendorsFail(state, action);
    case actionTypes.GET_VENDOR_SUCCESS:
      return getVendorSuccess(state, action);
    case actionTypes.GET_VENDOR_FAIL:
      return getVendorFail(state, action);
    case actionTypes.VENDOR_FETCHING:
      return fetching(state, action);

    case actionTypes.CLEAR_DELETE_VENDOR_STATE:
      return clearDeleteVendorState(state);
    case actionTypes.DELETE_VENDOR:
      return deleteVendor(state, action);
    case actionTypes.DELETE_VENDOR_SUCCESS:
      return deleteVendorSuccess(state, action);
    case actionTypes.DELETE_VENDOR_FAIL:
      return deleteVendorFail(state, action);

    case actionTypes.CLEAR_UPDATE_VENDOR_STATE:
      return clearUpdateVendorState(state);
    case actionTypes.UPDATE_VENDOR:
      return updateVendor(state, action);
    case actionTypes.UPDATE_VENDOR_SUCCESS:
      return updateVendorSuccess(state, action);
    case actionTypes.UPDATE_VENDOR_FAIL:
      return updateVendorFail(state, action);

    case actionTypes.CLEAR_POST_VENDOR_STATE:
      return clearPostVendorState(state, action);
    case actionTypes.POST_VENDOR:
      return postVendor(state, action);
    case actionTypes.POST_VENDOR_SUCCESS:
      return postVendorSuccess(state, action);
    case actionTypes.POST_VENDOR_FAIL:
      return postVendorFail(state, action);
    default:
      break;
  }

  return state;
};

export default reducer;
