import React, { Component } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import MdArrowRoundUp from "react-ionicons/lib/MdArrowRoundUp";

import classes from "./Toolbar.scss";
import DrawerToggle from "../Drawer/DrawerToggle/DrawerToggle";
import NavigationItems from "../Navigation/NavigationItems/NavigationItems";
import MenuList from "../Navigation/Account/MenuList/MenuList";
import Aux from "../../hoc/Aux/Aux";
import SideDrawer from "../Drawer/SideDrawer/SideDrawer";
import { extractPathBeforeFirstSlash } from "../../share/js/utility";
import ScrollButton from "./ScrollButton";
import { lang } from "../../share/js/localization";

// const currentMenu = "Dashboard";

class Toolbar extends Component {
  constructor() {
    super();

    this.state = {
      menuShow: false,
      sideDrawerShow: false,
      headerTitle: "Dashboard"
    };
  }

  accountClickedHandler = () => {
    this.setState(state => ({
      menuShow: !state.menuShow
    }));
  };

  drawerToggleClickedHandler = () => {
    this.setState(state => ({
      sideDrawerShow: !state.sideDrawerShow
    }));
  };

  menuListOnClickHandler = () => {
    this.setState(state => ({
      menuShow: false
    }));
  };

  componentWillMount() {
    const headerTitle = extractPathBeforeFirstSlash(
      this.props.history.location.pathname
    )
      .replace(/\//g, "")
      .toLowerCase();

    this.setState(state => {
      return {
        ...state,
        headerTitle: (lang[headerTitle] || "Dashboard").toUpperCase()
      };
    });
  }

  componentWillReceiveProps() {
    // console.log(this.props);
    const headerTitle = extractPathBeforeFirstSlash(
      this.props.history.location.pathname
    )
      .replace(/\//g, "")
      .toLowerCase();

    this.setState(state => {
      return {
        ...state,
        headerTitle: (lang[headerTitle] || "Dashboard").toUpperCase()
      };
    });
  }

  render() {
    return (
      <Aux>
        <header className={classes.Toolbar}>
          <DrawerToggle clicked={this.drawerToggleClickedHandler} />
          <div className={classes.Logo}>{this.state.headerTitle}</div>
          <nav>
            <NavigationItems
              // isAuthenticated={this.props.isAuthenticated}
              accountClickedHandler={this.accountClickedHandler}
            />
          </nav>
          <ScrollButton
            Symbol={MdArrowRoundUp}
            scrollStepInPx={100}
            // delayInMs={8}
          />
        </header>
        <MenuList
          show={this.state.menuShow}
          onClickHandler={this.menuListOnClickHandler}
        />
        <SideDrawer
          show={this.state.sideDrawerShow}
          sideDrawerCloseHandler={this.drawerToggleClickedHandler}
          isAuthenticated={this.props.isAuthenticated}
        />
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    headerTitle: state.navigationReducer.headerTitle
  };
};

export default withRouter(connect(mapStateToProps)(Toolbar));
