import * as actionTypes from "../actions/types";
import { updateObject } from "../../share/js/utility";

const initialState = {
  productReportStatuses: [],
  loading: false,
  error: null
};

// GET REPORT STATUSES
const getProductReportStatuses = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    productReportStatuses: []
  });
};

const getProductReportStatusesSuccess = (state, action) => {
  const { productReportStatuses } = action.payload;
  return updateObject(state, {
    loading: false,
    error: null,
    productReportStatuses: productReportStatuses
  });
};

const getProductReportStatusesFail = (state, action) => {
  const { error } = action.payload;

  return updateObject(state, {
    loading: false,
    error: error,
    productReportStatuses: []
  });
};

const clearGetProductReportStatusesState = state => {
  return updateObject(state, {
    loading: false,
    error: null
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_GET_PRODUCT_REPORT_STATUSES_STATE:
      return clearGetProductReportStatusesState(state);
    case actionTypes.GET_PRODUCT_REPORT_STATUSES:
      return getProductReportStatuses(state);
    case actionTypes.GET_PRODUCT_REPORT_STATUSES_SUCCESS:
      return getProductReportStatusesSuccess(state, action);
    case actionTypes.GET_PRODUCT_REPORT_STATUSES_FAIL:
      return getProductReportStatusesFail(state, action);
    default:
      break;
  }

  return state;
};

export default reducer;
