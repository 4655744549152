import React, { Component } from "react";
import { connect } from "react-redux";
import { PulseLoader } from "react-spinners";

import classes from "./ViewVendor.scss";
import FormVendor from "../FormVendor";
import { acVendor } from "../../../stores/actions";
import { isObjEmpty } from "../../../share/js/utility";

// NOTE:
// because "ViewVendor" component is directly assigned to "Route"
// e.g. <Route path="/vendor/:id" component={ViewVendor} />
// this.props.history is accessible.
// "FormVendor" component is NOT assigned to "Route" so we can't use "history.goBack()"
class ViewVendor extends Component {
  componentDidMount() {
    const vendorId = this.props.match.params.id;

    // fetch vendor
    this.props.getVendorById(vendorId);
  }

  render() {
    // fetchData: {
    //   form: {
    //     name: "NAME Realx",
    //     addresses: ["ADDRESS"],
    //     emails: ["EMAIL"],
    //     phones: ["000", "123"],
    //     contactPersons: [
    //       {
    //         fname: "FIRST",
    //         lname: "LASTs",
    //         phones: ["000", "111222"],
    //         emails: ["EMAIL"]
    //       }
    //     ]
    //   }
    // }

    const vendor = this.props.vendor;
    let parsedVendor = {};

    if (!isObjEmpty(vendor)) {
      parsedVendor = {
        id: vendor.id,
        name: vendor.name,
        addresses: vendor.addresses.map(address => address.address),
        emails: vendor.emails.map(email => email.email),
        phones: vendor.phones.map(phone => phone.phone_number),
        contactPersons: vendor.contactPersons.map(contactPerson => {
          return {
            ...contactPerson,
            emails: contactPerson.emails.map(email => email.email),
            phones: contactPerson.phones.map(phone => phone.phone_number)
          };
        })
      };
      console.log("parsedVendor::::::", parsedVendor);
    }

    let formComponent = "Vendor not found.";

    if (!this.props.loading && !isObjEmpty(vendor)) {
      formComponent = (
        <FormVendor {...this.props} data={parsedVendor} mode={"view"} />
      );
    }

    if (this.props.loading) {
      formComponent = (
        <PulseLoader
          sizeUnit={"px"}
          size={12}
          color={"#123abc"}
          css={{ textAlign: "center" }}
          loading={this.props.loading}
        />
      );
    }

    return <div className={classes.ViewVendor}>{formComponent}</div>;
  }
}

const mapStateToProps = state => {
  return {
    loading: state.vendorReducer.loading,
    vendor: state.vendorReducer.vendor
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getVendorById: id => dispatch(acVendor.getVendorById(id))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewVendor);
