import React, { Component } from "react";
import { connect } from "react-redux";
import MdSearch from "react-ionicons/lib/MdSearch";
import IosPrint from "react-ionicons/lib/IosPrint";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { PulseLoader } from "react-spinners";
import RSelect from "react-select";

import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import classes from "./Report.scss";
import EnhancedTable from "./Table/EnhancedTable";
import Section from "./Section/Section";
import Form from "../Settings/Form/Form";
import Input from "../Settings/Form/Input/Input";
import { toCaps, toLower } from "../../share/js/utility";
import TextBtn from "../../components/TextBtn/TextBtn";
import { lang } from "../../share/js/localization";
import Select from "../Settings/Form/Select/Select";
import AddDialog from "./AddDialog/AddDialog";
import {
  searchBtnColor,
  warningColor,
  backBtnColor
} from "../../share/style/_variables";
import ViewDialog from "./ViewDialog/ViewDialog";
import PrintBtnWithPrintJs from "../../components/PrintBtnWithPrintJs/PrintBtnWithPrintJs";
import CustomReactToPrint from "../../components/CustomReactToPrint/CustomReactToPrint";
import ReportFormPrint from "./Dialog/ReportFormPrint/ReportFormPrint";
import {
  acReportStatus,
  acReport,
  acProduct,
  acProductReportStatus,
  acVendor
} from "../../stores/actions";
// import ViewDialog from "./Dialog/Dialog_bu";

class Report extends Component {
  state = {
    // openAddProductDialog: false,
    // openViewProductDialog: false,
    form: {
      id: "",
      technician: "",
      customerName: "",
      status: {
        id: 0
      },
      // entryDate: [new Date(), new Date()],
      entryDate: [null, null],
      product: {
        id: 0,
        status: {
          id: 0
        },
        serialNumber: ""
      },
      vendor: { value: 0, id: 0, label: "", name: "" }
    },
    loading: false,
    printClicked: false
  };

  onChangeDatePickerRange = (date, index) => {
    // 0 => startDate, 1 => endDate
    const copiedEntryDate = this.state.form.entryDate.concat();
    copiedEntryDate[index] = date;

    this.setState(state => {
      return { form: { ...state.form, entryDate: copiedEntryDate } };
    });

    console.log(this.state.form);
  };

  addProductDialogVisibilityHandler = () => {
    this.setState(state => ({
      openAddProductDialog: !state.openAddProductDialog
    }));
  };

  viewProductDialogVisibilityHandler = () => {
    this.setState(state => ({
      openViewProductDialog: !state.openViewProductDialog
    }));
  };

  addReportHandler = () => {
    console.log(this.props.history);
    this.props.history.push({
      pathname: `${this.props.history.location.pathname}/add`
    });
    // this.addProductDialogVisibilityHandler();
  };

  deleteProductHandler = () => {};
  editProductHandler = () => {};

  componentDidMount() {
    // scroll to top of the page
    window.scrollTo(0, 0);

    // FETCH ALL USERS FOR TABLE
    this.props.getReportStatuses();
    this.props.getProductReportStatuses();
    this.props.getAllProducts();
    this.props.getAllReports();
    this.props.getAllVendors();
  }

  inputOnChangeHandler = (section, evt) => {
    const value = evt.target.value;
    console.log(value);

    if (section === "productSerialNumber") {
      this.setState(state => {
        return {
          form: {
            ...state.form,
            product: {
              ...state.form.product,
              serialNumber: value
            }
          }
        };
      });
    }

    this.setState(state => {
      return {
        form: {
          ...state.form,
          [section]: value
        }
      };
    });
  };

  changeStatusHandler = evt => {
    const value = evt.target.value;

    this.setState(state => {
      return {
        form: {
          ...state.form,
          status: { id: value }
        }
      };
    });
  };

  changeProductStatusHandler = evt => {
    const value = evt.target.value || 0;

    this.setState(state => {
      return {
        form: {
          ...state.form,
          product: {
            ...state.form.product,
            status: {
              id: value
            }
          }
        }
      };
    });
  };

  productOnChangeHandler = selectedOption => {
    selectedOption = selectedOption || { value: 0 };

    console.log("selectedOption::::", selectedOption.value);

    this.setState(state => ({
      form: {
        ...state.form,
        product: {
          ...state.form.product,
          id: selectedOption.value
        }
      }
    }));
  };

  vendorOnChangeHandler = selectedOption => {
    selectedOption = selectedOption || { value: 0, id: 0, label: "", name: "" };

    console.log("selectedOption::::", selectedOption);

    this.setState(state => ({
      form: {
        ...state.form,
        vendor: {
          ...selectedOption,
          id: selectedOption.value
        }
      }
    }));
  };

  viewReportHandler = id => {
    this.props.history.push({
      pathname: this.props.history.location.pathname + "/" + id
    });
  };

  render() {
    console.log("reports::::::", this.props.reports);

    const parsedReportStatusesForSelect = [
      { value: 0, text: "" },
      ...this.props.reportStatuses.map(reportStatus => ({
        value: reportStatus.id,
        text: reportStatus.title
      }))
    ];

    const parsedProductReportStatusesForSelect = [
      { value: 0, text: "" },
      ...this.props.productReportStatuses.map(productReportStatus => ({
        value: productReportStatus.id,
        text: productReportStatus.title
      }))
    ];

    const parsedProductForSelect = [
      ...this.props.products.map(product => ({
        value: product.id,
        label: product.name,
        category: product.category
        // vendor: { id: product.vendor.id, name: product.vendor.name }
      }))
    ];

    const parsedVendorForSelect = [
      ...this.props.vendors.map(vendor => ({
        value: vendor.id,
        label: vendor.name,
        name: vendor.name
      }))
    ];

    let table = (
      <PulseLoader
        sizeUnit={"px"}
        size={12}
        color={"#123abc"}
        css={{ textAlign: "center" }}
        loading={this.state.loading}
      />
    );

    if (
      !this.props.reportLoading &&
      !this.props.vendorLoading &&
      !this.props.productLoading &&
      !this.props.productReportStatusesLoading &&
      !this.props.reportStatusesLoading
    ) {
      table = (
        <EnhancedTable
          viewHandler={this.viewReportHandler}
          searchTableData={this.state.form}
          reports={this.props.reports}
          products={this.props.products}
          vendors={this.props.vendors}
          productReportStatuses={this.props.productReportStatuses}
          reportStatuses={this.props.reportStatuses}
        />
      );
    }

    return (
      <div className={classes.Product}>
        <Section>
          <Form>
            <Input
              label={toCaps("id")}
              onChange={evt => this.inputOnChangeHandler("id", evt)}
            />
            <Input label={"Status"}>
              <Select
                value={this.state.form.status.id}
                onChange={this.changeStatusHandler}
                options={parsedReportStatusesForSelect}
              />
            </Input>

            <Input label={toCaps(lang.entryDate)}>
              <div className={classes.CustomDatePickerWrapper}>
                <DatePicker
                  selected={this.state.form.entryDate[0]}
                  selectsStart
                  startDate={this.state.form.entryDate[0]}
                  endDate={this.state.form.entryDate[1]}
                  onChange={date => this.onChangeDatePickerRange(date, 0)}
                  className={classes.CustomDatePicker}
                  dateFormat={"dd/MM/yyyy"}
                />
                <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>
                <DatePicker
                  selected={this.state.form.entryDate[1]}
                  selectsEnd
                  startDate={this.state.form.entryDate[0]}
                  endDate={this.state.form.entryDate[1]}
                  onChange={date => this.onChangeDatePickerRange(date, 1)}
                  className={classes.CustomDatePicker}
                  dateFormat={"dd/MM/yyyy"}
                />
              </div>
            </Input>
            <Input
              label={lang.customerName}
              onChange={evt => this.inputOnChangeHandler("customerName", evt)}
            />
            <Input>
              <div style={{ border: "1px solid #ccc", width: "100%" }} />
            </Input>
            <Input label={toCaps(lang.product)} searchableSelect>
              <RSelect
                isDisabled={false}
                className="basic-single"
                classNamePrefix="select"
                defaultValue={null}
                isClearable={true}
                isSearchable={true}
                name="Product"
                options={parsedProductForSelect}
                onChange={this.productOnChangeHandler}
              />
            </Input>
            <Input label={toCaps(lang.productStatus)}>
              <Select
                value={this.state.form.product.status.id}
                onChange={this.changeProductStatusHandler}
                options={parsedProductReportStatusesForSelect}
              />
            </Input>

            <Input label={toCaps(lang.vendor)} searchableSelect>
              <RSelect
                isDisabled={false}
                className="basic-single"
                classNamePrefix="select"
                defaultValue={null}
                isClearable={true}
                isSearchable={true}
                name="Vendor"
                options={parsedVendorForSelect}
                onChange={this.vendorOnChangeHandler}
              />
            </Input>

            <Input
              label={toCaps(lang.productSerialNumber)}
              onChange={evt =>
                this.inputOnChangeHandler("productSerialNumber", evt)
              }
            />
            {/* <Input style={{ justifyContent: "flex-end", flexDirection: "row" }}>
              <TextBtn
                title={toCaps("search")}
                onClick={this.addVendorHandler}
                fit
                style={{ btn: { background: warningColor } }}
              />
            </Input> */}

            <Input
              style={{
                justifyContent: "flex-start",
                flexDirection: "row",
                marginTop: "25px"
              }}
            >
              <TextBtn
                title={toCaps(lang.addReport)}
                onClick={this.addReportHandler}
                fit
                style={{}}
              />
            </Input>
          </Form>

          {table}
          {/* TODO: remove componentToPrint below */}
          {/* {componentToPrint} */}
        </Section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    reports: state.reportReducer.reports || [],
    reportStatuses: state.reportStatusReducer.reportStatuses,
    error: state.reportReducer.error,
    productReportStatuses:
      state.productReportStatusReducer.productReportStatuses,

    products: state.productReducer.products,
    vendors: state.vendorReducer.vendors,

    vendorLoading: state.vendorReducer.loading,
    productLoading: state.productReducer.loading,
    reportLoading: state.reportReducer.reportLoading,
    productReportStatusesLoading: state.productReportStatusReducer.loading,
    reportStatusesLoading: state.reportStatusReducer.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getReportStatuses: _ => dispatch(acReportStatus.getReportStatuses()),
    getAllProducts: () => dispatch(acProduct.getAllProducts()),
    getAllVendors: _ => dispatch(acVendor.getAllVendors()),
    getAllReports: _ => dispatch(acReport.getAllReports()),
    getProductReportStatuses: _ =>
      dispatch(acProductReportStatus.getProductReportStatuses()),
    clearPostReportState: _ => dispatch(acReport.clearPostReportState())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Report);
