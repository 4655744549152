import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import IosRemove from "react-ionicons/lib/IosRemove";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import green from "@material-ui/core/colors/green";
import TableHead from "./TableHead/TableHead";
import EnhancedTableToolbar from "./TableToolbar/TableToolbar";
import Toolbar from "./TableToolbar/TableToolbar";
import { lang } from "../../../share/js/localization";
import { toCaps, escapeRegExp } from "../../../share/js/utility";
import TextBtn from "../../../components/TextBtn/TextBtn";
import { TableFooter } from "@material-ui/core";

/* 

Symbol button for table
<TextBtn
  Symbol={IosRemove}
  style={{
    icon: { fill: "#fff", fontSize: "22px" },
    btn: { padding: 0, height: "auto" }
  }}
  fit
/>; */

const CustomTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const checkBoxStyles = theme => ({
  root: {
    color: green[600],
    "&$checked": {
      color: green[500]
    }
  },
  checked: {}
});

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox);

let counter = 0;
function createData(username, email, type, status, actions) {
  counter += 1;
  return { id: counter, username, email, type, status, actions };
}

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  {
    id: "username",
    numeric: false,
    disablePadding: false,
    // label: "Dessert (100g serving)"
    label: toCaps(lang.username)
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: toCaps(lang.email)
  },
  {
    id: "type",
    numeric: true,
    disablePadding: false,
    label: toCaps("type")
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: toCaps("Status")
  },

  { id: "actions", action: true, disablePadding: false, label: "Actions" }
];

// const data = [
//   createData("Aupcake", "email1@e.com", "admin", "active", ["view"]),
//   createData("Bupcake", "email1@e.com", "staff", "disabled", ["view"]),
//   createData("Cupcake", "email1@e.com", "staff", "disabled", ["view"]),
//   createData("Dupcake", "email1@e.com", "staff", "disabled", ["view"]),
//   createData("Eupcake", "email1@e.com", "staff", "disabled", ["view"]),
//   createData("fupcake", "bmail1@e.com", "staff", "disabled", ["view"]),
//   createData("gupcake", "email1@e.com", "staff", "disabled", ["view"]),
//   createData("hupcake", "email1@e.com", "staff", "disabled", ["view"])
// ];

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  table: {
    // minWidth: 800
  },
  tableWrapper: {
    overflowX: "auto"
  }
});

class EnhancedTable extends React.Component {
  state = {
    order: "asc",
    orderBy: "username",
    selected: [],
    // data: data,
    page: 0,
    rowsPerPage: 5
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: state.data.map(n => n.id) }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
    // this.setState({ selected: [id] });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const { classes, usernameOrEmail } = this.props;
    let { order, orderBy, selected, rowsPerPage, page } = this.state;
    // let data = this.props.users;
    let data = this.props.users.map(user => {
      // from server: { id: 4, username: 'u1', email: 'e1', isActive: 1, isAdmin: 1 }
      const { id, username, email, isActive, isAdmin } = user;
      return {
        id,
        username,
        email,
        isActive,
        isAdmin,
        actions: ["view"]
      };
    });

    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    data = this.filterData(usernameOrEmail, data);

    return (
      <Paper className={classes.root}>
        {/* <Toolbar
          numSelected={selected.length}
          title={this.props.toolbarTitle || "asd"}
        /> */}
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <TableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              rows={rows}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      // onClick={event => this.handleClick(event, n.id)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      {/* <TableCell padding="checkbox">
                        <CustomCheckbox checked={isSelected} />
                      </TableCell> */}
                      <TableCell align="left">{n.username}</TableCell>
                      <TableCell align="left">{n.email}</TableCell>
                      <TableCell align="right">
                        {n.isAdmin ? "Admin" : "Staff"}
                      </TableCell>
                      <TableCell align="right">
                        {n.isActive ? "Active" : "NotActive"}
                      </TableCell>
                      {n.actions.map(action => {
                        if (action == "view") {
                          return (
                            <TableCell align="right" key={"actions" + n.id}>
                              <TextBtn
                                onClick={() =>
                                  this.props.viewOnClickHandler({
                                    id: n.id,
                                    username: n.username,
                                    email: n.email,
                                    isAdmin: n.isAdmin,
                                    isActive: n.isActive
                                  })
                                }
                                title={lang.view}
                                fit
                                style={{ btn: { backgroundColor: "#ec407a" } }}
                              />
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  // colSpan={0}
                  // style={{ display: "flex" }}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  // component="div"
                  backIconButtonProps={{
                    "aria-label": "Previous Page"
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page"
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );
  }

  filterData(usernameOrEmail, data) {
    if (usernameOrEmail != "") {
      const usernameOrEmailEscaped = escapeRegExp(usernameOrEmail);
      console.log(usernameOrEmailEscaped);
      const regex = RegExp(`.*${usernameOrEmailEscaped}.*`, "gi");
      data = data.filter(obj => {
        regex.lastIndex = 0; // reset regex to enable reuse
        return regex.test(obj.username) || regex.test(obj.email);
      });
    }
    return data;
  }
}

// "properties" past into "this component"
EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired,
  usernameOrEmail: PropTypes.string.isRequired
};

export default withStyles(styles)(EnhancedTable);
