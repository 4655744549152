import React, { Component } from "react";
import { lang } from "../../../../share/js/localization";
import Aux from "../../../../hoc/Aux/Aux";
import TextBtn from "../../../../components/TextBtn/TextBtn";
import { toCaps } from "../../../../share/js/utility";
import {
  primaryBtnColor,
  dangerColor,
  warningColor,
  editBtnColor,
  backBtnColor
} from "../../../../share/style/_variables";
import { Button } from "@material-ui/core";

const Actions = props => {
  const { edit } = props;
  const {
    editHandler,
    cancelEditHandler,
    saveChangesHandler,
    deleteProductHandler
  } = props.handler;

  // BUTTONS
  let editResetBtn = (
    <TextBtn
      title={toCaps(lang.edit)}
      onClick={editHandler}
      fit
      style={{
        grouper: { justifyContent: "center", marginRight: "auto" },
        btn: { background: editBtnColor }
      }}
    />
  );

  let saveChangesBtn = null;

  if (edit) {
    editResetBtn = (
      <TextBtn
        title={toCaps(lang.cancelEdit)}
        onClick={cancelEditHandler}
        fit
        style={{
          grouper: { justifyContent: "center", marginRight: "auto" },
          btn: { background: backBtnColor }
        }}
      />
    );

    saveChangesBtn = (
      <TextBtn
        title={toCaps(lang.saveChanges)}
        onClick={saveChangesHandler}
        fit
        style={{
          grouper: { justifyContent: "center" },
          btn: { background: warningColor }
        }}
      />
    );
  }

  return (
    <Aux>
      {editResetBtn}
      <TextBtn
        title={toCaps(lang.delete)}
        onClick={deleteProductHandler}
        fit
        style={{
          grouper: { justifyContent: "center" },
          btn: { background: dangerColor }
        }}
      />
      {/* {props.fieldChanged ? null : (
        <TextBtn
          title={toCaps(lang.saveChanges)}
          onClick={props.handler.saveChangesHandler}
          fit
          style={{
            grouper: { justifyContent: "center" },
            btn: { background: warningColor }
          }}
        />
      )} */}

      {saveChangesBtn}
    </Aux>
  );
};

export default Actions;
