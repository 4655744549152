import React, { Component } from "react";
import { connect } from "react-redux";

import Aux from "../../hoc/Aux/Aux";
import SideDrawer from "../../components/Drawer/SideDrawer/SideDrawer";
import Toolbar from "../../components/Toolbar/Toolbar";

import classes from "./Layout.scss";

class Layout extends Component {
  render() {
    let layout = (
      <main className={[classes.Content].join(" ")}>{this.props.children}</main>
    );

    if (this.props.isAuthenticated) {
      layout = (
        <Aux>
          <Toolbar isAuthenticated={this.props.isAuthenticated} />
          <main
            className={[classes.Content, classes.ContentWithToolbar].join(" ")}
          >
            {this.props.children}
          </main>
        </Aux>
      );
    }

    return layout;
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.authReducer.token !== null
  };
};

export default connect(mapStateToProps)(Layout);
