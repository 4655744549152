import React, { Component } from "react";
import { connect } from "react-redux";

import classes from "./AddDialog.scss";
import Dialog from "../Dialog/Dialog";
import { lang } from "../../../share/js/localization";
import Aux from "../../../hoc/Aux/Aux";
import InfoDialog from "../../../components/InfoDialog/InfoDialog";
import {
  toCaps,
  checkIsEmail,
  updateObject,
  isStringEmpty
} from "../../../share/js/utility";
import AddCategoryDialog from "../AddCategoryDialog/AddCategoryDialog";
import Actions from "./Actions/Actions";
import Content from "./Content/Content";
import { acProduct, acCategory } from "../../../stores/actions";

class AddDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      info: {
        fieldError: false,
        fieldErrorMessage: ""
      },
      showAddCategoryDialog: false,
      form: this.formDefault()
    };
  }

  formDefault = _ => ({
    name: "",
    description: "",
    category: { id: 0 }, // Select Component cannot have "null" value
    vendor: { id: null } // we use custom-select component so it can be null
  });

  resetFormInput = _ => {
    this.setState(state => ({
      form: { ...this.formDefault(), vendor: state.form.vendor }
    }));
  };

  categoryOnChangeHandler = evt => {
    let categoryId = evt.target.value || 0;

    this.setState(state =>
      updateObject(state, {
        form: {
          ...state.form,
          category: { id: categoryId }
        }
      })
    );
  };

  vendorOnChangeHandler = selectedOption => {
    const vendorId = selectedOption ? selectedOption.value : null;

    this.setState(state =>
      updateObject(state, {
        form: {
          ...state.form,
          vendor: { id: vendorId }
        }
      })
    );
  };

  inputOnChangeHandler = (evt, key) => {
    let value = evt.target.value;

    this.setState(state =>
      updateObject(state, {
        form: {
          ...state.form,
          [key]: value
        }
      })
    );

    // console.log(this.state.form);
  };

  okInfoHandler = section => {
    if (section === "fieldError") {
      this.setState({
        info: {
          fieldError: false,
          fieldErrorMessage: ""
        }
      });
      return;
    }

    if (section === "customError") {
      this.props.clearPostProductState();
    }

    if (section === "success") {
      this.props.clearPostProductState();
      this.resetFormInput();

      console.log("SUCCESSSSSSSSS", this.state.form);
    }
  };

  /// title handler ///
  closeHandler = () => {
    this.props.visibilityHandler();

    // re-fetch products
    this.props.getAllProducts();
  };

  /// actions handler ///
  addProductHandler = () => {
    let {
      name,
      category: { id: categoryId },
      // vendor: { id: vendorId },
      description
    } = this.state.form;

    // if (vendorId == 0 || vendorId == null) {
    //   this.setState({
    //     info: {
    //       fieldError: true,
    //       fieldErrorMessage: lang.formatString(
    //         lang.fieldMustBeFill,
    //         `${toCaps(lang.vendor)}`
    //       )
    //     }
    //   });
    //   return;
    // }

    if (isStringEmpty(name)) {
      this.setState({
        info: {
          fieldError: true,
          fieldErrorMessage: lang.formatString(
            lang.fieldMustBeFill,
            `${toCaps(lang.name)}`
          )
        }
      });
      return;
    }

    if (categoryId == 0 || categoryId == null) {
      this.setState({
        info: {
          fieldError: true,
          fieldErrorMessage: lang.formatString(
            lang.fieldMustBeFill,
            `${toCaps(lang.category)}`
          )
        }
      });
      return;
    }

    // if (name.split(" ").length < 1 || !categoryId || !vendorId) {
    //   this.setState({ showCannotAddInfo: true });
    //   return;
    // }

    // ADD PRODUCT
    this.props.postProduct({
      name,
      categoryId,
      // vendorId,
      description
    });
  };

  /// Content handler ///
  changeAddCategoryDialogVisibility = () => {
    this.setState(state => ({
      ...state,
      showAddCategoryDialog: !state.showAddCategoryDialog
    }));
  };

  showCategoryHandler = () => {
    this.changeAddCategoryDialogVisibility();
  };

  deleteCategoryHandler = () => {
    const categoryId = this.state.form.category.id;
    this.props.deleteCategoryById(categoryId);

    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        category: {
          id: 0
        }
      }
    }));
  };

  postCategoryHandler = name => {
    this.props.postCategory({ name });
  };

  parsedError = () => {
    const error = this.props.postError;

    if (error) {
      if (error == `Column 'category_id' cannot be null`) {
        return lang.formatString(lang.fieldMustBeFill, "Category");
      }

      if (error.includes("Duplicate entry")) {
        if (error.includes("unique_name_category")) {
          return lang.formatString(
            lang.fieldAlreadyExistError,
            lang.formatString(lang.combinationOfTwo, lang.name, lang.category)
          );
        }
      }
    }

    return error;
  };

  render() {
    const { showAddCategoryDialog, form } = this.state;
    const {
      name,
      description,
      vendor: { id: vendorId },
      category: { id: categoryId }
    } = form;

    const parsedVendorForSelect = this.props.vendors.map(vendor => ({
      value: vendor.id,
      label: `${vendor.name}`
    }));

    const parsedCategoryForSelect = [
      { value: 0, text: "" },
      ...this.props.categories.map(category => ({
        value: category.id,
        text: toCaps(category.name)
      }))
    ];

    // console.log("parsedCategoryForSelect::::", parsedCategoryForSelect);

    const { show } = this.props;

    const { fieldError, fieldErrorMessage } = this.state.info;

    let customError =
      !this.props.postProductSuccess && !isStringEmpty(this.props.postError);
    let success =
      this.props.postProductSuccess && isStringEmpty(this.props.postError);

    let infoDialogs = null;

    if (show) {
      infoDialogs = (
        <Aux>
          <InfoDialog
            show={fieldError}
            message={fieldErrorMessage}
            okHandler={() => this.okInfoHandler("fieldError")}
          />
          <InfoDialog
            show={customError}
            message={this.parsedError()}
            okHandler={() => this.okInfoHandler("customError")}
          />
          <InfoDialog
            show={success}
            message={lang.generalSuccess}
            okHandler={() => this.okInfoHandler("success")}
          />
        </Aux>
      );
    }

    return (
      <Aux>
        {infoDialogs}
        <Dialog
          show={show}
          closeHandler={this.closeHandler}
          title={toCaps(lang.addProduct)}
          Actions={() => (
            <Actions
              handler={{
                addProductHandler: this.addProductHandler
              }}
            />
          )}
          Content={() => (
            <div className={classes.AddDialog}>
              <Content
                handler={{
                  inputOnChangeHandler: this.inputOnChangeHandler,
                  vendorOnSearchHandler: this.vendorOnSearchHandler,
                  categoryOnChangeHandler: this.categoryOnChangeHandler,
                  vendorOnChangeHandler: this.vendorOnChangeHandler,
                  showCategoryHandler: this.showCategoryHandler,
                  deleteCategoryHandler: this.deleteCategoryHandler
                }}
                inputValue={{
                  name: name,
                  description: description,
                  categoryId: categoryId,
                  vendorId: vendorId // IGNORE
                }}
                parsedVendorForSelect={parsedVendorForSelect}
                parsedCategoryForSelect={parsedCategoryForSelect}
              />
            </div>
          )}
        />

        <AddCategoryDialog
          show={showAddCategoryDialog}
          title={toCaps(lang.addCategory)}
          visibilityHandler={this.changeAddCategoryDialogVisibility}
          postCategoryHandler={this.postCategoryHandler}
        />
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    vendors: state.vendorReducer.vendors,
    categories: state.categoryReducer.categories,
    postError: state.productReducer.error,
    postProductSuccess: state.productReducer.postProductSuccess
  };
};

const mapDispatchToProps = dispatch => {
  return {
    postCategory: data => dispatch(acCategory.postCategory(data)),
    deleteCategoryById: id => dispatch(acCategory.deleteCategoryById(id)),
    postProduct: data => dispatch(acProduct.postProduct(data)),
    getAllProducts: _ => dispatch(acProduct.getAllProducts()),
    clearPostProductState: _ => dispatch(acProduct.clearPostProductState())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddDialog);
