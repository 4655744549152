import React, { Component } from "react";
import { connect } from "react-redux";
import RSelect from "react-select";

import classes from "./Product.scss";
import EnhancedTable from "./Table/EnhancedTable";
import Section from "./Section/Section";
import Form from "../Settings/Form/Form";
import Input from "../Settings/Form/Input/Input";
import { toCaps, toLower, updateObject } from "../../share/js/utility";
import TextBtn from "../../components/TextBtn/TextBtn";
import { lang } from "../../share/js/localization";
import Select from "../Settings/Form/Select/Select";
import AddDialog from "./AddDialog/AddDialog";
import { searchBtnColor, warningColor } from "../../share/style/_variables";
import ViewDialog from "./ViewDialog/ViewDialog";
import { acProduct, acVendor, acCategory } from "../../stores/actions";
import SearchableSelect from "../../components/SearchableSelect/SearchableSelect";
import { PulseLoader } from "react-spinners";
import Aux from "../../hoc/Aux/Aux";
// import ViewDialog from "./Dialog/Dialog_bu";

class Product extends Component {
  state = {
    openAddProductDialog: false,
    openViewProductDialog: false,
    viewProductData: {},
    searchTableData: {
      vendor: { id: null },
      category: { id: 0 },
      name: ""
    }
  };

  resetSearch = () => {
    this.setState(state => ({
      searchTableData: {
        vendor: { id: null },
        category: { id: 0 },
        name: ""
      }
    }));
  };

  addProductDialogVisibilityHandler = () => {
    this.setState(state => ({
      openAddProductDialog: !state.openAddProductDialog
    }));
  };

  viewProductDialogVisibilityHandler = () => {
    this.setState(state => ({
      openViewProductDialog: !state.openViewProductDialog
    }));
  };

  addProductHandler = () => {
    this.addProductDialogVisibilityHandler();
  };

  viewProductHandler = viewProductData => {
    this.setState({ viewProductData });
    this.viewProductDialogVisibilityHandler();
  };

  deleteProductHandler = () => {};
  editProductHandler = () => {};

  componentWillMount() {
    // handle addProduct clicked from dashboard
    if (this.props.history.location.state) {
      const { openAddProductDialog } = this.props.history.location.state;

      this.setState(state => ({
        ...state,
        openAddProductDialog: openAddProductDialog || false
      }));
    }

    // clear state from history
    this.props.history.replace({
      pathname: "/product",
      state: {}
    });
  }

  componentDidMount() {
    // scroll to top of the page
    window.scrollTo(0, 0);

    // FETCH ALL PRODUCTS FOR TABLE
    this.props.getAllProducts();
    this.props.getAllVendors();
    this.props.getAllCategories();
  }

  vendorOnChangeHandler = selectedOption => {
    const vendorId = selectedOption ? selectedOption.value : null;
    this.setState(state => ({
      searchTableData: { ...state.searchTableData, vendor: { id: vendorId } }
    }));

    // console.log("vendorId::::", selectedOption);
  };

  categoryOnChangeHandler = evt => {
    let value = evt.target.value || null;

    this.setState(state => ({
      searchTableData: { ...state.searchTableData, category: { id: value } }
    }));
  };

  inputOnChangeHandler = evt => {
    const value = evt.target.value;

    this.setState(state =>
      updateObject(state, {
        searchTableData: {
          ...state.searchTableData,
          name: value
        }
      })
    );
  };

  test = () => {
    console.log(this.state.searchTableData);
  };

  render() {
    const parsedVendorForSelect = this.props.vendors.map(vendor => ({
      value: vendor.id,
      label: toCaps(vendor.name)
    }));

    const parsedCategoryForSelect = [
      { value: 0, text: "" },
      ...this.props.categories.map(category => ({
        value: category.id,
        text: toCaps(category.name)
      }))
    ];

    // console.log("vendors", parsedVendorForSelect);

    let productLayout = (
      <Aux>
        <Form>
          {/* <Input label={lang.vendor} searchableSelect>
            <RSelect
              isDisabled={false}
              className="basic-single"
              classNamePrefix="select"
              defaultValue={null}
              isClearable={true}
              isSearchable={true}
              name="Vendor"
              options={parsedVendorForSelect}
              onChange={this.vendorOnChangeHandler}
            />
          </Input> */}

          <Input label={lang.name} onChange={this.inputOnChangeHandler} />
          <Input label={lang.category}>
            <Select
              value={this.state.searchTableData.category.id}
              onChange={this.categoryOnChangeHandler}
              options={parsedCategoryForSelect}
            />
          </Input>

          <Input style={{ justifyContent: "flex-start", flexDirection: "row" }}>
            <TextBtn
              title={toCaps(lang.addProduct)}
              onClick={this.addProductHandler}
              fit
              style={{}}
            />
          </Input>
        </Form>

        <EnhancedTable
          viewProductHandler={this.viewProductHandler}
          products={this.props.products}
          searchTableData={this.state.searchTableData}
        />
      </Aux>
    );

    // loading
    if (
      (this.props.productLoading &&
        !this.state.openAddProductDialog &&
        !this.state.openViewProductDialog) ||
      this.props.vendorLoading ||
      (this.props.categoryLoading &&
        !this.state.openAddProductDialog &&
        !this.state.openViewProductDialog)
    ) {
      productLayout = (
        <PulseLoader
          sizeUnit={"px"}
          size={12}
          color={"#123abc"}
          css={{ textAlign: "center" }}
        />
      );
    }

    return (
      <div className={classes.Product}>
        <AddDialog
          title={toCaps(lang.addProduct)}
          // viewedUserObj={this.state.viewedUserObj}
          visibilityHandler={this.addProductDialogVisibilityHandler}
          show={this.state.openAddProductDialog}
        />
        <ViewDialog
          title={toCaps(lang.viewProduct)}
          // viewedUserObj={this.state.viewedUserObj}
          visibilityHandler={this.viewProductDialogVisibilityHandler}
          show={this.state.openViewProductDialog}
          viewData={this.state.viewProductData}
          history={this.props.history}
          resetSearch={this.resetSearch}
        />
        <Section>{productLayout}</Section>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    products: state.productReducer.products,
    vendors: state.vendorReducer.vendors,
    vendorLoading: state.vendorReducer.loading,
    productLoading: state.productReducer.loading,
    categories: state.categoryReducer.categories,
    categoryLoading: state.categoryReducer.loading
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllProducts: () => dispatch(acProduct.getAllProducts()),
    getAllVendors: () => dispatch(acVendor.getAllVendors()),
    getAllCategories: () => dispatch(acCategory.getAllCategories())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Product);
