import React from 'react';
import classes from './DrawerToggle.scss';

// NOTE: we specify the "css" to "display: none" if we are on "desktop".
const drawerToggle = (props) => (
    <div
        className={classes.DrawerToggle}
        onClick={props.clicked}>
        <div></div>
        <div></div>
        <div></div>
    </div>
)

export default drawerToggle;