import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import IosRemove from "react-ionicons/lib/IosRemove";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import green from "@material-ui/core/colors/green";
import TableHead from "./TableHead/TableHead";
import EnhancedTableToolbar from "./TableToolbar/TableToolbar";
import Toolbar from "./TableToolbar/TableToolbar";
import { lang } from "../../../share/js/localization";
import { toCaps, isStringEmpty } from "../../../share/js/utility";
import TextBtn from "../../../components/TextBtn/TextBtn";
import { TableFooter } from "@material-ui/core";

function desc(a, b, orderBy) {
  // name, description => string
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  // vendor & category => object => {id:, name:}
  if (orderBy === "vendor" || orderBy === "category") {
    if (b[orderBy].name < a[orderBy].name) {
      return -1;
    }
    if (b[orderBy].name > a[orderBy].name) {
      return 1;
    }
  }

  return 0;
}

// NOTE: cmp => getSorting(order, orderBy)
function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

// NOTE: orderBy is TABLE'S COLUMN HEADER NAME
function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: toCaps(lang.name)
  },
  {
    id: "category",
    numeric: false,
    disablePadding: false,
    label: toCaps(lang.category)
  },

  // {
  //   id: "vendor",
  //   numeric: false,
  //   disablePadding: false,
  //   // label: "Dessert (100g serving)"
  //   label: toCaps(lang.vendor)
  // },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: toCaps(lang.description)
  },

  {
    id: "actions",
    action: true,
    disablePadding: false,
    label: toCaps(lang.actions)
  }
];

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  table: {
    // minWidth: 800
  },
  tableWrapper: {
    overflowX: "auto"
  }
});

class EnhancedTable extends React.Component {
  state = {
    order: "asc",
    orderBy: "name",
    selected: [],
    page: 0,
    rowsPerPage: 5
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  // handleSelectAllClick = event => {
  //   if (event.target.checked) {
  //     this.setState(state => ({ selected: state.data.map(n => n.id) }));
  //     return;
  //   }
  //   this.setState({ selected: [] });
  // };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
    // this.setState({ selected: [id] });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    let data = this.props.products.map(product => {
      /*
        from server: 
        {
          category: {id: 1, title: "hardware"}
          description: "description here..."
          id: 3
          name: "LG Smart tv"
          vendor: {id: 8, name: "LG Corp", addresses: Array(1), emails: Array(4), phones: Array(1), …}
        }
      
      */

      const { id, name, category, description, vendor } = product;
      // const parsedVendor = vendor ? vendor.name : "";
      // const parsedCategory = category ? category.name : "";

      return {
        id,
        name,
        description,
        category,
        // vendor: { id: vendor.id, name: vendor.name },
        actions: ["view"]
      };
    });

    const { classes, searchTableData } = this.props;
    let { order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    // console.log("searchTableData::::", searchTableData, data);

    /* searchTableData:
      {
        name: ""
        category: {id: 0}
        vendor: {id: null}
      }
    */
    data = this.filterData(searchTableData, data);

    return (
      <Paper className={classes.root}>
        {/* <Toolbar
          numSelected={selected.length}
          title={this.props.toolbarTitle || "asd"}
        /> */}
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-labelledby="tableTitle">
            <TableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              // onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={data.length}
              rows={rows}
            />
            <TableBody>
              {stableSort(data, getSorting(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(n => {
                  const isSelected = this.isSelected(n.id);
                  return (
                    <TableRow
                      hover
                      // onClick={event => this.handleClick(event, n.id)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n.id}
                      selected={isSelected}
                    >
                      {/* <TableCell padding="checkbox">
                        <CustomCheckbox checked={isSelected} />
                      </TableCell> */}
                      <TableCell align="left">{n.name}</TableCell>
                      <TableCell align="left">{n.category.name}</TableCell>
                      {/* <TableCell align="left">{n.vendor.name}</TableCell> */}
                      <TableCell align="left">{n.description}</TableCell>
                      {n.actions.map(action => {
                        if (action == "view") {
                          return (
                            <TableCell align="right" key={"actions" + n.id}>
                              <TextBtn
                                onClick={() =>
                                  this.props.viewProductHandler({ ...n })
                                }
                                title={lang.view}
                                fit
                                style={{ btn: { backgroundColor: "#ec407a" } }}
                              />
                            </TableCell>
                          );
                        }
                      })}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 49 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  // colSpan={0}
                  // style={{ display: "flex" }}
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  // component="div"
                  backIconButtonProps={{
                    "aria-label": "Previous Page"
                  }}
                  nextIconButtonProps={{
                    "aria-label": "Next Page"
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );
  }

  filterData(searchObj, products) {
    const {
      name,
      category: { id: categoryId },
      vendor: { id: vendorId }
    } = searchObj;

    if (!isStringEmpty(name)) {
      const regex = RegExp(`.*${name}.*`, "gi");
      products = products.filter(product => {
        regex.lastIndex = 0; // reset regex to enable reuse
        return regex.test(product.name);
      });
    }

    if (categoryId != 0) {
      products = products.filter(product => {
        return categoryId == product.category.id;
      });
    }

    // REMEMBER: we use custom-select so the value can be null
    if (vendorId != 0 && vendorId != null) {
      products = products.filter(product => {
        return vendorId == product.vendor.id;
      });
    }

    return products;
  }
}

// "properties" past into "this component", if "isRequired", will show warning in inspector
EnhancedTable.propTypes = {
  // classes: PropTypes.object.isRequired
  // usernameOrEmail: PropTypes.number.isRequired
};

export default withStyles(styles)(EnhancedTable);
