// actions => do JOBS, reducers => change STATE base on action

import * as actionTypes from "./types";
import axios from "../../share/js/axios";
import { TOKEN, USER_ID } from "../../share/js/constants";

export const authenticating = () => {
  return {
    type: actionTypes.AUTH_AUTHENTICATING
  };
};

const fetching = _ => {
  return {
    type: actionTypes.USER_FETCHING
  };
};

const getAllUsersSuccess = data => {
  return {
    type: actionTypes.GET_ALL_USERS_SUCCESS,
    payload: { ...data }
  };
};

export const getAllUsers = () => {
  return dispatch => {
    dispatch(fetching());

    axios
      .get("/users", {
        headers: {
          "x-auth": localStorage.getItem(TOKEN)
        }
      })
      .then(res => {
        let { data: users } = res.data;
        // console.log(users);

        // const authData = { username: username, [TOKEN]: token, [USER_ID]: id };

        // saveObjectToLocalStorage(authData);

        dispatch(getAllUsersSuccess({ users }));
      })
      .catch(err => {
        // console.log("err /login:", err);
        // dispatch(fail(err.response.data));
      });
  };
};
