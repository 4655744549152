import * as actionTypes from "../actions/types";
import { updateObject } from "../../share/js/utility";

const initialState = {
  products: [],
  product: {},
  loading: false,
  error: null,
  postProductSuccess: false,
  deleteProductSuccess: false,
  updateProductSuccess: false
};

// GET PRODUCT
const fetching = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
    postProductSuccess: false,
    deleteProductSuccess: false
  });
};

const getAllProductsSuccess = (state, action) => {
  const { products } = action.payload;

  return updateObject(state, {
    products,
    loading: false,
    error: null
  });
};

const getAllProductsFail = (state, action) => {
  const { error } = action.payload;

  return updateObject(state, {
    products: [],
    error: error,
    loading: false
  });
};
const getProductSuccess = (state, action) => {
  const { product } = action.payload;

  return updateObject(state, {
    product,
    loading: false,
    error: null
  });
};

const getProductFail = (state, action) => {
  const { error } = action.payload;

  return updateObject(state, {
    product: {},
    error: error,
    loading: false
  });
};

// DELETE PRODUCT
const deleteProduct = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
    deleteProductSuccess: false
  });
};

const deleteProductSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    deleteProductSuccess: true
  });
};

const deleteProductFail = (state, action) => {
  const { error } = action.payload;

  return updateObject(state, {
    loading: false,
    error: error,
    deleteProductSuccess: false
  });
};

const clearDeleteProductState = state => {
  return updateObject(state, {
    loading: false,
    error: null,
    deleteProductSuccess: false
  });
};

// UPDATE PRODUCT
const updateProduct = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
    updateProductSuccess: false
  });
};

const updateProductSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    updateProductSuccess: true
  });
};

const updateProductFail = (state, action) => {
  const { error } = action.payload;

  return updateObject(state, {
    loading: false,
    error: error,
    updateProductSuccess: false
  });
};

const clearUpdateProductState = state => {
  return updateObject(state, {
    loading: false,
    error: null,
    updateProductSuccess: false
  });
};

// POST PRODUCT
const postProduct = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
    postProductSuccess: false
  });
};

const postProductSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    postProductSuccess: true
  });
};
const postProductFail = (state, action) => {
  const { error } = action.payload;

  return updateObject(state, {
    loading: false,
    error: error,
    postProductSuccess: false
  });
};

const clearPostProductState = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    postProductSuccess: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_PRODUCTS_SUCCESS:
      return getAllProductsSuccess(state, action);
    case actionTypes.GET_ALL_PRODUCTS_FAIL:
      return getAllProductsFail(state, action);
    case actionTypes.GET_PRODUCT_SUCCESS:
      return getProductSuccess(state, action);
    case actionTypes.GET_PRODUCT_FAIL:
      return getProductFail(state, action);
    case actionTypes.PRODUCT_FETCHING:
      return fetching(state, action);

    case actionTypes.CLEAR_DELETE_PRODUCT_STATE:
      return clearDeleteProductState(state);
    case actionTypes.DELETE_PRODUCT:
      return deleteProduct(state, action);
    case actionTypes.DELETE_PRODUCT_SUCCESS:
      return deleteProductSuccess(state, action);
    case actionTypes.DELETE_PRODUCT_FAIL:
      return deleteProductFail(state, action);

    case actionTypes.CLEAR_UPDATE_PRODUCT_STATE:
      return clearUpdateProductState(state);
    case actionTypes.UPDATE_PRODUCT:
      return updateProduct(state, action);
    case actionTypes.UPDATE_PRODUCT_SUCCESS:
      return updateProductSuccess(state, action);
    case actionTypes.UPDATE_PRODUCT_FAIL:
      return updateProductFail(state, action);

    case actionTypes.CLEAR_POST_PRODUCT_STATE:
      return clearPostProductState(state, action);
    case actionTypes.POST_PRODUCT:
      return postProduct(state, action);
    case actionTypes.POST_PRODUCT_SUCCESS:
      return postProductSuccess(state, action);
    case actionTypes.POST_PRODUCT_FAIL:
      return postProductFail(state, action);
    default:
      break;
  }

  return state;
};

export default reducer;
