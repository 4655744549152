import React from "react";
import MdAdd from "react-ionicons/lib/MdAdd";
import MdRemove from "react-ionicons/lib/MdRemove";

import classes from "./LabelWithBtn.scss";
import Input from "../../../../Settings/Form/Input/Input";
import { toLower, joinClassNames } from "../../../../../share/js/utility";
import Aux from "../../../../../hoc/Aux/Aux";

const LabelWithBtn = props => {
  let addRemoveBtn = (
    <Aux>
      <span
        className={joinClassNames(classes.Btn, classes.Add)}
        onClick={props.addHandler}
      >
        <MdAdd fontSize={"16px"} />
      </span>

      {props.data.length > 1 ? (
        <span
          className={joinClassNames(classes.Btn, classes.Remove)}
          onClick={props.removeHandler}
        >
          <MdRemove fontSize={"16px"} />
        </span>
      ) : null}
    </Aux>
  );

  if (props.disabled) {
    addRemoveBtn = null;
  }

  return (
    <div className={classes.InputWithChild}>
      <div className={classes.Header}>
        <span>{props.headerTitle}:</span>
        {addRemoveBtn}
      </div>
    </div>
  );
};

export default LabelWithBtn;
