import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';

import classes from './NavigationItem.scss';

class NavigationItem extends Component {

    onNavLinkClickedHandler = (e) => {
        // e.stopPropagation(); // stop click event bubbling up to parent
    };

    render() {
        return (
            <li className={classes.NavigationItem}>
                <NavLink
                    to={this.props.link}
                    activeClassName={classes.active}
                    exact={this.props.exact}
                    onClick={this.onNavLinkClickedHandler}>{this.props.children}</NavLink>
            </li>
        );
    }

}

export default NavigationItem;