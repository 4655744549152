import React from "react";
import IosArrowDown from "react-ionicons/lib/IosArrowDown";

import NavigationItem from "../NavigationItem/NavigationItem";
import classes from "./NavigationItems.scss";
import { textColor } from "../../../share/style/_variables";
import Account from "../Account/Account";

const NavigationItems = props => {
  // let elements = <NavigationItem link="/auth">Sign In</NavigationItem>;

  // if (props.isAuthenticated) {
  //   elements = [
  //     <NavigationItem link="/orders" key="0">
  //       Orders
  //     </NavigationItem>,
  //     <NavigationItem link="/logout" key="1">
  //       Sign Out
  //     </NavigationItem>
  //   ];
  // }

  return (
    <ul className={classes.NavigationItems}>
      <Account onClick={props.accountClickedHandler} />
      {/* {elements} */}
    </ul>
  );
};

export default NavigationItems;
