import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../../../share/js/utility";

const initialState = {
  headerTitle: "Dashboard",
  currentNavigationPath: "/"
};

const setAuthRedirectPath = (state, action) => {
  const { title, path } = action.payload;
  return updateObject(state, {
    headerTitle: title,
    currentNavigationPath: path
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_NAVIGATION_PATH:
      return setAuthRedirectPath(state, action);
    default:
      break;
  }

  return state;
};

export default reducer;
