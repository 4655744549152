import React, { Component } from "react";
import { connect } from "react-redux";

import classes from "./ViewReport.scss";

import {
  acReport,
  acProduct,
  acProductReportStatus,
  acReportStatus,
  acVendor
} from "../../../stores/actions";
import { USER_ID } from "../../../share/js/constants";
import {
  updateObject,
  isObjEmpty,
  isStringEmpty,
  getFormattedDate
} from "../../../share/js/utility";
import { PulseLoader } from "react-spinners";
import Content from "../AddDialog/Content/Content";
import Aux from "../../../hoc/Aux/Aux";
import InfoDialog from "../../../components/InfoDialog/InfoDialog";
import { lang } from "../../../share/js/localization";
import Section from "../Section/Section";

// NOTE:
// because "ViewVendor" component is directly assigned to "Route"
// e.g. <Route path="/vendor/:id" component={ViewVendor} />
// this.props.history is accessible.
// "FormVendor" component is NOT assigned to "Route" so we can't use "history.goBack()"
class ViewReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      edit: false,
      fieldError: false,
      fieldErrorMessage: "",
      form: this.formDefault()
    };
  }
  formDefault = _ => ({
    id: `${Date.now()}${localStorage.getItem(USER_ID)}`,
    entryDate: new Date(),
    technician: "",
    status: {
      id: 0
    },
    contactPersons: [this.contactPersonObj()],
    products: [this.productObj()]
  });

  contactPersonObj = _ => ({
    fname: "",
    lname: "",
    phones: [""],
    emails: [""]
  });

  productObj = _ => ({
    id: 0,
    name: "",
    serialNumber: "",
    category: { id: 0, name: "" },
    vendor: { id: 0, name: "" },
    status: { id: 0 },
    bill: "",
    buyDate: null,
    returnDate: null,
    explanationDescription: "",
    problemDescription: "",
    complementDescription: "",
    changeProduct: this.productServiceObj()
  });

  productServiceObj = _ => ({
    id: 0,
    name: "",
    serialNumber: "",
    category: { id: 0, name: "" },
    vendor: { id: 0, name: "" }
  });

  componentDidMount() {
    // scroll to top of the page
    window.scrollTo(0, 0);

    const reportId = this.props.match.params.id;
    this.props.getReportStatuses();
    this.props.getProductReportStatuses();
    this.props.getAllProducts();
    this.props.getAllVendors();
    this.props.getReportById(reportId);
  }

  addAddressHandler = () => {
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        addresses: [...state.form.addresses].concat("")
      }
    }));
  };

  removeAddressHandler = () => {
    if (this.state.form.addresses.length === 1) return;
    this.setState(state => {
      const copied = [...state.form.addresses];
      copied.pop();

      return {
        ...state,
        form: {
          ...state.form,
          addresses: copied
        }
      };
    });
  };

  addEmailHandler = () => {
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        emails: [...state.form.emails].concat("")
      }
    }));
  };
  removeEmailHandler = () => {
    if (this.state.form.emails.length === 1) return;
    this.setState(state => {
      const copied = [...state.form.emails];
      copied.pop();

      return {
        ...state,
        form: {
          ...state.form,
          emails: copied
        }
      };
    });
  };

  addPhoneHandler = () => {
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        phones: [...state.form.phones].concat("")
      }
    }));
  };
  removePhoneHandler = () => {
    if (this.state.form.phones.length === 1) return;

    this.setState(state => {
      const copied = [...state.form.phones];
      copied.pop();

      return {
        ...state,
        form: {
          ...state.form,
          phones: copied
        }
      };
    });
  };

  addContactPersonHandler = () => {
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        contactPersons: [...state.form.contactPersons].concat(
          this.contactPersonObj()
        )
      }
    }));
  };
  removeContactPersonHandler = () => {
    if (this.state.form.contactPersons.length === 1) return;

    this.setState(state => {
      const copied = [...state.form.contactPersons];
      copied.pop();

      return {
        ...state,
        form: {
          ...state.form,
          contactPersons: copied
        }
      };
    });
  };
  addContactPersonPhoneHandler = index => {
    this.setState(state => {
      const copied = [...state.form.contactPersons];
      copied[index].phones.push("");

      return {
        ...state,
        form: {
          ...state.form,
          contactPersons: copied
        }
      };
    });

    console.log(this.state);
  };
  removeContactPersonPhoneHandler = index => {
    if (this.state.form.contactPersons[index].phones.length === 1) return;

    this.setState(state => {
      const copied = [...state.form.contactPersons];
      copied[index].phones.pop();

      return {
        ...state,
        form: {
          ...state.form,
          contactPersons: copied
        }
      };
    });
  };
  addContactPersonEmailHandler = index => {
    this.setState(state => {
      const copied = [...state.form.contactPersons];
      copied[index].emails.push("");

      return {
        ...state,
        form: {
          ...state.form,
          contactPersons: copied
        }
      };
    });

    console.log(this.state);
  };
  removeContactPersonEmailHandler = index => {
    if (this.state.form.contactPersons[index].emails.length === 1) return;

    this.setState(state => {
      const copied = [...state.form.contactPersons];
      copied[index].emails.pop();

      return {
        ...state,
        form: {
          ...state.form,
          contactPersons: copied
        }
      };
    });
  };

  addProductHandler = () => {
    this.setState(state => ({
      ...state,
      form: {
        ...state.form,
        products: [...state.form.products].concat(this.productObj())
      }
    }));
  };
  removeProductHandler = () => {
    if (this.state.form.products.length === 1) return;

    this.setState(state => {
      const copied = [...state.form.products];
      copied.pop();

      return {
        ...state,
        form: {
          ...state.form,
          products: copied
        }
      };
    });
  };

  inputOnChangeHandler = (evt, key) => {
    console.log(this.state.form);
    const value = evt.target.value;
    const splittedKey = key.split("_");

    if (splittedKey.length > 1) {
      const splittedKeyIndex = splittedKey[splittedKey.length - 1];

      if (
        splittedKey[0] === "addresses" ||
        splittedKey[0] === "emails" ||
        splittedKey[0] === "phones"
      ) {
        this.setState(state => {
          const copied = state.form[splittedKey[0]].concat(); // copy of addresses array
          copied[splittedKeyIndex] = value;

          return updateObject(state, {
            form: {
              ...state.form,
              [splittedKey[0]]: copied
            }
          });
        });
      }

      console.log(splittedKey);

      if (splittedKey[0] === "contactPersons") {
        if (splittedKey.length < 4) {
          // ex. ["contactPersons", "0", "fname"]
          const contactPersons = splittedKey[0];
          const parentKey = splittedKey[1];
          const contactPersonChild = splittedKey[2];

          this.setState(state => {
            const copied = state.form[contactPersons].concat(); // copy of contactPersons array
            copied[parentKey][contactPersonChild] = value;

            return updateObject(state, {
              form: {
                ...state.form,
                contactPersons: copied
              }
            });
          });

          return;
        }

        // ex. ["contactPersons", "0", "phones", "0"]
        const contactPersons = splittedKey[0];
        const parentKey = splittedKey[1];
        const contactPersonChild = splittedKey[2];
        const childKey = splittedKey[3];

        this.setState(state => {
          const copied = state.form[contactPersons].concat(); // copy of contactPersons array
          copied[parentKey][contactPersonChild][childKey] = value;

          return updateObject(state, {
            form: {
              ...state.form,
              contactPersons: copied
            }
          });
        });
      }

      return;
    }

    this.setState(state =>
      updateObject(state, {
        form: {
          ...state.form,
          [key]: value
        }
      })
    );
  };

  /// title handler ///
  closeHandler = () => {
    this.props.visibilityHandler();
  };

  /// Content handler ///
  changeAddCategoryDialogVisibility = () => {
    this.setState(state => ({
      ...state,
      showAddCategoryDialog: !state.showAddCategoryDialog
    }));
  };

  addCategoryHandler = () => {
    this.changeAddCategoryDialogVisibility();
  };

  backToReportHandler = () => {
    console.log(this.props);

    // this.props.history.goBack();

    this.props.history.replace({
      pathname: "/report"
    });
  };

  datePickerOnChangeHandler = date => {
    this.setState(state => {
      return {
        ...state,
        form: {
          ...state.form,
          entryDate: date
        }
      };
    });
  };

  changeStatusHandler = evt => {
    let value = evt.target.value || 0;

    this.setState(state => ({
      form: { ...state.form, status: { id: value } }
    }));
  };

  changeProductStatusHandler = (productIndex, evt) => {
    let value = evt.target.value || 0;

    this.setState(state => {
      /*
      {
        name: "",
        category: { id: 0, value: "" },
        vendor: { id: 0, value: "" },
        status: { id: 0 }
      }
      */

      const products = [...state.form.products];
      products[productIndex] = {
        ...products[productIndex],
        status: {
          id: value
        }
      };

      return {
        form: { ...state.form, products: products }
      };
    });
  };

  productSerialNumberOnChangeHandler = (productIndex, evt) => {
    let value = evt.target.value || "";

    this.setState(state => {
      /*
      {
        name: "",
        category: { id: 0, value: "" },
        vendor: { id: 0, value: "" },
        status: { id: 0 }
      }
      */

      const products = [...state.form.products];
      products[productIndex] = {
        ...products[productIndex],
        serialNumber: value
      };

      return {
        form: { ...state.form, products: products }
      };
    });
  };

  changeProductInputOnChangeHandler = (section, productIndex, evt) => {
    let value = evt.target.value || "";

    this.setState(state => {
      /*
      {
        name: "",
        category: { id: 0, value: "" },
        vendor: { id: 0, value: "" },
        status: { id: 0 }
      }
      */

      const products = [...state.form.products];
      products[productIndex] = {
        ...products[productIndex],
        changeProduct: {
          ...products[productIndex].changeProduct,
          [section]: value
        }
      };

      return {
        form: { ...state.form, products: products }
      };
    });
  };

  changeProductBillHandler = (productIndex, evt) => {
    let value = evt.target.value || "";

    this.setState(state => {
      /*
      {
        name: "",
        category: { id: 0, value: "" },
        vendor: { id: 0, value: "" },
        status: { id: 0 }
      }
      */

      const products = [...state.form.products];
      products[productIndex] = {
        ...products[productIndex],
        bill: value
      };

      return {
        form: { ...state.form, products: products }
      };
    });
  };

  productNameOnChangeHandler = (productIndex, selectedOption) => {
    selectedOption = selectedOption || {
      value: 0,
      label: "",
      category: { id: 0, name: "" }
      // vendor: { id: 0, name: "" }
    };

    const { value: id, label: name, category } = selectedOption;

    // return;
    this.setState(state => {
      /*
      {
        name: "",
        category: { id: 0, value: "" },
        vendor: { id: 0, value: "" },
        status: { id: 0 }
      }
      */

      const products = [...state.form.products];
      products[productIndex] = {
        ...products[productIndex],
        ...selectedOption,
        id,
        name,
        category
      };

      return {
        form: { ...state.form, products: products }
      };
    });

    console.log("new state:::::", this.state);
  };

  changeProductNameOnChangeHandler = (productIndex, selectedOption) => {
    selectedOption = selectedOption || {
      value: 0,
      label: "",
      category: { id: 0, name: "" }
      // vendor: { id: 0, name: "" }
    };

    /* selectedOption:
    {
      value: 1
      label: "new name"
      category: {id: 1, name: "hardware"}
      vendor: {id: 58, name: "Vendor 2"}
    }
    */
    const { value: id, label: name, category } = selectedOption;

    // return;
    this.setState(state => {
      /*
      {
        name: "",
        category: { id: 0, value: "" },
        vendor: { id: 0, value: "" },
        status: { id: 0 }
      }
      */

      const products = [...state.form.products];
      products[productIndex] = {
        ...products[productIndex],
        changeProduct: {
          ...products[productIndex].changeProduct,
          ...selectedOption,
          id,
          name,
          category
          // vendor
        }
      };

      return {
        form: { ...state.form, products: products }
      };
    });
  };

  okInfoHandler = section => {
    if (section === "fieldError") {
      this.setState({
        fieldError: false,
        fieldErrorMessage: ""
      });
      return;
    }

    if (section === "deleteSuccess") {
      this.props.clearDeleteReportState();
      this.props.history.replace({
        pathname: "/report"
      });
      return;
    }

    if (section === "customError") {
      this.props.clearDeleteReportState();
    }

    if (section === "updateSuccess") {
      this.props.clearUpdateReportState();

      const reportId = this.props.match.params.id;
      this.props.getReportById(reportId);

      this.setState({ edit: false });
    }
  };

  editHandler = () => {
    const {
      report,
      products: allproducts, // need to be allproducts, because conflict with form.products
      vendors: allvendors
    } = this.props;

    const fetchedReport = this.fetchReportByIdToFormFormat(
      report,
      allproducts,
      allvendors
    );

    if (fetchedReport) {
      this.setState(state => ({
        edit: true,
        form: { ...fetchedReport }
      }));
    }
  };

  cancelEditHandler = () => {
    this.setState({ edit: false });
  };

  saveChangesHandler = () => {
    // REMEMBER: UPDATE form and POST(INSERT/ADD) form is in the SAME format.
    const { id: reportId } = this.props.report;

    const form = this.state.form;

    console.log("form:::::::::", form);
    const dataToPost = {
      report_id: parseInt(form.id),
      report_status_id: parseInt(form.status.id),
      technician: form.technician,
      description: form.description,
      entryDate: form.entryDate,
      customer: {
        id: this.props.report.customer.id,
        ...form.contactPersons[0]
      },
      products: [
        ...form.products.map(product => {
          let change = {};

          if (product.changeProduct.id != 0) {
            change = {
              product_id: parseInt(product.changeProduct.id),
              serialNumber: product.changeProduct.serialNumber
            };
          }

          return {
            original: {
              product_id: parseInt(product.id),
              serialNumber: product.serialNumber,
              report_product_status_id: product.status.id,
              bill: product.bill,

              buy_date: product.buyDate,
              return_date: product.returnDate,
              explanation_description: product.explanationDescription,
              problem_description: product.problemDescription,
              complement_description: product.complementDescription,

              vendor_id:
                parseInt(product.vendor.id) == 0
                  ? null
                  : parseInt(product.vendor.id)
            },
            change: change
          };
        })
      ]
    };

    console.log("dataToPost::::::", dataToPost);

    // UPDATE REPORT
    this.props.updateReportById(reportId, dataToPost);
  };

  deleteReportHandler = () => {
    const { id: reportId } = this.props.report;
    // alert(reportId);

    // DELETE REPORT
    this.props.deleteReportById(reportId);
  };

  fetchReportByIdToFormFormat = (
    fetchedReportById,
    fetchedProducts,
    fetchedVendors
  ) => {
    // console.log("fetchedReportById:::::", fetchedReportById);
    // console.log("fetchedProducts:::::", fetchedProducts);

    const report = fetchedReportById;
    const allproducts = fetchedProducts;
    const allvendors = fetchedVendors;

    if (!isObjEmpty(report) && (allproducts && allproducts.length > 0)) {
      let reportId,
        statusId,
        technician,
        entryDate,
        customer,
        description,
        products;

      // console.log("fetched::::", this.props.report);
      const fetched = this.props.report;

      const { fname, lname, phones, emails } = fetched.customer;
      const parsedCustomerPhones = phones
        ? phones.map(phone => phone.phone_number)
        : [];
      const parsedCustomerEmails = emails
        ? emails.map(email => email.email)
        : [];

      reportId = fetched.report_id;
      statusId = fetched.report_status.id;
      technician = fetched.technician;
      entryDate = fetched.entry_at;
      customer = [
        {
          fname,
          lname,
          phones: parsedCustomerPhones,
          emails: parsedCustomerEmails
        }
      ];
      description = fetched.description;

      if (allproducts.length > 0) {
        const blacklistProductReportId = [];
        console.log("allproducts::::", allproducts);
        const parsedProducts = this.props.report.products
          .map(product => {
            let changeProduct = null;
            let changeProductData = null;

            // NOT NULL && NOT 0
            if (product.change_id != null && product.change_id != 0) {
              blacklistProductReportId.push(product.change_id);
              changeProduct = report.products.filter(
                f => f.id == product.change_id
              )[0];

              changeProductData = allproducts.filter(f => {
                return f.id == changeProduct.product_id;
              })[0];
            }

            const oriProductData = allproducts.filter(p => {
              return p.id == product.product_id;
            })[0];

            let vendorData = allvendors.filter(v => {
              return v.id == product.vendor_id;
            })[0];

            vendorData = vendorData || { id: 0, value: 0, name: "", label: "" };

            const oriProduct = {
              filterId: product.id,
              value: oriProductData.id, // for <RSelect />
              label: oriProductData.name, // for <RSelect />

              buyDate: product.buy_date,
              returnDate: product.return_date,
              explanationDescription: product.explanation_description,
              problemDescription: product.problem_description,
              complementDescription: product.complement_description,

              bill: product.bill,
              serialNumber: product.serialnumber,
              status: { id: product.report_product_status_id },
              id: oriProductData.id,
              name: oriProductData.name,
              category: {
                id: oriProductData.category.id,
                name: oriProductData.category.name
              },
              vendor: {
                ...vendorData,
                value: vendorData.id,
                label: vendorData.name
              }
            };

            if (changeProduct == null) {
              changeProduct = this.productServiceObj();
            } else {
              changeProduct = {
                value: changeProductData.id, // for <RSelect />
                label: changeProductData.name, // for <RSelect />

                serialNumber: changeProduct.serialnumber,
                id: changeProductData.id,
                name: changeProductData.name,
                category: {
                  id: changeProductData.category.id,
                  name: changeProductData.category.name
                },
                vendor: {
                  // id: changeProductData.vendor.id,
                  // name: changeProductData.vendor.name
                }
              };
            }

            // console.log("oriProduct::::", oriProduct);

            return {
              ...JSON.parse(JSON.stringify(oriProduct)),
              changeProduct: JSON.parse(JSON.stringify(changeProduct))
            };
          })
          .filter(
            product => !blacklistProductReportId.includes(product.filterId)
          );

        // console.log(
        //   "parsedProducts::::",
        //   parsedProducts,
        //   blacklistProductReportId
        // );

        products = [...parsedProducts];
      }

      return {
        id: reportId,
        entryDate: entryDate,
        technician: technician,
        description: description,
        status: {
          id: statusId
        },
        contactPersons: customer,
        products: products
      };
    }

    return null;
  };

  // tgl pembelian, pengembalian, deskripsi produk
  datePickerProductOnChangeHandler = (section, productIndex, date) => {
    let value = date || null;

    this.setState(state => {
      const products = [...state.form.products];
      products[productIndex] = {
        ...products[productIndex],
        [section]: value
      };

      return {
        form: { ...state.form, products: products }
      };
    });
  };

  changeProductDescriptionHandler = (section, productIndex, evt) => {
    let value = evt.target.value || "";

    this.setState(state => {
      const products = [...state.form.products];
      products[productIndex] = {
        ...products[productIndex],
        [section]: value
      };

      return {
        form: { ...state.form, products: products }
      };
    });
  };

  productVendorOnChangeHandler = (productIndex, selectedOption) => {
    selectedOption = selectedOption || {
      value: 0,
      label: "",
      name: ""
    };

    const { value: id, name } = selectedOption;

    // return;
    this.setState(state => {
      const products = [...state.form.products];

      products[productIndex] = {
        ...products[productIndex],
        vendor: { ...selectedOption, id, name }
      };

      console.log(
        "this.state::::::",
        selectedOption,
        products[productIndex].vendor
      );

      return {
        form: { ...state.form, products: products }
      };
    });
  };

  render() {
    const {
      vendorLoading,
      reportLoading,
      productsLoading,
      reportStatusLoading,
      productReportStatusLoading,
      report,
      reportStatuses,
      products: allproducts,
      productReportStatuses
    } = this.props;
    if (
      vendorLoading ||
      reportLoading ||
      productReportStatusLoading ||
      reportStatusLoading ||
      productReportStatusLoading
    ) {
      return (
        <div className={classes.AddReport}>
          <Section>
            <span style={{ textAlign: "center" }}>
              <PulseLoader sizeUnit={"px"} size={12} color={"#123abc"} />
            </span>
          </Section>
        </div>
      );
    }

    if (isObjEmpty(report)) {
      return (
        <div className={classes.AddReport}>
          <Section>Report not found.</Section>
        </div>
      );
    }

    /* this.props.report

    {
      creator: {id: 3, username: "admin123"}
      customer: {id: 55, fname: "", lname: "", phones: Array(1), emails: Array(1)}
      description: null
      entry_at: "2019-05-11T06:05:31.000Z"
      id: "1"
      products: []
      report_id: "15575547102293"
      report_status: {id: 1, title: "buka"}
      technician: "wqeqweqwe"
    }
    
    */
    let inputValue;

    if (
      !isObjEmpty(report) &&
      !this.state.edit &&
      (this.props.products && this.props.products.length > 0)
    ) {
      let {
        id: reportId,
        status: { id: statusId },
        technician,
        entryDate,
        contactPersons: customer,
        description,
        products
      } = this.fetchReportByIdToFormFormat(
        this.props.report,
        this.props.products,
        this.props.vendors
      );

      inputValue = {
        id: reportId,
        statusId: statusId,
        technician: technician,
        entryDate: new Date(entryDate),
        contactPersons: customer,
        description: description || "",
        products: products,

        // for select-options
        selectProducts: this.props.products,
        reportStatuses: this.props.reportStatuses,
        productReportStatuses: this.props.productReportStatuses,

        selectVendors: this.props.vendors
      };
    } else {
      let {
        id: reportId,
        status: { id: statusId },
        technician,
        entryDate,
        contactPersons: customer,
        description,
        products
      } = this.state.form;

      inputValue = {
        id: reportId,
        statusId: statusId,
        technician: technician,
        entryDate: new Date(entryDate),
        contactPersons: customer,
        description: description || "",
        products: products,

        // for select-options
        selectProducts: this.props.products,
        reportStatuses: this.props.reportStatuses,
        productReportStatuses: this.props.productReportStatuses,

        selectVendors: this.props.vendors
      };
    }

    let viewReportLayout = (
      <div className={classes.AddDialog}>
        <Content
          mode={"view"}
          edit={this.state.edit}
          handler={{
            inputOnChangeHandler: this.inputOnChangeHandler,
            vendorOnSearchHandler: this.vendorOnSearchHandler,
            categoryOnChangeHandler: this.categoryOnChangeHandler,
            addEmailHandler: this.addEmailHandler,
            removeEmailHandler: this.removeEmailHandler,
            addPhoneHandler: this.addPhoneHandler,
            removePhoneHandler: this.removePhoneHandler,
            addContactPersonHandler: this.addContactPersonHandler,
            removeContactPersonHandler: this.removeContactPersonHandler,
            addContactPersonPhoneHandler: this.addContactPersonPhoneHandler,
            removeContactPersonPhoneHandler: this
              .removeContactPersonPhoneHandler,
            addContactPersonEmailHandler: this.addContactPersonEmailHandler,
            removeContactPersonEmailHandler: this
              .removeContactPersonEmailHandler,
            backToReportHandler: this.backToReportHandler,
            datePickerOnChangeHandler: this.datePickerOnChangeHandler,
            addProductHandler: this.addProductHandler,
            removeProductHandler: this.removeProductHandler,
            changeStatusHandler: this.changeStatusHandler,
            changeProductStatusHandler: this.changeProductStatusHandler,
            productNameOnChangeHandler: this.productNameOnChangeHandler,
            productSerialNumberOnChangeHandler: this
              .productSerialNumberOnChangeHandler,
            changeProductBillHandler: this.changeProductBillHandler,

            changeProductDescriptionHandler: this
              .changeProductDescriptionHandler,
            datePickerProductOnChangeHandler: this
              .datePickerProductOnChangeHandler,

            // changeProduct
            changeProductInputOnChangeHandler: this
              .changeProductInputOnChangeHandler,
            changeProductNameOnChangeHandler: this
              .changeProductNameOnChangeHandler,
            editHandler: this.editHandler,
            cancelEditHandler: this.cancelEditHandler,
            deleteReportHandler: this.deleteReportHandler,
            saveChangesHandler: this.saveChangesHandler,

            productVendorOnChangeHandler: this.productVendorOnChangeHandler
          }}
          inputValue={inputValue}
        />
      </div>
    );

    const { fieldError, fieldErrorMessage } = this.state;

    let deleteCustomError =
      !this.props.deleteReportSuccess && !isStringEmpty(this.props.error);
    let deleteSuccess =
      this.props.deleteReportSuccess && isStringEmpty(this.props.error);
    let updateSuccess =
      this.props.updateReportSuccess && isStringEmpty(this.props.error);

    return (
      <div className={classes.AddReport}>
        <Aux>
          <InfoDialog
            show={fieldError || false}
            message={fieldErrorMessage}
            okHandler={() => this.okInfoHandler("fieldError")}
          />
          <InfoDialog
            show={deleteCustomError || false}
            message={this.props.error}
            okHandler={() => this.okInfoHandler("customError")}
          />
          <InfoDialog
            show={deleteSuccess || false}
            message={lang.generalSuccess}
            okHandler={() => this.okInfoHandler("deleteSuccess")}
          />
          <InfoDialog
            show={updateSuccess || false}
            message={lang.generalSuccess}
            okHandler={() => this.okInfoHandler("updateSuccess")}
          />
        </Aux>
        <Section>{viewReportLayout}</Section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    productReportStatuses:
      state.productReportStatusReducer.productReportStatuses,
    products: state.productReducer.products,
    reportStatuses: state.reportStatusReducer.reportStatuses,
    report: state.reportReducer.report,
    vendors: state.vendorReducer.vendors,

    vendorLoading: state.vendorReducer.loading,
    reportLoading: state.reportReducer.loading,
    productsLoading: state.productReducer.loading,
    reportStatusLoading: state.reportStatusReducer.loading,
    productReportStatusLoading: state.productReportStatusReducer.loading,

    error: state.reportReducer.error,
    deleteReportSuccess: state.reportReducer.deleteReportSuccess,
    updateReportSuccess: state.reportReducer.updateReportSuccess
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getReportStatuses: _ => dispatch(acReportStatus.getReportStatuses()),
    getProductReportStatuses: _ =>
      dispatch(acProductReportStatus.getProductReportStatuses()),
    getAllProducts: _ => dispatch(acProduct.getAllProducts()),
    getAllVendors: _ => dispatch(acVendor.getAllVendors()),

    updateReportById: (id, data) =>
      dispatch(acReport.updateReportById(id, data)),
    getReportById: id => dispatch(acReport.getReportById(id)),

    clearUpdateReportState: _ => dispatch(acReport.clearUpdateReportState()),
    clearDeleteReportState: _ => dispatch(acReport.clearDeleteReportState()),

    deleteReportById: id => dispatch(acReport.deleteReportById(id)),
    updateReportById: (id, data) =>
      dispatch(acReport.updateReportById(id, data))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewReport);
