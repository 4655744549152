import React from "react";
import ReactToPrint from "react-to-print";
import IosPrint from "react-ionicons/lib/IosPrint";

import TextBtn from "../TextBtn/TextBtn";
import { toCaps } from "../../share/js/utility";
import { backBtnColor } from "../../share/style/_variables";

import PrintTemplate from "react-print";
import ReactDOM from "react-dom";
import ReportFormPrint from "../../containers/Report/Dialog/ReportFormPrint/ReportFormPrint";

class MyTemplate extends React.Component {
  render() {
    return (
      <PrintTemplate>
        <div>{this.props.children}</div>
      </PrintTemplate>
    );
  }
}

const CustomReactToPrint = props => {
  const clicked = () => {
    // mount to dom
    ReactDOM.render(
      <MyTemplate>{props.componentToPrint}</MyTemplate>,
      document.getElementById("print-mount")
    );

    // custom listener and print it
    if (props.onClickHandler) props.onClickHandler();

    setTimeout(() => window.print(), 100); // wait 100ms for update print layout element
    // window.print();
  };

  return (
    <TextBtn
      title={`${toCaps(props.title || "Print")}`}
      fit
      style={{
        btn: {
          display: "flex",
          flexDirection: "row-reverse",
          background: backBtnColor
        },
        icon: { fill: "#fff" }
      }}
      Symbol={IosPrint}
      onClick={clicked}
    />
  );
};

export default CustomReactToPrint;
