// actions => do JOBS, reducers => change STATE base on action

import * as actionTypes from "./types";
import axios from "../../share/js/axios";
import { TOKEN } from "../../share/js/constants";

const fetching = _ => {
  return {
    type: actionTypes.VENDOR_FETCHING
  };
};

const postVendorSuccess = _ => {
  return {
    type: actionTypes.POST_VENDOR_SUCCESS
  };
};

export const clearPostVendorState = _ => {
  return {
    type: actionTypes.CLEAR_POST_VENDOR_STATE
  };
};

const postVendorFail = data => {
  return {
    type: actionTypes.POST_VENDOR_FAIL,
    payload: { ...data }
  };
};
const postVendorInit = _ => {
  return {
    type: actionTypes.POST_VENDOR
  };
};

const getVendorSuccess = data => {
  return {
    type: actionTypes.GET_VENDOR_SUCCESS,
    payload: { ...data }
  };
};

const getVendorFail = data => {
  return {
    type: actionTypes.GET_VENDOR_FAIL,
    payload: { ...data }
  };
};

const getAllVendorsSuccess = data => {
  return {
    type: actionTypes.GET_ALL_VENDORS_SUCCESS,
    payload: { ...data }
  };
};

const getAllVendorsFail = data => {
  return {
    type: actionTypes.GET_ALL_VENDORS_FAIL,
    payload: { ...data }
  };
};

export const getAllVendors = () => {
  return dispatch => {
    dispatch(fetching());

    axios
      .get("/vendors", {
        headers: {
          "x-auth": localStorage.getItem(TOKEN)
        }
      })
      .then(res => {
        let { data: vendors } = res.data;
        console.log("vendors", vendors);

        dispatch(getAllVendorsSuccess({ vendors }));
      })
      .catch(err => {
        // console.log("err /login:", err);
        dispatch(getAllVendorsFail({ error: err.response.data }));
      });
  };
};

export const getVendorById = id => {
  return dispatch => {
    dispatch(fetching());

    axios
      .get("/vendors/" + id, {
        headers: {
          "x-auth": localStorage.getItem(TOKEN)
        }
      })
      .then(res => {
        let { data: vendor } = res.data;
        console.log("vendor::::::", vendor);

        if (res.data.data.sqlMessage) {
          // skip to catch, will not execute below codes.
          throw res.data.data.sqlMessage;
        }

        dispatch(getVendorSuccess({ vendor }));
      })
      .catch(err => {
        let error = null;
        console.log("throwed", err);

        if (!err.response) {
          error = err;
        } else {
          error = err.response.data;
        }

        dispatch(getVendorFail({ error }));
      });
  };
};

// DELETE VENDOR
const deleteVendorInit = _ => {
  return {
    type: actionTypes.DELETE_VENDOR
  };
};

const deleteVendorSuccess = _ => {
  return {
    type: actionTypes.DELETE_VENDOR_SUCCESS
  };
};

const deleteVendorFail = data => {
  return {
    type: actionTypes.DELETE_VENDOR_FAIL,
    payload: { ...data }
  };
};

export const clearDeleteVendorState = _ => {
  return {
    type: actionTypes.CLEAR_DELETE_VENDOR_STATE
  };
};

export const deleteVendorById = id => {
  return dispatch => {
    dispatch(deleteVendorInit());

    axios
      .get("/vendors/delete/" + id, {
        headers: {
          "x-auth": localStorage.getItem(TOKEN)
        }
      })
      .then(res => {
        if (res.data.data.sqlMessage) {
          // skip to catch, will not execute below codes.
          throw res.data.data.sqlMessage;
        }

        dispatch(deleteVendorSuccess());
      })
      .catch(err => {
        let error = null;
        // console.log("throwed", err);

        if (!err.response) {
          error = err;
        } else {
          error = err.response.data;
        }

        dispatch(deleteVendorFail({ error }));
      });
  };
};
// UPDATE VENDOR
const updateVendorInit = _ => {
  return {
    type: actionTypes.UPDATE_VENDOR
  };
};

const updateVendorSuccess = _ => {
  return {
    type: actionTypes.UPDATE_VENDOR_SUCCESS
  };
};

const updateVendorFail = data => {
  return {
    type: actionTypes.UPDATE_VENDOR_FAIL,
    payload: { ...data }
  };
};

export const clearUpdateVendorState = _ => {
  return {
    type: actionTypes.CLEAR_UPDATE_VENDOR_STATE
  };
};

export const updateVendorById = (id, data) => {
  return dispatch => {
    dispatch(updateVendorInit());

    axios
      .post("/vendors/" + id, data, {
        headers: {
          "x-auth": localStorage.getItem(TOKEN)
        }
      })
      .then(res => {
        if (res.data.data.sqlMessage) {
          // skip to catch, will not execute below codes.
          throw res.data.data.sqlMessage;
        }

        // this will also clear loading state, so no need to dispatch(updateVendorSuccess())
        dispatch(getVendorById(id));
        // dispatch(updateVendorSuccess());
      })
      .catch(err => {
        let error = null;
        // console.log("throwed", err);

        if (!err.response) {
          error = err;
        } else {
          error = err.response.data;
        }

        dispatch(updateVendorFail({ error }));
      });
  };
};

export const postVendor = data => {
  return dispatch => {
    dispatch(postVendorInit());

    axios
      .post("/vendors", data, {
        headers: {
          "x-auth": localStorage.getItem(TOKEN)
        }
      })
      .then(res => {
        // let { data: vendor } = res.data;
        console.log("post vendor::::::", res);

        if (res.data.data.sqlMessage) {
          // skip to catch, will not execute below codes.
          throw res.data.data.sqlMessage;
        }

        dispatch(postVendorSuccess());
      })
      .catch(err => {
        let error = null;
        console.log("throwed", err);

        if (!err.response) {
          error = err;
        } else {
          error = err.response.data;
        }

        dispatch(postVendorFail({ error }));
      });
  };
};
