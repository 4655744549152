import React from "react";
// import IosArrowDown from 'react-ionicons/lib/IosArrowDown';
import IosStatsOutline from "react-ionicons/lib/IosStatsOutline";
import IosBasketOutline from "react-ionicons/lib/IosBasketOutline";
import IosPeopleOutline from "react-ionicons/lib/IosPeopleOutline";
import IosPaperOutline from "react-ionicons/lib/IosPaperOutline";
import IosSettingsOutline from "react-ionicons/lib/IosSettingsOutline";

import Link from "../Link/Link";
import classes from "./MobileLinks.scss";
import { textColor } from "../../../../share/style/_variables";
import Aux from "../../../../hoc/Aux/Aux";
import { toCaps } from "../../../../share/js/utility";
import { lang } from "../../../../share/js/localization";
// import Account from '../Account/Account';

const MobileLinks = props => {
  let elements = <Link link="/auth">Sign In</Link>;

  if (!props.isAuthenticated) {
    elements = [
      <Link link={"/"} key="0" Symbol={IosStatsOutline} isActive exact>
        {toCaps(lang.dashboard)}
      </Link>,
      <Link link="/product" key="1" Symbol={IosBasketOutline}>
        {toCaps(lang.product)}
      </Link>,
      <Link link="/vendor" key="2" Symbol={IosPeopleOutline}>
        {toCaps(lang.vendor)}
      </Link>,
      <Link link="/report" key="3" Symbol={IosPaperOutline}>
        {toCaps(lang.report)}
      </Link>,
      <Link link="/settings" key="4" Symbol={IosSettingsOutline}>
        {toCaps(lang.settings)}
      </Link>
    ];
  }

  return (
    <Aux>
      {/* mobile */}
      <ul className={[classes.NavigationItems].join(" ")}>{elements}</ul>
    </Aux>
  );
};

export default MobileLinks;
