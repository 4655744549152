// actions => do JOBS, reducers => change STATE base on action

import * as actionTypes from "./types";
import axios from "../../share/js/axios";
import { TOKEN } from "../../share/js/constants";

const fetching = _ => {
  return {
    type: actionTypes.CATEGORY_FETCHING
  };
};

const postCategorySuccess = _ => {
  return {
    type: actionTypes.POST_CATEGORY_SUCCESS
  };
};

export const clearPostCategoryState = _ => {
  return {
    type: actionTypes.CLEAR_POST_CATEGORY_STATE
  };
};

const postCategoryFail = data => {
  return {
    type: actionTypes.POST_CATEGORY_FAIL,
    payload: { ...data }
  };
};
const postCategoryInit = _ => {
  return {
    type: actionTypes.POST_CATEGORY
  };
};

const getCategorySuccess = data => {
  return {
    type: actionTypes.GET_CATEGORY_SUCCESS,
    payload: { ...data }
  };
};

const getCategoryFail = data => {
  return {
    type: actionTypes.GET_CATEGORY_FAIL,
    payload: { ...data }
  };
};

const getAllCategoriesSuccess = data => {
  return {
    type: actionTypes.GET_ALL_CATEGORIES_SUCCESS,
    payload: { ...data }
  };
};

const getAllCategoriesFail = data => {
  return {
    type: actionTypes.GET_ALL_CATEGORIES_FAIL,
    payload: { ...data }
  };
};

export const getAllCategories = () => {
  return dispatch => {
    dispatch(fetching());

    axios
      .get("/categories", {
        headers: {
          "x-auth": localStorage.getItem(TOKEN)
        }
      })
      .then(res => {
        let { data: categories } = res.data;
        console.log("categories", categories);

        dispatch(getAllCategoriesSuccess({ categories }));
      })
      .catch(err => {
        // console.log("err /login:", err);
        dispatch(getAllCategoriesFail({ error: err.response.data }));
      });
  };
};

export const getCategoryById = id => {
  return dispatch => {
    dispatch(fetching());

    axios
      .get("/categories/" + id, {
        headers: {
          "x-auth": localStorage.getItem(TOKEN)
        }
      })
      .then(res => {
        let { data: category } = res.data;
        console.log("category::::::", category);

        if (res.data.data.sqlMessage) {
          // skip to catch, will not execute below codes.
          throw res.data.data.sqlMessage;
        }

        dispatch(getCategorySuccess({ category }));
      })
      .catch(err => {
        let error = null;
        console.log("throwed", err);

        if (!err.response) {
          error = err;
        } else {
          error = err.response.data;
        }

        dispatch(getCategoryFail({ error }));
      });
  };
};

// DELETE CATEGORY
const deleteCategoryInit = _ => {
  return {
    type: actionTypes.DELETE_CATEGORY
  };
};

const deleteCategorySuccess = _ => {
  return {
    type: actionTypes.DELETE_CATEGORY_SUCCESS
  };
};

const deleteCategoryFail = data => {
  return {
    type: actionTypes.DELETE_CATEGORY_FAIL,
    payload: { ...data }
  };
};

export const clearDeleteCategoryState = _ => {
  return {
    type: actionTypes.CLEAR_DELETE_CATEGORY_STATE
  };
};

export const deleteCategoryById = id => {
  return dispatch => {
    dispatch(deleteCategoryInit());

    axios
      .get("/categories/delete/" + id, {
        headers: {
          "x-auth": localStorage.getItem(TOKEN)
        }
      })
      .then(res => {
        if (res.data.data.sqlMessage) {
          // skip to catch, will not execute below codes.
          throw res.data.data.sqlMessage;
        }

        // dispatch(deleteCategorySuccess());
        dispatch(getAllCategories());
      })
      .catch(err => {
        let error = null;
        // console.log("throwed", err);

        if (!err.response) {
          error = err;
        } else {
          error = err.response.data;
        }

        dispatch(deleteCategoryFail({ error }));
      });
  };
};
// UPDATE CATEGORY
const updateCategoryInit = _ => {
  return {
    type: actionTypes.UPDATE_CATEGORY
  };
};

const updateCategorySuccess = _ => {
  return {
    type: actionTypes.UPDATE_CATEGORY_SUCCESS
  };
};

const updateCategoryFail = data => {
  return {
    type: actionTypes.UPDATE_CATEGORY_FAIL,
    payload: { ...data }
  };
};

export const clearUpdateCategoryState = _ => {
  return {
    type: actionTypes.CLEAR_UPDATE_CATEGORY_STATE
  };
};

// export const updateCategoryById = (id, data) => {
//   return dispatch => {
//     dispatch(updateCategoryInit());

//     axios
//       .post("/categories/" + id, data, {
//         headers: {
//           "x-auth": localStorage.getItem(TOKEN)
//         }
//       })
//       .then(res => {
//         if (res.data.data.sqlMessage) {
//           // skip to catch, will not execute below codes.
//           throw res.data.data.sqlMessage;
//         }

//         // this will also clear loading state, so no need to dispatch(updateCategorySuccess())
//         dispatch(getCategoryById(id));
//         // dispatch(updateCategorySuccess());
//       })
//       .catch(err => {
//         let error = null;
//         // console.log("throwed", err);

//         if (!err.response) {
//           error = err;
//         } else {
//           error = err.response.data;
//         }

//         dispatch(updateCategoryFail({ error }));
//       });
//   };
// };

export const postCategory = data => {
  return dispatch => {
    dispatch(postCategoryInit());

    axios
      .post("/categories", data, {
        headers: {
          "x-auth": localStorage.getItem(TOKEN)
        }
      })
      .then(res => {
        // let { data: category } = res.data;
        console.log("post category::::::", res);

        if (res.data.data.sqlMessage) {
          // skip to catch, will not execute below codes.
          throw res.data.data.sqlMessage;
        }

        dispatch(postCategorySuccess());
        // dispatch(getAllCategories());
      })
      .catch(err => {
        let error = null;
        console.log("throwed", err);

        if (!err.response) {
          error = err;
        } else {
          error = err.response.data;
        }

        dispatch(postCategoryFail({ error }));
      });
  };
};
