import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { merge, tada, flip, slideInDown, slideInUp } from "react-animations";
// const tadaFlip = merge(tada, flip);

import classes from "./MenuList.scss";
import { lang } from "../../../../share/js/localization";
import { toCaps } from "../../../../share/js/utility";
import NavigationItem from "../../NavigationItem/NavigationItem";

// const SlideDiv = isSlideDown => {
//     if (isSlideDown == null) {
//         return styled.div`
//         top: -20%;
//         `;
//     }

//     let bounceAnimation = keyframes`${slideInDown}`;

//     if (!isSlideDown) {
//         bounceAnimation = keyframes`${slideInDown} 0% {
//             top: -10px; // same as box-shadow's blur
//         }`;
//     }

//     return styled.div`
//     animation: 0.3s ${bounceAnimation};
//     animation-direction: ${isSlideDown ? 'normal' : 'reverse'};
//     animation-fill-mode: forwards;
//     animation-timing-function: ease-in-out;
// `};

class MenuList extends Component {
  onNavLinkClickedHandler = e => {
    // e.stopPropagation(); // stop click event bubbling up to parent
  };

  render() {
    // const Slide = SlideDiv(this.state.show);

    return (
      <div>
        <div className={classes.Backdrop} />

        <div
          className={[classes.AccountMenu].join(" ")}
          style={{
            transform: this.props.show ? "translateY(0)" : "translateY(-110%)"
          }}
        >
          <ul>
            <li>
              <NavLink
                to={"/settings"}
                exact={this.props.exact}
                onClick={this.props.onClickHandler}
              >
                {toCaps(lang.accountSettings)}
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/logout"}
                exact={this.props.exact}
                onClick={this.props.onClickHandler}
              >
                {toCaps(lang.signOut)}
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default MenuList;
