import React, { Component } from "react";
import MdCreate from "react-ionicons/lib/MdCreate";
import { Tooltip } from "@material-ui/core";

import classes from "./Section.scss";

class Section extends Component {
  render() {
    return (
      <div className={classes.Section}>
        <div className={classes.Section__Grouper}>
          {/* wrapper for .Section__Title for inline-block(auto width) to work */}
          <div>
            <div className={classes.Section__Title}>
              <h4>
                <span>{this.props.title}</span>
              </h4>
            </div>
          </div>
          <div className={classes.Section__Content}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}

export default Section;
