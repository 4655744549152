import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { lang } from "../../share/js/localization";

class InfoDialog extends React.Component {
  //   state = {
  //     open: true
  //   };

  okHandler = () => {
    // this.setState({ open: true });
    this.props.okHandler();
  };

  render() {
    let contentStyle = {};

    if (!this.props.title) {
      contentStyle = { marginBottom: 0, marginTop: "8px" };
    }

    return (
      <div>
        {/* <Button
          variant="outlined"
          color="primary"
          onClick={this.handleClickOpen}
        >
          Open alert dialog
        </Button> */}
        <Dialog
          open={this.props.show ? true : false}
          onClose={this.props.closeHandler}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          transitionDuration={{ exit: 0.1 }}
        >
          {!this.props.title ? null : (
            <DialogTitle id="alert-dialog-title">
              {this.props.title}
            </DialogTitle>
          )}
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={contentStyle}
            >
              {this.props.message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.okHandler} color="primary" autoFocus>
              {lang.ok}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default InfoDialog;
