// actions => do JOBS, reducers => change STATE base on action

import * as actionTypes from "./types";
import axios from "../../share/js/axios";
import { TOKEN } from "../../share/js/constants";

const fetching = _ => {
  return {
    type: actionTypes.PRODUCT_FETCHING
  };
};

const getProductSuccess = data => {
  return {
    type: actionTypes.GET_PRODUCT_SUCCESS,
    payload: { ...data }
  };
};

const getProductFail = data => {
  return {
    type: actionTypes.GET_PRODUCT_FAIL,
    payload: { ...data }
  };
};

const getAllProductsSuccess = data => {
  return {
    type: actionTypes.GET_ALL_PRODUCTS_SUCCESS,
    payload: { ...data }
  };
};

const getAllProductsFail = data => {
  return {
    type: actionTypes.GET_ALL_PRODUCTS_FAIL,
    payload: { ...data }
  };
};

export const getAllProducts = () => {
  return dispatch => {
    dispatch(fetching());

    axios
      .get("/products", {
        headers: {
          "x-auth": localStorage.getItem(TOKEN)
        }
      })
      .then(res => {
        let { data: products } = res.data;
        console.log("products", products);

        dispatch(getAllProductsSuccess({ products }));
      })
      .catch(err => {
        // console.log("err /login:", err);
        dispatch(getAllProductsFail({ error: err.response.data }));
      });
  };
};

export const getProductById = id => {
  return dispatch => {
    dispatch(fetching());

    axios
      .get("/products/" + id, {
        headers: {
          "x-auth": localStorage.getItem(TOKEN)
        }
      })
      .then(res => {
        let { data: product } = res.data;
        console.log("product::::::", product);

        if (res.data.data.sqlMessage) {
          // skip to catch, will not execute below codes.
          throw res.data.data.sqlMessage;
        }

        dispatch(getProductSuccess({ product }));
      })
      .catch(err => {
        let error = null;
        console.log("throwed", err);

        if (!err.response) {
          error = err;
        } else {
          error = err.response.data;
        }

        dispatch(getProductFail({ error }));
      });
  };
};

// DELETE PRODUCT
const deleteProductInit = _ => {
  return {
    type: actionTypes.DELETE_PRODUCT
  };
};

const deleteProductSuccess = _ => {
  return {
    type: actionTypes.DELETE_PRODUCT_SUCCESS
  };
};

const deleteProductFail = data => {
  return {
    type: actionTypes.DELETE_PRODUCT_FAIL,
    payload: { ...data }
  };
};

export const clearDeleteProductState = _ => {
  return {
    type: actionTypes.CLEAR_DELETE_PRODUCT_STATE
  };
};

export const deleteProductById = id => {
  return dispatch => {
    dispatch(deleteProductInit());

    axios
      .get("/products/delete/" + id, {
        headers: {
          "x-auth": localStorage.getItem(TOKEN)
        }
      })
      .then(res => {
        if (res.data.data.sqlMessage) {
          // skip to catch, will not execute below codes.
          throw res.data.data.sqlMessage;
        }

        dispatch(deleteProductSuccess());
      })
      .catch(err => {
        let error = null;
        // console.log("throwed", err);

        if (!err.response) {
          error = err;
        } else {
          error = err.response.data;
        }

        dispatch(deleteProductFail({ error }));
      });
  };
};
// UPDATE PRODUCT
const updateProductInit = _ => {
  return {
    type: actionTypes.UPDATE_PRODUCT
  };
};

const updateProductSuccess = _ => {
  return {
    type: actionTypes.UPDATE_PRODUCT_SUCCESS
  };
};

const updateProductFail = data => {
  return {
    type: actionTypes.UPDATE_PRODUCT_FAIL,
    payload: { ...data }
  };
};

export const clearUpdateProductState = _ => {
  return {
    type: actionTypes.CLEAR_UPDATE_PRODUCT_STATE
  };
};

export const updateProductById = (id, data) => {
  return dispatch => {
    dispatch(updateProductInit());

    const { name, vendorId, categoryId, description } = data;
    const dataToPost = {
      name,
      description,
      vendor_id: vendorId,
      category_id: categoryId
    };

    axios
      .post("/products/" + id, dataToPost, {
        headers: {
          "x-auth": localStorage.getItem(TOKEN)
        }
      })
      .then(res => {
        if (res.data.data.sqlMessage) {
          // skip to catch, will not execute below codes.
          throw res.data.data.sqlMessage;
        }

        // this will also clear loading state, so no need to dispatch(updateProductSuccess())
        // dispatch(getProductById(id)); // <-- if we use new page for update

        dispatch(updateProductSuccess()); // <-- because we use Dialog
      })
      .catch(err => {
        let error = null;
        // console.log("throwed", err);

        if (!err.response) {
          error = err;
        } else {
          error = err.response.data;
        }

        dispatch(updateProductFail({ error }));
      });
  };
};

// POST PRODUCT

const postProductInit = _ => {
  return {
    type: actionTypes.POST_PRODUCT
  };
};

const postProductSuccess = _ => {
  return {
    type: actionTypes.POST_PRODUCT_SUCCESS
  };
};

const postProductFail = data => {
  return {
    type: actionTypes.POST_PRODUCT_FAIL,
    payload: { ...data }
  };
};

export const clearPostProductState = _ => {
  return {
    type: actionTypes.CLEAR_POST_PRODUCT_STATE
  };
};
export const postProduct = data => {
  return dispatch => {
    dispatch(postProductInit());

    const { name, vendorId, categoryId, description } = data;
    const dataToPost = {
      name,
      description,
      vendor_id: vendorId,
      category_id: categoryId
    };

    console.log("dataToPost::::", dataToPost);

    axios
      .post("/products", dataToPost, {
        headers: {
          "x-auth": localStorage.getItem(TOKEN)
        }
      })
      .then(res => {
        // let { data: product } = res.data;
        console.log("post product::::::", res);

        if (res.data.data.sqlMessage) {
          // skip to catch, will not execute below codes.
          throw res.data.data.sqlMessage;
        }

        dispatch(postProductSuccess());
      })
      .catch(err => {
        let error = null;
        console.log("throwed", err);

        if (!err.response) {
          error = err;
        } else {
          error = err.response.data;
        }

        dispatch(postProductFail({ error }));
      });
  };
};
