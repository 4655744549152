import React, { Component } from "react";
import MdCreate from "react-ionicons/lib/MdCreate";

import classes from "./Input.scss";
import { joinClassNames, toCaps } from "../../../../share/js/utility";

const Input = props => {
  const smallBtns = props.smallBtns;
  const bigBtn = props.bigBtn;

  let inputComponent = (
    <div
      className={joinClassNames(
        classes.Input,
        props.noMarginLR ? classes.InputNoMarginLeftRight : "",
        props.medium ? classes["Input--medium"] : ""
      )}
      style={props.style}
    >
      <label>{toCaps(props.label)}</label>
      <input
        className={joinClassNames(
          classes.Input__Field,
          props.medium ? classes["Input__Field--medium"] : ""
        )}
        // defaultValue={props.defaultValue}
        value={props.defaultValue}
        disabled={props.disabled}
        readOnly={props.disabled}
        onChange={props.disabled ? null : props.onChange}
        {...props.inputAdditionalProps}
        {...(() => {
          // alert(props.autoComplete);
          if (props.autoComplete != null)
            return { autoComplete: "new-password" };

          return {};
        })()}
      />
    </div>
  );

  if (props.textarea) {
    inputComponent = (
      <div
        className={joinClassNames(
          classes.Input,
          props.noMarginLR ? classes.InputNoMarginLeftRight : "",
          props.medium ? classes["Input--medium"] : ""
        )}
        style={props.style}
      >
        <label>{toCaps(props.label)}</label>
        <textarea
          rows={props.rows}
          className={joinClassNames(
            classes.Input__TextArea,
            props.medium ? classes["Input__Field--medium"] : ""
          )}
          // defaultValue={props.defaultValue}
          value={props.defaultValue}
          disabled={props.disabled}
          readOnly={props.disabled}
          onChange={props.disabled ? null : props.onChange}
        />
      </div>
    );
  }

  if (props.children) {
    inputComponent = (
      <div
        className={joinClassNames(
          classes.Input,
          props.noMarginLR ? classes.InputNoMarginLeftRight : "",
          props.medium ? classes["Input--medium"] : ""
        )}
        style={props.style}
      >
        {props.label ? <label>{toCaps(props.label)}</label> : null}
        {props.children}
      </div>
    );
  }

  if (props.children && props.searchableSelect) {
    inputComponent = (
      <div
        className={joinClassNames(
          classes.Input,
          props.noMarginLR ? classes.InputNoMarginLeftRight : "",
          props.medium ? classes["Input--medium"] : ""
        )}
        style={props.style}
        ref={element => {
          if (element) {
            element.style.setProperty("height", "auto", "important");
            element.style.setProperty("margin-bottom", "7.5px", "important");
          }
        }}
      >
        {props.label ? <label>{toCaps(props.label)}</label> : null}
        <div
          className={joinClassNames(
            classes.Input__Field,
            props.medium ? classes["Input__Field--medium"] : ""
          )}
          style={{
            padding: "0",
            border: "none",
            height: "auto"
          }}
        >
          {props.children}
        </div>
      </div>
    );
  }

  if (smallBtns) {
    inputComponent = (
      <div
        className={joinClassNames(
          classes.Input,
          props.noMarginLR ? classes.InputNoMarginLeftRight : "",
          props.medium ? classes["Input--medium"] : ""
        )}
        style={props.style}
      >
        <label>{toCaps(props.label)}</label>
        <div className={classes.Input__FieldWithBtn}>
          {props.children || (
            <input
              className={joinClassNames(
                classes.Input__Field,
                props.medium ? classes["Input__Field--medium"] : ""
              )}
              // defaultValue={props.defaultValue}
              value={props.defaultValue}
              disabled={props.disabled}
              readOnly={props.disabled}
              onChange={props.disabled ? null : props.onChange}
            />
          )}
          {smallBtns.map((smallBtn, index) => {
            // console.log(smallBtn);
            return (
              <span
                key={index}
                className={joinClassNames(
                  classes.Input__Btn,
                  classes.Input__SmallBtn
                )}
                onClick={smallBtn.onClick}
                style={smallBtn.style}
              >
                <smallBtn.Symbol fontSize={smallBtn.symbolSize} />
              </span>
            );
          })}
        </div>
      </div>
    );
  }

  if (bigBtn) {
    inputComponent = (
      <div
        className={joinClassNames(
          classes.Input,
          props.noMarginLR ? classes.InputNoMarginLeftRight : "",
          props.medium ? classes["Input--medium"] : ""
        )}
        style={props.style}
      >
        {props.label ? (
          <label>{props.noCaps ? props.label : toCaps(props.label)}</label>
        ) : null}
        <div
          className={joinClassNames(classes.Input__Btn, classes.Input__BigBtn)}
          onClick={props.bigBtn.onClick}
        >
          {toCaps(props.bigBtn.title)}
        </div>
      </div>
    );
  }

  return inputComponent;
};

export default Input;
