import * as actionTypes from "./types";
import axios from "../../share/js/axios";
import { TOKEN } from "../../share/js/constants";

// GET report_statuses
const getProductReportStatusesInit = _ => {
  return {
    type: actionTypes.GET_PRODUCT_REPORT_STATUSES
  };
};

const getProductReportStatusesSuccess = data => {
  return {
    type: actionTypes.GET_PRODUCT_REPORT_STATUSES_SUCCESS,
    payload: { ...data }
  };
};

const getProductReportStatusesFail = data => {
  return {
    type: actionTypes.GET_PRODUCT_REPORT_STATUSES_FAIL,
    payload: { ...data }
  };
};

export const clearGetProductReportStatusesState = _ => {
  return {
    type: actionTypes.CLEAR_GET_PRODUCT_REPORT_STATUSES_STATE
  };
};

export const getProductReportStatuses = _ => {
  return dispatch => {
    dispatch(getProductReportStatusesInit());

    axios
      .get("/productReportStatuses/", {
        headers: {
          "x-auth": localStorage.getItem(TOKEN)
        }
      })
      .then(res => {
        if (res.data.data.sqlMessage) {
          // skip to catch, will not execute below codes.
          throw res.data.data.sqlMessage;
        }

        let { data: productReportStatuses } = res.data;
        console.log("productReportStatuses", productReportStatuses);

        dispatch(getProductReportStatusesSuccess({ productReportStatuses }));
      })
      .catch(err => {
        let error = null;
        // console.log("throwed", err);

        if (!err.response) {
          error = err;
        } else {
          error = err.response.data;
        }

        dispatch(getProductReportStatusesFail({ error }));
      });
  };
};
