import Input from "../Input";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import classes from "./CustomDatePicker.scss";

const CustomDatePicker = props => {
  const { label, selected, onChange, style } = props;
  return (
    <Input label={label} style={style ? style.input : {}}>
      <div className={classes.CustomDatePickerWrapper}>
        <DatePicker
          selected={selected}
          onChange={onChange}
          className={classes.CustomDatePicker}
          style={style ? style.datePicker : {}}
          dateFormat={"dd/MM/yyyy"}
          disabled={props.disabled}
        />
      </div>
    </Input>
  );
};

export default CustomDatePicker;
