import React, { Component } from "react";
import ReactDOM from "react-dom";
import IosArrowDown from "react-ionicons/lib/IosArrowDown";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import classes from "./Account.scss";
import { textColor } from "../../../share/style/_variables";
import Aux from "../../../hoc/Aux/Aux";

class Account extends Component {
  onNavLinkClickedHandler = e => {
    // e.stopPropagation(); // stop click event bubbling up to parent
    // const node = ReactDOM.findDOMNode(this);
    // console.log(node);
  };

  render() {
    return (
      <li className={classes.NavigationItem} onClick={this.props.onClick}>
        <Aux onClick={this.onNavLinkClickedHandler}>
          <span className={[classes.Username, classes.DesktopOnly].join(" ")}>
            {this.props.username}
          </span>
          <span className={classes.Arrow}>
            <IosArrowDown color={textColor} fontSize={"22px"} />
          </span>
        </Aux>
      </li>
    );
  }
}

const mapStateToProps = state => {
  return {
    username: state.authReducer.username
  };
};

export default withRouter(connect(mapStateToProps)(Account));
