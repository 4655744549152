import React, { Component } from "react";
import { connect } from "react-redux";
import MdSearch from "react-ionicons/lib/MdSearch";

import classes from "./Vendor.scss";
import EnhancedTable from "./Table/EnhancedTable";
import Section from "./Section/Section";
import Form from "../Settings/Form/Form";
import Input from "../Settings/Form/Input/Input";
import { toCaps, toLower } from "../../share/js/utility";
import TextBtn from "../../components/TextBtn/TextBtn";
import { lang } from "../../share/js/localization";
import Select from "../Settings/Form/Select/Select";
import AddDialog from "./AddDialog/AddDialog";
import { searchBtnColor } from "../../share/style/_variables";
import ViewDialog from "./ViewDialog/ViewDialog";
import { acVendor } from "../../stores/actions";
// import ViewDialog from "./Dialog/Dialog_bu";

class Vendor extends Component {
  state = {
    nameOrEmail: "",
    openAddProductDialog: false,
    openViewProductDialog: false,
    viewProductData: {}
  };

  addProductDialogVisibilityHandler = () => {
    this.setState(state => ({
      openAddProductDialog: !state.openAddProductDialog
    }));
  };

  viewProductDialogVisibilityHandler = () => {
    this.setState(state => ({
      openViewProductDialog: !state.openViewProductDialog
    }));
  };

  addVendorHandler = () => {
    console.log(this.props.history);
    this.props.history.push({
      pathname: `${this.props.history.location.pathname}/add`
    });
    // this.addProductDialogVisibilityHandler();
  };

  viewVendorHandler = id => {
    this.props.history.push({
      pathname: this.props.history.location.pathname + "/" + id
    });
  };

  deleteProductHandler = () => {};
  editProductHandler = () => {};

  componentDidMount() {
    // scroll to top of the page
    window.scrollTo(0, 0);

    // FETCH ALL USERS FOR TABLE
    this.props.getAllVendors();
  }

  inputOnChangeHandler = (evt, section) => {
    const value = evt.target.value;
    // console.log(value);

    this.setState(state => {
      return {
        ...state,
        [section]: value
      };
    });
  };

  render() {
    // console.log("render::::", this.props.vendors);

    return (
      <div className={classes.Product}>
        <Section>
          <Form>
            <Input
              label={lang.nameOrEmail}
              onChange={evt => this.inputOnChangeHandler(evt, "nameOrEmail")}
            />
            <Input
              style={{ justifyContent: "flex-start", flexDirection: "row" }}
            >
              <TextBtn
                title={toCaps(lang.addVendor)}
                onClick={this.addVendorHandler}
                fit
                style={{}}
              />
            </Input>
          </Form>

          <EnhancedTable
            viewHandler={this.viewVendorHandler}
            nameOrEmail={this.state.nameOrEmail || ""}
            vendors={this.props.vendors}
            // searchedVendor={this.state.nameOrEmail}
          />
        </Section>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    vendors: state.vendorReducer.vendors
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAllVendors: () => dispatch(acVendor.getAllVendors())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Vendor);
