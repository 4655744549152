import React from "react";
import classes from "./SubMenu.scss";

const SubMenu = props => {
  return (
    <li className={classes.SubMenu} onClick={props.clicked}>
      <div>
        <span>
          <props.Symbol fontSize="22px" className={classes[props.className]} />
        </span>
        <span>{props.title}</span>
      </div>
    </li>
  );
};

export default SubMenu;
