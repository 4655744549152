// actions => do JOBS, reducers => change STATE base on action

import * as actionTypes from "./types";
import axios from "../../share/js/axios";
import { TOKEN } from "../../share/js/constants";

const fetching = _ => {
  return {
    type: actionTypes.REPORT_FETCHING
  };
};

const postReportSuccess = _ => {
  return {
    type: actionTypes.POST_REPORT_SUCCESS
  };
};

export const clearPostReportState = _ => {
  return {
    type: actionTypes.CLEAR_POST_REPORT_STATE
  };
};

const postReportFail = data => {
  return {
    type: actionTypes.POST_REPORT_FAIL,
    payload: { ...data }
  };
};
const postReportInit = _ => {
  return {
    type: actionTypes.POST_REPORT
  };
};

const getReportSuccess = data => {
  return {
    type: actionTypes.GET_REPORT_SUCCESS,
    payload: { ...data }
  };
};

const getReportFail = data => {
  return {
    type: actionTypes.GET_REPORT_FAIL,
    payload: { ...data }
  };
};

const getAllReportsSuccess = data => {
  return {
    type: actionTypes.GET_ALL_REPORTS_SUCCESS,
    payload: { ...data }
  };
};

const getAllReportsFail = data => {
  return {
    type: actionTypes.GET_ALL_REPORTS_FAIL,
    payload: { ...data }
  };
};

export const getAllReports = () => {
  return dispatch => {
    dispatch(fetching());

    axios
      .get("/reports", {
        headers: {
          "x-auth": localStorage.getItem(TOKEN)
        }
      })
      .then(res => {
        let { data: reports } = res.data;
        console.log("reports", reports);

        dispatch(getAllReportsSuccess({ reports }));
      })
      .catch(err => {
        // console.log("err /reports:", err);
        dispatch(getAllReportsFail({ error: err.response.data }));
      });
  };
};

export const getReportById = id => {
  return dispatch => {
    dispatch(fetching());

    axios
      .get("/reports/" + id, {
        headers: {
          "x-auth": localStorage.getItem(TOKEN)
        }
      })
      .then(res => {
        let { data: report } = res.data;
        console.log("report::::::", report);

        if (res.data.data.sqlMessage) {
          // skip to catch, will not execute below codes.
          throw res.data.data.sqlMessage;
        }

        dispatch(getReportSuccess({ report }));
      })
      .catch(err => {
        let error = null;
        console.log("throwed", err);

        if (!err.response) {
          error = err;
        } else {
          error = err.response.data;
        }

        dispatch(getReportFail({ error }));
      });
  };
};

// DELETE REPORT
const deleteReportInit = _ => {
  return {
    type: actionTypes.DELETE_REPORT
  };
};

const deleteReportSuccess = _ => {
  return {
    type: actionTypes.DELETE_REPORT_SUCCESS
  };
};

const deleteReportFail = data => {
  return {
    type: actionTypes.DELETE_REPORT_FAIL,
    payload: { ...data }
  };
};

export const clearDeleteReportState = _ => {
  return {
    type: actionTypes.CLEAR_DELETE_REPORT_STATE
  };
};

export const deleteReportById = id => {
  return dispatch => {
    dispatch(deleteReportInit());

    axios
      .get("/reports/delete/" + id, {
        headers: {
          "x-auth": localStorage.getItem(TOKEN)
        }
      })
      .then(res => {
        if (res.data.data.sqlMessage) {
          // skip to catch, will not execute below codes.
          throw res.data.data.sqlMessage;
        }

        dispatch(deleteReportSuccess());
      })
      .catch(err => {
        let error = null;
        // console.log("throwed", err);

        if (!err.response) {
          error = err;
        } else {
          error = err.response.data;
        }

        dispatch(deleteReportFail({ error }));
      });
  };
};

// UPDATE REPORT
const updateReportInit = _ => {
  return {
    type: actionTypes.UPDATE_REPORT
  };
};

const updateReportSuccess = _ => {
  return {
    type: actionTypes.UPDATE_REPORT_SUCCESS
  };
};

const updateReportFail = data => {
  return {
    type: actionTypes.UPDATE_REPORT_FAIL,
    payload: { ...data }
  };
};

export const clearUpdateReportState = _ => {
  return {
    type: actionTypes.CLEAR_UPDATE_REPORT_STATE
  };
};

export const updateReportById = (id, data) => {
  return dispatch => {
    dispatch(updateReportInit());

    // console.log("updateReportById::::", data);

    axios
      .post("/reports/" + id, data, {
        headers: {
          "x-auth": localStorage.getItem(TOKEN)
        }
      })
      .then(res => {
        if (res.data.data.sqlMessage) {
          // skip to catch, will not execute below codes.
          throw res.data.data.sqlMessage;
        }

        // this will also clear loading state, so no need to dispatch(updateReportSuccess())
        // dispatch(getReportById(id));
        dispatch(updateReportSuccess());
      })
      .catch(err => {
        let error = null;
        // console.log("throwed", err);

        if (!err.response) {
          error = err;
        } else {
          error = err.response.data;
        }

        dispatch(updateReportFail({ error }));
      });
  };
};

export const postReport = data => {
  return dispatch => {
    dispatch(postReportInit());

    axios
      .post("/reports", data, {
        headers: {
          "x-auth": localStorage.getItem(TOKEN)
        }
      })
      .then(res => {
        // let { data: report } = res.data;
        console.log("post report::::::", res);

        if (res.data.data.sqlMessage) {
          // skip to catch, will not execute below codes.
          throw res.data.data.sqlMessage;
        }

        dispatch(postReportSuccess());
      })
      .catch(err => {
        let error = null;
        console.log("throwed", err);

        if (!err.response) {
          error = err;
        } else {
          error = err.response.data;
        }

        dispatch(postReportFail({ error }));
      });
  };
};
