import React, { Component } from "react";
import MdSearch from "react-ionicons/lib/MdSearch";
import IosAdd from "react-ionicons/lib/IosAdd";

import Select from "../../../Settings/Form/Select/Select";
import Input from "../../../Settings/Form/Input/Input";
import { toLower } from "../../../../share/js/utility";
import DialogForm from "../../../../components/DialogForm/DialogForm";
import {
  searchBtnColor,
  primaryBtnColor
} from "../../../../share/style/_variables";
import { lang } from "../../../../share/js/localization";

const Content = props => {
  return (
    <DialogForm>
      <Input
        label={lang.vendor}
        defaultValue={props.inputValue.vendor}
        smallBtns={[
          {
            Symbol: MdSearch,
            onClick: props.vendorOnSearchHandler,
            symbolSize: "22px",
            style: {
              backgroundColor: searchBtnColor
            }
          }
        ]}
        disabled
        noMarginLR
      />
      <Input
        label={lang.name}
        defaultValue={props.inputValue.name}
        onChange={evt => props.handler.inputOnChangeHandler(evt, "name")}
        noMarginLR
      />

      <Input label={lang.category} noMarginLR>
        <Select
          // value={toLower(this.state.form.type)}
          value={toLower(props.inputValue.category)}
          onChange={evt => props.handler.inputOnChangeHandler(evt, "category")}
          options={[
            {
              text: "",
              value: ""
            },
            {
              text: "Hardware",
              value: "hardware"
            },
            {
              text: "Smartphone",
              value: "smartphone"
            }
          ]}
        />
      </Input>
      <Input
        label={lang.description}
        defaultValue={props.inputValue.description}
        onChange={evt => props.handler.inputOnChangeHandler(evt, "description")}
        style={{
          flexDirection: "column",
          alignItems: "flex-start",
          height: "auto"
        }}
        noMarginLR
      />
    </DialogForm>
  );
};

export default Content;
