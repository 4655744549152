import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import classes from "./Link.scss";
import { textColor } from "../../../../share/style/_variables";

class Link extends Component {
  onNavLinkClickedHandler = e => {
    // e.stopPropagation(); // stop click event bubbling up to parent
  };

  render() {
    return (
      <li className={classes.NavigationItem}>
        <NavLink
          to={this.props.link}
          activeClassName={classes.active}
          exact={this.props.exact}
          onClick={this.onNavLinkClickedHandler}
        >
          <span className={classes.Title}>{this.props.children}</span>
          <span className={classes.Symbol}>
            <this.props.Symbol color={textColor} fontSize={"22px"} />
          </span>
          <span className={classes.Indicator} />
        </NavLink>
      </li>
    );
  }
}

export default Link;
