import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import DialogForm from "../../../../components/DialogForm/DialogForm";
import Input from "../../Form/Input/Input";
import IosTrash from "react-ionicons/lib/IosTrash";

import classes from "./AddDialog.scss";
import TextBtn from "../../../../components/TextBtn/TextBtn";
import {
  toCaps,
  toLower,
  updateObject,
  checkIsEmail
} from "../../../../share/js/utility";
import { lang } from "../../../../share/js/localization";
import Select from "../../Form/Select/Select";
import {
  dangerColor,
  warningColor,
  primaryBtnColor
} from "../../../../share/style/_variables";
import AlertDialog from "../AlertDialog/AlertDialog";
import InfoDialog from "../../../../components/InfoDialog/InfoDialog";
import axios from "../../../../share/js/axios";
import { TOKEN } from "../../../../share/js/constants";

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit
  }
}))(MuiDialogActions);

class AddDialog extends React.Component {
  constructor() {
    super();

    this.state = {
      info: {
        customErrorMessage: "",
        customError: false,
        success: false,
        generalError: false
      },
      form: {
        username: "",
        email: "",
        password: "",
        isAdmin: 0,
        isActive: 1
      }
    };
  }

  handleClickOpen = () => {
    this.props.visibilityHandler();
  };

  handleClose = () => {
    this.props.visibilityHandler();

    // FETCH ALL USERS
    this.props.refetchUsers();
  };

  addHandler = async () => {
    let { username, email, isActive, isAdmin, password } = this.state.form;
    username = username.trim();

    if (username.trim().length < 6) {
      this.setState(state => ({
        info: {
          ...state.info,
          customError: true,
          customErrorMessage: "Username must be minimum 6 characters."
        }
      }));
      return;
    }

    if (!checkIsEmail(email)) {
      this.setState(state => ({
        info: {
          ...state.info,
          customError: true,
          customErrorMessage: "Email is not valid."
        }
      }));
      return;
    }

    // ADD USER
    const data = {
      email,
      username,
      isActive,
      isAdmin,
      password
    };

    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-auth": localStorage.getItem(TOKEN)
      }
    };

    // addUser passed from Settings.js
    // this.props.addUser(data);
    await axios
      .post("/users/register", data, config)
      .then(res => {
        const { sqlMessage } = res.data.data;

        if (sqlMessage) {
          this.setState(state => ({
            info: {
              ...state.info,
              customError: true,
              customErrorMessage: sqlMessage
            }
          }));

          return;
        }

        // show InfoDialog
        this.setState(state => ({
          info: {
            ...state.info,
            success: true,
            customErrorMessage: ""
          }
        }));
      })
      .catch(err => {
        console.log("err :::::::", err);

        this.setState(state => ({
          info: {
            ...state.info,
            generalError: true
          }
        }));
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  okInfoHandler = section => {
    let stateObj = state => ({
      info: {
        ...state.info,
        [section]: false
      }
    });

    if (section === "success") {
      stateObj = state => ({
        info: {
          ...state.info,
          success: false
        },
        form: {
          ...state.form,
          username: "",
          email: "",
          password: ""
        }
      });
    }

    this.setState(state => stateObj(state));
  };

  inputOnChangeHandler = (evt, section) => {
    let value = evt.target.value;

    this.setState(state =>
      updateObject(state, {
        form: {
          ...state.form,
          [section]: value
        }
      })
    );
  };

  render() {
    // console.log("this.props.history::::::::", this.props.history);

    const { username, email, isActive, isAdmin, password } = this.state.form;
    const {
      customErrorMessage,
      customError,
      success,
      generalError
    } = this.state.info;
    const { show } = this.props;

    return (
      <div>
        <InfoDialog
          show={generalError}
          message={lang.generalErrorMessage}
          okHandler={() => this.okInfoHandler("generalError")}
        />
        <InfoDialog
          show={customError}
          message={customErrorMessage}
          okHandler={() => this.okInfoHandler("customError")}
        />
        <InfoDialog
          show={success}
          message={lang.insertMessage}
          okHandler={() => this.okInfoHandler("success")}
        />
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={show}
          maxWidth="lg"
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            {this.props.title}
          </DialogTitle>
          <DialogContent className={classes.ViewDialog}>
            <DialogForm>
              <Input
                label="Username"
                defaultValue={username}
                onChange={evt => this.inputOnChangeHandler(evt, "username")}
              />
              <Input
                label="Email"
                defaultValue={email}
                onChange={evt => this.inputOnChangeHandler(evt, "email")}
              />
              <Input
                label="Password"
                defaultValue={password}
                onChange={evt => this.inputOnChangeHandler(evt, "password")}
              />
              <Input label={lang.type}>
                <Select
                  value={isAdmin}
                  onChange={evt => this.inputOnChangeHandler(evt, "isAdmin")}
                  options={[
                    {
                      text: "Staff",
                      value: 0
                    },
                    {
                      text: "Admin",
                      value: 1
                    }
                  ]}
                />
              </Input>
              <Input label="Status">
                <Select
                  value={isActive}
                  onChange={evt => this.inputOnChangeHandler(evt, "isActive")}
                  options={[
                    {
                      text: "active",
                      value: 1
                    },
                    {
                      text: "notactive",
                      value: 0
                    }
                  ]}
                />
              </Input>
            </DialogForm>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={this.handleClose} color="primary">
              Save changes
            </Button> */}
            <TextBtn
              title={toCaps(lang.add)}
              onClick={this.addHandler}
              fit
              style={{
                grouper: { justifyContent: "center" },
                btn: { background: primaryBtnColor }
              }}
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default AddDialog;
