import React, { Component } from "react";
import MdArrowDropdown from "react-ionicons/lib/MdArrowDropdown";
import MdArrowDropup from "react-ionicons/lib/MdArrowDropup";

import classes from "./MinimizeableInput.scss";
import { joinClassNames, getRandomInt } from "../../../../../share/js/utility";

class MinimizeableInput extends Component {
  state = {
    hide: true
  };

  visibilityHandler = () => {
    this.setState(state => {
      return {
        hide: !state.hide
      };
    });
  };

  render() {
    return (
      <div
        className={joinClassNames(
          classes.MinimizeableInput,
          this.state.hide ? classes.Hide : ""
        )}
      >
        <div className={classes.Header} onClick={this.visibilityHandler}>
          <span>{this.props.title}</span>
          <span style={{ display: "flex" }}>
            {this.state.hide ? (
              <MdArrowDropdown color="#fff" />
            ) : (
              <MdArrowDropup color="#fff" />
            )}
          </span>
        </div>
        <div className={classes.Content}>{this.props.children}</div>
      </div>
    );
  }
}

export default MinimizeableInput;
