import LocalizedStrings from "react-localization";

let strings = new LocalizedStrings({
  en: {
    products: "products",
    name: "name",
    description: "description",
    actions: "actions",

    // nav
    signOut: "sign out",
    accountSettings: "account settings",
    // settings
    userProfile: "user profile",
    user: "user",
    email: "email",
    username: "username",
    password: "password",
    add: "add",
    delete: "delete",
    edit: "edit",
    change: "change",
    changePassword: "change password",
    addUser: "add user",
    language: "language",
    // sidebar
    dashboard: "dashboard",
    product: "product",
    vendor: "vendor",
    report: "report",
    settings: "settings",
    saveChanges: "save changes",
    type: "type",
    // user
    deleteUser: "delete user",
    deleteSuccessMessage: "Data has been deleted successfully.",
    deleteMessage:
      " *This action cannot be undone!\nDo you want to delete this permanently?\n{0}",
    addMessage: " {0} added successfully.\n",
    updateMessage: "Update successful.\n",
    insertMessage: "Insert successful.\n",
    generalErrorMessage: "Something went wrong please try again.\n",
    cancel: "cancel",
    cancelEdit: "cancel edit",
    yes: "yes",
    viewUser: "View User",
    ok: "ok",
    addProduct: "add product",
    addCategory: "add brand",
    category: "brand",
    viewProduct: "View Product",
    address: "address",
    contact: "contact",
    phone: "phone",
    addVendor: "add vendor",
    back: "back",
    addReport: "add report",
    changePasswordNotSameMessage:
      "New password and confirm new password is not same.\n",
    changePasswordCurrentPasswordNotTrueMessage:
      "Current password is not true.\n",
    fieldMustBeFill: " {0} can't be empty.\n",
    generalSuccess: "Operation has been successfully completed",
    fieldAlreadyExistError:
      "The {0} already exists. Please use a different {0}",
    combinationOfTwo: "combination of {0} and {1}",
    CannotDeleteFieldInUseBy:
      "Cannot delete {0}. {0} is currently in used by {1}.",
    nameOrEmail: "Name or Email",
    addresses: "Addresses",
    address: "Address",
    emails: "Emails",
    email: "Email",
    phones: "Phones",
    phone: "Phone",
    fname: "First Name",
    lname: "Last Name",
    view: "View",
    status: "Status",
    confirmNewPassword: "Confirm New Password",
    newPassword: "New Password",
    currentPassword: "Current Password",
    usernameOrEmail: "Username or Email",
    customerName: "Customer Name",
    productSerialNumber: "Product serial number",
    entryDate: "entry date",
    productStatus: "Product Status",
    technician: "Receive by",
    buyDate: "Buy Date",
    returnDate: "Return Date",
    complement: "complement",
    explanation: "explanation",
    problem: "problem"
  },
  id: {
    actions: "aksi",
    description: "deskripsi",
    user: "user",
    products: "barang",
    name: "nama",

    // nav
    signOut: "keluar",
    accountSettings: "pengaturan akun",
    // settings
    userProfile: "user profil",
    email: "email",
    username: "username",
    password: "password",
    add: "tambah",
    delete: "hapus",
    edit: "edit",
    change: "ubah",
    changePassword: "ganti password",
    addUser: "tambah user",
    language: "bahasa",
    // sidebar
    dashboard: "dashboard",
    product: "barang",
    vendor: "vendor",
    report: "laporan",
    settings: "pengaturan",
    saveChanges: "simpan",
    type: "tipe",
    deleteUser: "hapus user",
    deleteMessage:
      " *Tindakan ini tidak bisa dibatalkan!\nApakah anda yakin ingin menghapus ini secara permanen?\n{0}",
    cancel: "batal",
    yes: "ya",
    viewUser: "Detil User",
    addMessage: " {0} Berhasil ditambahkan.\n",

    ok: "ok",
    addProduct: "tambah barang",
    addCategory: "tambah brand",
    category: "brand",
    viewProduct: "Detil Barang",
    address: "alamat",
    contact: "kontak",
    addVendor: "tambah vendor",
    back: "kembali",
    addReport: "tambah laporan",
    updateMessage: "Data berhasil diperbaharui.\n",
    insertMessage: "Data berhasil dibuat.\n",
    changePasswordNotSameMessage:
      "Password baru dan konfirmasi password baru tidak sama.\n",
    changePasswordCurrentPasswordNotTrueMessage: "password sekarang salah.\n",
    generalErrorMessage: "Terjadi kesalahan mohon periksa dan coba kembali.\n",
    deleteSuccessMessage: "Data telah berhasil dihapus.",
    phone: "telepon",
    fieldMustBeFill: " {0} tidak boleh kosong.\n",
    cancelEdit: "hapus perubahan",
    generalSuccess: "Aksi berhasil dilakukan.",
    fieldAlreadyExistError: "{0} telah terdaftar. Mohon coba gunakan {0} lain.",
    combinationOfTwo: "kombinasi antar {0} ini dan {1} ini",
    CannotDeleteFieldInUseBy:
      "Tidak dapat hapus {0}. {0} digunakan oleh {1} lain.",
    nameOrEmail: "Nama atau Email",
    addresses: "Alamat",
    address: "Alamat",
    emails: "Email",
    email: "Email",
    phones: "Telepon",
    phone: "Telepon",
    fname: "Nama Depan",
    lname: "Nama Belakang",
    view: "Lihat",
    status: "Status",
    confirmNewPassword: "Konfirmasi Password Baru",
    newPassword: "Password Baru",
    currentPassword: "Password Lama",
    usernameOrEmail: "Username atau Email",
    customerName: "Nama Customer",
    productSerialNumber: "Serial number",
    entryDate: "tanggal entri",
    productStatus: "Status Barang",
    technician: "Diterima oleh",
    buyDate: "Tanggal pembelian",
    returnDate: "Tanggal pengembalian",
    complement: "kelengkapan",
    explanation: "keterangan",
    problem: "masalah"
  }
});

// default id
strings.setLanguage(localStorage.getItem("language") || "id");

export { strings as lang };
