import React from "react";
import MdAdd from "react-ionicons/lib/MdAdd";
import MdRemove from "react-ionicons/lib/MdRemove";
import {
  joinClassNames,
  toLower,
  toCaps
} from "../../../../../share/js/utility";
import Input from "../../../../Settings/Form/Input/Input";
import classes from "./InputWithChild.scss";
import Aux from "../../../../../hoc/Aux/Aux";
import { lang } from "../../../../../share/js/localization";

const InputWithChild = props => {
  const { parentKey, headerTitle } = props; // parentKey for nested/mapped InputWithChild

  let list = props.data.map((dataValue, index) => (
    <li key={index}>
      <Input
        label={`${toCaps(lang[props.childTitle] || props.childTitle)} ${index +
          1}`}
        defaultValue={dataValue}
        onChange={evt =>
          props.inputOnChangeHandler(
            evt,
            parentKey
              ? `${parentKey}_${toLower(props.headerTitle)}_${index}`
              : toLower(`${props.headerTitle}_${index}`)
          )
        }
        noMarginLR
        medium
        style={{ margin: "7.5px 0" }}
        disabled={props.disabled}
      />
    </li>
  ));

  let addRemoveBtn = (
    <Aux>
      <span
        className={joinClassNames(classes.Btn, classes.Add)}
        onClick={props.addHandler}
      >
        <MdAdd fontSize={"16px"} />
      </span>

      {props.data.length > 1 ? (
        <span
          className={joinClassNames(classes.Btn, classes.Remove)}
          onClick={props.removeHandler}
        >
          <MdRemove fontSize={"16px"} />
        </span>
      ) : null}
    </Aux>
  );

  if (props.disabled) {
    addRemoveBtn = null;
  }

  return (
    <div className={classes.InputWithChild}>
      <div className={classes.Header}>
        <span>{toCaps(lang[props.headerTitle] || props.headerTitle)}:</span>
        {addRemoveBtn}
      </div>
      <ul>{list}</ul>
    </div>
  );
};

export default InputWithChild;
