import * as actionTypes from "../actions/types";
import { updateObject } from "../../share/js/utility";

const initialState = {
  token: null,
  userId: null,
  isAdmin: 0,
  username: "",
  email: "",
  error: null,
  loading: false,
  authRedirectPath: "/"
};

const authAuthenticating = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
};

const fail = (state, action) => {
  const { error } = action.payload;

  return updateObject(state, {
    error: error,
    loading: false
  });
};
const clearError = (state, action) => {
  return updateObject(state, {
    error: null
  });
};

const success = (state, action) => {
  const { token, userId, username, email, isAdmin } = action.payload;

  console.log(action);

  return updateObject(state, {
    token: token || state.token,
    userId: userId || state.userId,
    username: username || state.username,
    email: email || state.email,
    isAdmin: isAdmin || state.isAdmin,
    error: null,
    loading: false
  });
};
const signOut = (state, action) => {
  return updateObject(state, initialState);
};

const setRedirectPath = (state, action) => {
  const { path } = action.payload;

  return updateObject(state, {
    authRedirectPath: path
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_AUTHENTICATING:
      return authAuthenticating(state, action);
    case actionTypes.AUTH_SUCCESS:
      return success(state, action);
    case actionTypes.AUTH_FAIL:
      return fail(state, action);
    case actionTypes.AUTH_CLEAR_ERROR:
      return clearError(state, action);
    case actionTypes.AUTH_SIGN_OUT:
      return signOut(state, action);
    case actionTypes.SET_AUTH_REDIRECT_PATH:
      return setRedirectPath(state, action);
    default:
      break;
  }

  return state;
};

// const reducer = (state = initialState, action) => {
//   switch (action.type) {
//     case actionTypes.CHECK_AUTH_STATE:
//       return checkAuthState(state, action);
//     case actionTypes.SET_AUTH_REDIRECT_PATH:
//       return setAuthRedirectPath(state, action);
//     case actionTypes.AUTH_ON_PROCESS:
//       return authOnProcess(state, action);
//     case actionTypes.AUTH_SUCCESS:
//       return authSuccess(state, action);
//     case actionTypes.AUTH_FAIL:
//       return authFail(state, action);
//     case actionTypes.AUTH_SIGN_OUT:
//       return authSignOut(state, action);
//     default:
//       break;
//   }

//   return state;
// };

export default reducer;
