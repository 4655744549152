import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import MdSearch from "react-ionicons/lib/MdSearch";
import IosAdd from "react-ionicons/lib/IosAdd";

import classes from "./AddCategoryDialog.scss";

import {
  toCaps,
  toLower,
  updateObject,
  checkIsEmail
} from "../../../share/js/utility";

import {
  dangerColor,
  warningColor,
  primaryBtnColor,
  searchBtnColor
} from "../../../share/style/_variables";

import DialogForm from "../DialogForm/DialogForm";
import Input from "../../Settings/Form/Input/Input";
import Select from "../../Settings/Form/Select/Select";
import InfoDialog from "../../../components/InfoDialog/InfoDialog";
import TextBtn from "../../../components/TextBtn/TextBtn";
import { lang } from "../../../share/js/localization";

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit
  }
}))(MuiDialogActions);

class AddCategoryDialog extends React.Component {
  constructor() {
    super();

    this.state = {
      showAddedInfo: false,
      showCannotAddInfo: false,
      form: {
        name: ""
      },
      sendForm: {
        name: ""
      }
    };
  }

  handleClickOpen = () => {
    this.props.visibilityHandler();
  };

  handleClose = () => {
    this.props.visibilityHandler();
  };

  changeTypeHandler = evt => {
    const value = evt.target.value;

    this.setState(state =>
      updateObject(state, {
        form: {
          ...state.form,
          type: value
        }
      })
    );
  };
  changeStatusHandler = evt => {
    const value = evt.target.value;

    this.setState(state =>
      updateObject(state, {
        form: {
          ...state.form,
          status: value
        }
      })
    );
  };

  addHandler = () => {
    console.log("added info", this.state.form);

    let { name } = this.state.form;
    name = name.trim();

    if (name === "") {
      this.setState(state => ({
        ...state,
        showCannotAddInfo: true
      }));
      return;
    }

    this.setState(state => ({
      ...state,
      sendForm: {
        ...state.form
      },
      showAddedInfo: true
    }));
  };

  okInfoHandler = () => {
    // NOTE: because "modal close" has animation, it will retrieve
    // the empty name again in the "info dialog".

    // TODO: fixed "modal close" field problem
    // solution: create "state property" sendForm: {...form}
    this.setState(state => ({
      showAddedInfo: false,
      form: {
        ...state.form,
        name: ""
      }
    }));
  };

  okCannotAddInfoHandler = () => {
    this.setState(state => ({
      ...state,
      showCannotAddInfo: false
    }));
  };

  nameInputOnChangeHandler = evt => {
    const value = evt.target.value;

    this.setState(state =>
      updateObject(state, {
        form: {
          ...state.form,
          name: value
        }
      })
    );
  };

  render() {
    // console.log("render", this.props.viewedUserObj);

    // const { id, username, email, type, status } = this.props.viewedUserObj;
    const { name } = this.state.form;
    const { name: sendName } = this.state.sendForm;
    const { show } = this.props;

    return (
      <div>
        <InfoDialog
          show={this.state.showAddedInfo}
          title={toCaps(lang.category)}
          message={lang.formatString(lang.addMessage, `(NAME: ${sendName})`)}
          okHandler={this.okInfoHandler}
        />
        <InfoDialog
          show={this.state.showCannotAddInfo}
          title={toCaps(lang.category)}
          message={"Cannot add category. Please re-check and try again."}
          okHandler={this.okCannotAddInfoHandler}
        />
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={show}
          maxWidth="lg"
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            {this.props.title}
          </DialogTitle>
          <DialogContent className={classes.ViewDialog}>
            <DialogForm>
              <Input
                label={lang.name}
                defaultValue={name}
                onChange={this.nameInputOnChangeHandler}
              />
            </DialogForm>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={this.handleClose} color="primary">
              Save changes
            </Button> */}
            <TextBtn
              title={toCaps(lang.add)}
              onClick={this.addHandler}
              fit
              style={{
                grouper: { justifyContent: "center" },
                btn: { background: primaryBtnColor }
              }}
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

AddCategoryDialog.propTypes = {};

export default AddCategoryDialog;
