import React, { Component } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";

import logo from "./logo.svg";
// import './App.css';
import "./App.scss";
import Layout from "./containers/Layout/Layout";
import Dashboard from "./containers/Dashboard/Dashboard";
import Product from "./containers/Product/Product";
import Auth from "./containers/Auth/Auth";
import Settings from "./containers/Settings/Settings";
import Vendor from "./containers/Vendor/Vendor";
import AddVendor from "./containers/Vendor/AddVendor";
import ViewVendor from "./containers/Vendor/ViewVendor/ViewVendor";
import Report from "./containers/Report/Report";
import AddReport from "./containers/Report/AddReport";
import Logout from "./containers/Auth/Logout/Logout";
import { acAuth } from "./stores/actions";
import ViewReport from "./containers/Report/ViewReport/ViewReport";

class App extends Component {
  state = {
    currentPath: "/"
  };
  componentWillMount() {
    // trigger before render(), no problem because
    // "onTryAutoSignInAfterRefreshPage()" only
    // set reducer's state from localStorage
    // NO SIDE EFFECT
    this.props.onTryAutoSignInAfterRefreshPage();
  }

  render() {
    let routes = (
      <Switch>
        <Route path="/login" component={Auth} />
        <Redirect to="/login" />
      </Switch>
    );

    if (this.props.isAuthenticated) {
      routes = (
        <Switch>
          {/* /login will redirect to / if already authenticated */}
          <Route path="/login" component={Auth} />
          <Route path="/logout" component={Logout} />
          <Route path="/" component={Dashboard} exact />
          <Route path="/product" component={Product} />

          <Route path="/vendor/add" component={AddVendor} />
          <Route path="/vendor/:id" component={ViewVendor} />
          <Route path="/vendor" component={Vendor} />

          <Route path="/report/add" component={AddReport} />
          <Route path="/report/:id" component={ViewReport} />
          <Route path="/report" component={Report} />

          <Route path="/settings" component={Settings} />

          {/* <Redirect to={this.state.currentPath || "/"} /> */}
          <Redirect to="/login" />
        </Switch>
      );
    }

    return <Layout>{routes}</Layout>;
  }
}

const mapStateToProps = state => {
  return {
    // isAuthenticated: state.authReducer.token !== null,
    isAuthenticated: localStorage.getItem("token") !== null,
    loading: state.authReducer.loading,
    authReducer: state.authReducer,
    authRedirectPath: state.authReducer.authRedirectPath
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignInAfterRefreshPage: _ => dispatch(acAuth.checkAuthState(_)),
    setAuthenticating: _ => dispatch(acAuth.authenticating()),
    setAuthRedirectPath: path => dispatch(acAuth.setAuthRedirectPath(path))
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
