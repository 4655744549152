import _ from "lodash";

export const updateObject = (obj, newObj) => {
  return {
    ...obj,
    ...newObj
  };
};

export const ucFirst = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const queryStringToObject = search => {
  const query = new URLSearchParams(search);
  const obj = {};

  for (const pair of query.entries()) {
    obj[pair[0]] = +pair[1];
  }

  return obj;
};

export const objectToQueryString = obj => {
  return Object.keys(obj)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    .join("&");
};

export const extractPathBeforeFirstSlash = path => {
  const reg = /^[/][^/]*/gi;
  var arr = path.match(reg);

  return arr[0];
};

export const joinClassNames = (...classes) => {
  return classes.join(" ");
};

export const saveObjectToLocalStorage = obj => {
  for (const key in obj) {
    localStorage.setItem(key, obj[key]);
  }
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const toCaps = str => {
  // return _.startCase(_.toLower(str)); // startCase remove symbol!!!
  return _.toLower(str).replace(/\w+/g, _.capitalize);
};
export const toUpper = str => {
  return _.toUpper(_.toLower(str));
};
export const toLower = str => {
  return _.toLower(_.toLower(str));
};

//BOOL
export const checkIsEmail = str => {
  const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  return pattern.test(str);
};

// escape character before passing to real regex
export const escapeRegExp = string => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
};

export const getRandomInt = max => {
  return Math.floor(Math.random() * Math.floor(max));
};

export const getFormattedDate = (date, splitter = "/") => {
  let month = date.getMonth() + 1;
  month = month < 10 ? `0${month}` : month;

  let day = date.getDate();
  day = day < 10 ? `0${day}` : day;

  let year = date.getFullYear();

  return [day, month, year].join(splitter);
};

// ex output: [object String], [object Array], [object Object]
export const toString = obj => {
  return Object.prototype.toString.call(obj);
};

export const isObjEmpty = obj => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const isStringEmpty = value => {
  return value == null || value === "" || value.trim() === "";
};
