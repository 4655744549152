import React, { Component } from "react";
import MdSearch from "react-ionicons/lib/MdSearch";
import IosAdd from "react-ionicons/lib/IosAdd";
import MdArrowDropdown from "react-ionicons/lib/MdArrowDropdown";

import Select from "../../../Settings/Form/Select/Select";
import Input from "../../../Settings/Form/Input/Input";
import { toLower, toCaps } from "../../../../share/js/utility";
import DialogForm from "../../../../components/DialogForm/DialogForm";
import {
  searchBtnColor,
  primaryBtnColor,
  backBtnColor,
  warningColor,
  dangerColor,
  editBtnColor
} from "../../../../share/style/_variables";
import { lang } from "../../../../share/js/localization";
import InputWithChild from "./InputWithChild/InputWithChild";
import LabelWithBtn from "./LabelWithBtn/LabelWithBtn";
import MinimizeableInput from "./MinimizeableInput/MinimizeableInput";
import classes from "./Content.scss";
import TextBtn from "../../../../components/TextBtn/TextBtn";
import Aux from "../../../../hoc/Aux/Aux";

const Content = props => {
  const {
    addAddressHandler,
    removeAddressHandler,
    addEmailHandler,
    removeEmailHandler,
    addPhoneHandler,
    removePhoneHandler,
    addContactPersonHandler,
    removeContactPersonHandler,
    addContactPersonPhoneHandler,
    removeContactPersonPhoneHandler,
    inputOnChangeHandler,
    addContactPersonEmailHandler,
    removeContactPersonEmailHandler,
    backToVendorHandler,
    editHandler,
    cancelEditHandler,
    addVendorHandler,
    deleteVendorHandler,
    saveChangesHandler
  } = props.handler;

  const { mode, edit } = props;

  let editResetBtn = (
    <TextBtn
      title={toCaps(lang.edit)}
      onClick={editHandler}
      fit
      style={{
        grouper: { justifyContent: "center", marginRight: "auto" },
        btn: { background: editBtnColor }
      }}
    />
  );

  let saveChangesBtn = null;

  if (edit) {
    editResetBtn = (
      <TextBtn
        title={toCaps(lang.cancelEdit)}
        onClick={cancelEditHandler}
        fit
        style={{
          grouper: { justifyContent: "center", marginRight: "auto" },
          btn: { background: backBtnColor }
        }}
      />
    );

    saveChangesBtn = (
      <TextBtn
        title={toCaps(lang.saveChanges)}
        onClick={saveChangesHandler}
        fit
        style={{
          grouper: { justifyContent: "center" },
          btn: { background: warningColor }
        }}
      />
    );
  }

  return (
    <div>
      <div className={classes.Header}>
        <TextBtn
          title={toCaps(lang.back)}
          onClick={backToVendorHandler}
          fit
          style={{
            grouper: { justifyContent: "center" },
            btn: { background: backBtnColor }
          }}
        />
      </div>
      <div className={classes.Content}>
        <div className={classes.SubContent}>
          <DialogForm style={{ margin: "0" }}>
            <Input
              label={lang.name}
              defaultValue={props.inputValue.name}
              onChange={evt => inputOnChangeHandler(evt, "name")}
              noMarginLR
              medium
              style={{ margin: "7.5px 0" }}
              disabled={editHandler ? !edit : false}
            />
            <InputWithChild
              headerTitle={"addresses"}
              childTitle={"address"}
              addHandler={addAddressHandler}
              removeHandler={removeAddressHandler}
              inputOnChangeHandler={inputOnChangeHandler}
              data={props.inputValue.addresses}
              disabled={editHandler ? !edit : false}
            />
            <InputWithChild
              headerTitle={"emails"}
              childTitle={"email"}
              addHandler={addEmailHandler}
              removeHandler={removeEmailHandler}
              inputOnChangeHandler={inputOnChangeHandler}
              data={props.inputValue.emails}
              disabled={editHandler ? !edit : false}
            />
            <InputWithChild
              headerTitle={"phones"}
              childTitle={"phone"}
              addHandler={addPhoneHandler}
              removeHandler={removePhoneHandler}
              inputOnChangeHandler={inputOnChangeHandler}
              data={props.inputValue.phones}
              disabled={editHandler ? !edit : false}
            />
          </DialogForm>
        </div>

        <div className={classes.SubContent}>
          <DialogForm style={{ margin: "0" }}>
            <LabelWithBtn
              headerTitle={"Contact Persons"}
              addHandler={addContactPersonHandler}
              removeHandler={removeContactPersonHandler}
              data={props.inputValue.contactPersons}
              disabled={editHandler ? !edit : false}
            />

            {props.inputValue.contactPersons.map((contactPerson, index) => {
              const { fname, lname } = contactPerson;
              const subTitle =
                fname || lname
                  ? `${fname} ${lname}`
                  : `Contact Person ${index + 1}`;

              return (
                <MinimizeableInput key={index} title={subTitle}>
                  <Input
                    label={lang.fname}
                    defaultValue={fname}
                    onChange={evt =>
                      inputOnChangeHandler(evt, `contactPersons_${index}_fname`)
                    }
                    noMarginLR
                    medium
                    disabled={editHandler ? !edit : false}
                  />
                  <Input
                    label={lang.lname}
                    defaultValue={lname}
                    onChange={evt =>
                      inputOnChangeHandler(evt, `contactPersons_${index}_lname`)
                    }
                    noMarginLR
                    medium
                    disabled={editHandler ? !edit : false}
                  />

                  <InputWithChild
                    headerTitle={"emails"}
                    childTitle={"email"}
                    addHandler={() => addContactPersonEmailHandler(index)}
                    removeHandler={() => removeContactPersonEmailHandler(index)}
                    parentKey={`contactPersons_${index}`}
                    inputOnChangeHandler={inputOnChangeHandler}
                    data={contactPerson.emails}
                    disabled={editHandler ? !edit : false}
                  />

                  <InputWithChild
                    headerTitle={"phones"}
                    childTitle={"phone"}
                    addHandler={() => addContactPersonPhoneHandler(index)}
                    removeHandler={() => removeContactPersonPhoneHandler(index)}
                    parentKey={`contactPersons_${index}`}
                    inputOnChangeHandler={inputOnChangeHandler}
                    data={contactPerson.phones}
                    disabled={editHandler ? !edit : false}
                  />
                </MinimizeableInput>
              );
            })}
          </DialogForm>
        </div>
      </div>
      <div className={classes.Actions}>
        {mode === "view" ? (
          <Aux>
            {editResetBtn}
            <TextBtn
              title={toCaps(lang.delete)}
              onClick={deleteVendorHandler}
              fit
              style={{
                grouper: { justifyContent: "center" },
                btn: { background: dangerColor }
              }}
            />
            {saveChangesBtn}
          </Aux>
        ) : (
          <Aux>
            <TextBtn
              title={toCaps(lang.add)}
              onClick={addVendorHandler}
              fit
              style={{
                grouper: { justifyContent: "center" },
                btn: { background: primaryBtnColor }
              }}
            />
          </Aux>
        )}
      </div>
    </div>
  );
};

export default Content;
