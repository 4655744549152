import * as actionTypes from "../actions/types";
import { updateObject } from "../../share/js/utility";

const initialState = {
  reports: [],
  reportStatuses: [],
  report: {},
  loading: false,
  error: null,
  postReportSuccess: false,
  deleteReportSuccess: false,
  updateReportSuccess: false
};

// GET REPORT
const fetching = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
    postReportSuccess: false,
    deleteReportSuccess: false
  });
};

const getAllReportsSuccess = (state, action) => {
  const { reports } = action.payload;

  return updateObject(state, {
    reports,
    loading: false,
    error: null
  });
};

const getAllReportsFail = (state, action) => {
  const { error } = action.payload;

  return updateObject(state, {
    reports: [],
    error: error,
    loading: false
  });
};
const getReportSuccess = (state, action) => {
  const { report } = action.payload;

  return updateObject(state, {
    report,
    loading: false,
    error: null
  });
};

const getReportFail = (state, action) => {
  const { error } = action.payload;

  return updateObject(state, {
    report: {},
    error: error,
    loading: false
  });
};

// DELETE REPORT
const deleteReport = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
    deleteReportSuccess: false
  });
};

const deleteReportSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    deleteReportSuccess: true
  });
};

const deleteReportFail = (state, action) => {
  const { error } = action.payload;

  return updateObject(state, {
    loading: false,
    error: error,
    deleteReportSuccess: false
  });
};

const clearDeleteReportState = state => {
  return updateObject(state, {
    loading: false,
    error: null,
    deleteReportSuccess: false
  });
};

// UPDATE REPORT
const updateReport = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
    updateReportSuccess: false
  });
};

const updateReportSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    updateReportSuccess: true
  });
};

const updateReportFail = (state, action) => {
  const { error } = action.payload;

  return updateObject(state, {
    loading: false,
    error: error,
    updateReportSuccess: false
  });
};

const clearUpdateReportState = state => {
  return updateObject(state, {
    loading: false,
    error: null,
    updateReportSuccess: false
  });
};

// POST REPORT
const postReport = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
    postReportSuccess: false
  });
};

const postReportSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    postReportSuccess: true
  });
};
const postReportFail = (state, action) => {
  const { error } = action.payload;

  return updateObject(state, {
    loading: false,
    error: error,
    postReportSuccess: false
  });
};

const clearPostReportState = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    postReportSuccess: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_REPORTS_SUCCESS:
      return getAllReportsSuccess(state, action);
    case actionTypes.GET_ALL_REPORTS_FAIL:
      return getAllReportsFail(state, action);
    case actionTypes.GET_REPORT_SUCCESS:
      return getReportSuccess(state, action);
    case actionTypes.GET_REPORT_FAIL:
      return getReportFail(state, action);
    case actionTypes.REPORT_FETCHING:
      return fetching(state, action);

    case actionTypes.CLEAR_DELETE_REPORT_STATE:
      return clearDeleteReportState(state);
    case actionTypes.DELETE_REPORT:
      return deleteReport(state, action);
    case actionTypes.DELETE_REPORT_SUCCESS:
      return deleteReportSuccess(state, action);
    case actionTypes.DELETE_REPORT_FAIL:
      return deleteReportFail(state, action);

    case actionTypes.CLEAR_UPDATE_REPORT_STATE:
      return clearUpdateReportState(state);
    case actionTypes.UPDATE_REPORT:
      return updateReport(state, action);
    case actionTypes.UPDATE_REPORT_SUCCESS:
      return updateReportSuccess(state, action);
    case actionTypes.UPDATE_REPORT_FAIL:
      return updateReportFail(state, action);

    case actionTypes.CLEAR_POST_REPORT_STATE:
      return clearPostReportState(state, action);
    case actionTypes.POST_REPORT:
      return postReport(state, action);
    case actionTypes.POST_REPORT_SUCCESS:
      return postReportSuccess(state, action);
    case actionTypes.POST_REPORT_FAIL:
      return postReportFail(state, action);
    default:
      break;
  }

  return state;
};

export default reducer;
