import * as actionTypes from "../actions/types";
import { updateObject } from "../../share/js/utility";

const initialState = {
  reportStatuses: [],
  loading: false,
  error: null
};

// GET REPORT STATUSES
const getReportStatuses = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    reportStatuses: []
  });
};

const getReportStatusesSuccess = (state, action) => {
  const { reportStatuses } = action.payload;
  return updateObject(state, {
    loading: false,
    error: null,
    reportStatuses: reportStatuses
  });
};

const getReportStatusesFail = (state, action) => {
  const { error } = action.payload;

  return updateObject(state, {
    loading: false,
    error: error,
    reportStatuses: []
  });
};

const clearGetReportStatusesState = state => {
  return updateObject(state, {
    loading: false,
    error: null
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CLEAR_GET_REPORT_STATUSES_STATE:
      return clearGetReportStatusesState(state);
    case actionTypes.GET_REPORT_STATUSES:
      return getReportStatuses(state);
    case actionTypes.GET_REPORT_STATUSES_SUCCESS:
      return getReportStatusesSuccess(state, action);
    case actionTypes.GET_REPORT_STATUSES_FAIL:
      return getReportStatusesFail(state, action);
    default:
      break;
  }

  return state;
};

export default reducer;
