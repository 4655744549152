import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import DialogForm from "../../../../components/DialogForm/DialogForm";
import Input from "../../Form/Input/Input";
import IosTrash from "react-ionicons/lib/IosTrash";

import classes from "./ViewDialog.scss";
import TextBtn from "../../../../components/TextBtn/TextBtn";
import { toCaps, toLower, isObjEmpty } from "../../../../share/js/utility";
import { lang } from "../../../../share/js/localization";
import Select from "../../Form/Select/Select";
import { dangerColor, warningColor } from "../../../../share/style/_variables";
import AlertDialog from "../AlertDialog/AlertDialog";

import axios from "../../../../share/js/axios";
import { TOKEN } from "../../../../share/js/constants";
import InfoDialog from "../../../../components/InfoDialog/InfoDialog";

const DialogTitle = withStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500]
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles(theme => ({
  root: {
    borderTop: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit
  }
}))(MuiDialogActions);

class ViewDialog extends React.Component {
  constructor(props) {
    super(props);

    this.state = this.initialState();
  }

  initialState = () => ({
    info: {
      customErrorMessage: "",
      customError: false,
      success: false,
      updateSuccess: false,
      generalError: false
    },
    isAdmin: 0,
    selectedIsAdmin: 0,
    isActive: 1,
    selectedIsActive: 1,
    showDeleteAlert: false
  });

  // shouldComponentUpdate(props, n) {
  //   console.log("shouldComponentUpdate", props, n);

  //   return true;
  // }

  // TODO: if have time, check if "ViewDialog" still have error after "saveChanges"
  // CALLED EVERYTIME component state update
  componentWillReceiveProps(props) {
    const viewedUser = props.viewedUserObj;

    // set only when view different user OR first time view
    if (!isObjEmpty(viewedUser) && viewedUser.id != this.state.viewedUserId) {
      const { id, isAdmin, isActive } = props.viewedUserObj;

      this.setState({
        viewedUserId: id,
        isAdmin: isAdmin,
        selectedIsAdmin: isAdmin,
        isActive: isActive,
        selectedIsActive: isActive
      });
    }
  }

  handleClickOpen = () => {
    this.props.visibilityHandler();
  };

  handleClose = () => {
    this.props.visibilityHandler();

    // this.setState(this.initialState());
  };

  onChangeHandler = (evt, section) => {
    const value = evt.target.value;

    this.setState(state => ({
      ...state,
      [section]: value
    }));
  };

  resetPasswordHandler = async ({ id, email }) => {
    // alert(id + " " + email);
    const config = {
      headers: {
        // "Content-Type": "application/json",
        "x-auth": localStorage.getItem(TOKEN)
      }
    };

    // CHANGE PASSWORD
    await axios
      .post(
        "/users/resetPassword",
        {
          id,
          newPassword: "viriya"
        },
        config
      )
      .then(res => {
        const { sqlMessage } = res.data.data;

        if (sqlMessage) {
          this.setState(state => ({
            info: {
              ...state.info,
              customError: true,
              customErrorMessage: sqlMessage
            }
          }));

          return;
        }

        this.setState(state => ({
          info: {
            ...state.info,
            success: true,
            customErrorMessage: ""
          }
        }));
      })
      .catch(err => {
        // console.log("err /changePassword:", err);

        this.setState(state => ({
          info: {
            ...state.info,
            generalError: true
          }
        }));
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  saveChangesHandler = async id => {
    const { selectedIsAdmin, selectedIsActive } = this.state;
    let successCount = 2; // 0 means success

    // UPDATE USER
    const config = {
      headers: {
        "x-auth": localStorage.getItem(TOKEN)
      }
    };

    const isAdminPath = selectedIsAdmin == 1 ? "isAdmin" : "isNotAdmin";
    const isActivePath = selectedIsActive == 1 ? "unban" : "ban";

    await axios
      .get(`/users/${isAdminPath}/` + id, config)
      .then(res => {
        const { sqlMessage } = res.data.data;

        if (sqlMessage) {
          this.setState(state => ({
            info: {
              ...state.info,
              customError: true,
              customErrorMessage: sqlMessage
            }
          }));
        }

        successCount--;
      })
      .catch(err => {
        this.setState(state => ({
          info: {
            ...state.info,
            generalError: true
          }
        }));
      })
      .finally(() => {
        this.setState({ loading: false });
      });

    await axios
      .get(`/users/${isActivePath}/` + id, config)
      .then(res => {
        const { sqlMessage } = res.data.data;

        if (sqlMessage) {
          this.setState(state => ({
            info: {
              ...state.info,
              customError: true,
              customErrorMessage: sqlMessage
            }
          }));
        }

        successCount--;
      })
      .catch(err => {
        this.setState(state => ({
          info: {
            ...state.info,
            generalError: true
          }
        }));
      })
      .finally(() => {
        this.setState({ loading: false });
      });

    if (successCount == 0) {
      // show InfoDialog
      this.setState(state => ({
        info: {
          ...state.info,
          updateSuccess: true,
          customErrorMessage: ""
        }
      }));
    }
  };

  deleteHandler = () => {
    this.setState(state => ({
      ...state,
      showDeleteAlert: true
    }));
  };

  cancelDeleteUserHandler = () => {
    this.setState(state => ({
      ...state,
      showDeleteAlert: false
    }));
  };

  deleteUserHandler = async id => {
    this.setState(state => ({
      showDeleteAlert: false
    }));

    // TODO: dispatch
    // DELETE USER
    const config = {
      headers: {
        "x-auth": localStorage.getItem(TOKEN)
      }
    };

    await axios
      .get("/users/delete/" + id, config)
      .then(res => {
        const { sqlMessage } = res.data.data;

        // console.log("/users/delete/", res);
        if (sqlMessage) {
          this.setState(state => ({
            info: {
              ...state.info,
              customError: true,
              customErrorMessage: sqlMessage
            }
          }));

          return;
        }

        // show InfoDialog
        this.setState(state => ({
          info: {
            ...state.info,
            success: true,
            customErrorMessage: ""
          }
        }));
      })
      .catch(err => {
        this.setState(state => ({
          info: {
            ...state.info,
            generalError: true
          }
        }));
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  okInfoHandler = section => {
    let stateObj = state => ({
      info: {
        ...state.info,
        [section]: false
      }
    });

    if (section === "success") {
      // close dialog, re-fetch user
      this.props.refetchUsers();
      this.handleClose();
    }

    if (section === "updateSuccess") {
      this.props.refetchUsers();

      const { selectedIsAdmin, selectedIsActive } = this.state;

      console.log("updateSuccess", selectedIsAdmin, selectedIsActive);

      stateObj = state => ({
        info: {
          ...state.info,
          updateSuccess: false
        },
        isAdmin: selectedIsAdmin,
        selectedIsAdmin: selectedIsAdmin,
        isActive: selectedIsActive,
        selectedIsActive: selectedIsActive
      });

      console.log(this.state);
    }

    this.setState(state => stateObj(state));
  };

  render() {
    // console.log("render", this.props.viewedUserObj);

    const { id, username, email } = this.props.viewedUserObj;
    const { show } = this.props;

    const {
      customErrorMessage,
      customError,
      success,
      updateSuccess,
      generalError
    } = this.state.info;

    console.log(
      "render:::::",
      this.state.selectedIsAdmin,
      this.state.selectedIsActive
    );

    return (
      <div>
        <InfoDialog
          show={generalError}
          message={lang.generalErrorMessage}
          okHandler={() => this.okInfoHandler("generalError")}
        />
        <InfoDialog
          show={customError}
          message={customErrorMessage}
          okHandler={() => this.okInfoHandler("customError")}
        />
        <InfoDialog
          show={success}
          message={lang.deleteSuccessMessage}
          okHandler={() => this.okInfoHandler("success")}
        />
        <InfoDialog
          show={updateSuccess}
          message={lang.updateMessage}
          okHandler={() => this.okInfoHandler("updateSuccess")}
        />
        <AlertDialog
          show={this.state.showDeleteAlert ? true : false}
          title={toCaps(lang.deleteUser)}
          message={lang.formatString(
            lang.deleteMessage,
            `(USERNAME: ${username}, EMAIL: ${email})`
          )}
          noHandler={this.cancelDeleteUserHandler}
          yesHandler={() => this.deleteUserHandler(id)}
        />
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={show ? true : false}
          maxWidth="lg"
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            {this.props.title}
          </DialogTitle>
          <DialogContent className={classes.ViewDialog}>
            <DialogForm>
              <Input label="Username" defaultValue={username} disabled />
              <Input label="Email" defaultValue={email} disabled />
              <Input
                bigBtn={{
                  title: "Ubah Password",
                  onClick: () => this.resetPasswordHandler({ id, email })
                }}
                label="Password(default: viriya)"
                noCaps
              />
              <Input label={lang.type}>
                <Select
                  value={this.state.selectedIsAdmin}
                  onChange={evt => this.onChangeHandler(evt, "selectedIsAdmin")}
                  options={[
                    {
                      text: "Admin",
                      value: 1
                    },
                    {
                      text: "Staff",
                      value: 0
                    }
                  ]}
                />
              </Input>
              <Input label={lang.status}>
                <Select
                  value={this.state.selectedIsActive}
                  onChange={evt =>
                    this.onChangeHandler(evt, "selectedIsActive")
                  }
                  options={[
                    {
                      text: "active",
                      value: 1
                    },
                    {
                      text: "notactive",
                      value: 0
                    }
                  ]}
                />
              </Input>
            </DialogForm>
          </DialogContent>
          <DialogActions>
            <TextBtn
              title={toCaps(lang.delete)}
              onClick={this.deleteHandler}
              fit
              style={{
                grouper: { justifyContent: "center" },
                btn: { background: dangerColor }
              }}
            />
            {this.state.isActive == this.state.selectedIsActive &&
            this.state.isAdmin == this.state.selectedIsAdmin ? null : (
              <TextBtn
                title={toCaps(lang.saveChanges)}
                onClick={() => this.saveChangesHandler(id)}
                fit
                style={{
                  grouper: { justifyContent: "center" },
                  btn: { background: warningColor }
                }}
              />
            )}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

ViewDialog.propTypes = {
  viewedUserObj: PropTypes.object.isRequired
};

export default ViewDialog;
