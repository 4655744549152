import React, { Component } from "react";
import { connect } from "react-redux";

import classes from "./ViewDialog.scss";
import Dialog from "../Dialog/Dialog";
import { lang } from "../../../share/js/localization";
import Aux from "../../../hoc/Aux/Aux";
import InfoDialog from "../../../components/InfoDialog/InfoDialog";
import {
  toCaps,
  checkIsEmail,
  updateObject,
  isStringEmpty
} from "../../../share/js/utility";
import AddCategoryDialog from "../AddCategoryDialog/AddCategoryDialog";
import Actions from "./Actions/Actions";
import Content from "./Content/Content";
import { acProduct } from "../../../stores/actions";

class ViewDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      info: {
        fieldError: false,
        fieldErrorMessage: ""
      },
      edit: false,
      showAddedInfo: false,
      showCannotAddInfo: false,
      showAddCategoryDialog: false,
      form: this.formDefault()
    };
  }

  formDefault = _ => ({
    id: 0,
    name: "",
    description: "",
    category: {
      id: 0
    }
    // vendor: {
    //   id: null
    // }
  });

  componentWillReceiveProps(nextProps) {
    if (
      (isStringEmpty(this.state.form.name) ||
        this.state.form.name != nextProps.viewData.name) &&
      !this.state.edit
    ) {
      this.setState(state => ({
        edit: false,
        form: JSON.parse(JSON.stringify(nextProps.viewData))
      }));
    }
  }

  inputOnChangeHandler = (evt, key) => {
    const value = evt.target.value;

    this.setState(state =>
      updateObject(state, {
        form: {
          ...state.form,
          [key]: value
        }
      })
    );
  };

  /// title handler ///
  closeHandler = () => {
    this.props.visibilityHandler();

    this.setState({ edit: false });

    // RE-FETCH PRODUCTS
    this.props.getAllProducts();
  };

  /// Content handler ///
  changeAddCategoryDialogVisibility = () => {
    this.setState(state => ({
      ...state,
      showAddCategoryDialog: !state.showAddCategoryDialog
    }));
  };

  addCategoryHandler = () => {
    this.changeAddCategoryDialogVisibility();
  };

  deleteProductHandler = () => {
    const { id: productId } = this.state.form;

    // DELETE PRODUCT
    this.props.deleteProductById(productId);
    this.props.resetSearch();
  };

  saveChangesHandler = () => {
    let {
      id: productId,
      name,
      category: { id: categoryId },
      // vendor: { id: vendorId },
      description
    } = this.state.form;

    // if (vendorId == 0 || vendorId == null) {
    //   this.setState({
    //     info: {
    //       fieldError: true,
    //       fieldErrorMessage: lang.formatString(
    //         lang.fieldMustBeFill,
    //         `${toCaps(lang.vendor)}`
    //       )
    //     }
    //   });
    //   return;
    // }

    if (isStringEmpty(name)) {
      this.setState({
        info: {
          fieldError: true,
          fieldErrorMessage: lang.formatString(
            lang.fieldMustBeFill,
            `${toCaps(lang.name)}`
          )
        }
      });
      return;
    }

    if (categoryId == 0 || categoryId == null) {
      this.setState({
        info: {
          fieldError: true,
          fieldErrorMessage: lang.formatString(
            lang.fieldMustBeFill,
            `${toCaps(lang.category)}`
          )
        }
      });
      return;
    }

    // REMEMBER: in action(redux) we still need to parse,
    // categoryId => category_id,
    // vendorId => vendor_id
    // coz server use this format.
    const updateData = {
      name: name.trim(),
      categoryId,
      // vendorId,
      description
    };

    // UPDATE PRODUCT
    this.props.updateProductById(productId, updateData);
  };

  cancelEditHandler = () => {
    this.setState(state => ({
      edit: false,
      form: JSON.parse(JSON.stringify(this.props.viewData))
    }));
  };

  editHandler = () => {
    this.setState(state => ({
      edit: !state.edit
    }));
  };

  categoryOnChangeHandler = evt => {
    let categoryId = evt.target.value || 0;

    this.setState(state =>
      updateObject(state, {
        form: {
          ...state.form,
          category: { id: categoryId }
        }
      })
    );
  };

  // vendorOnChangeHandler = selectedOption => {
  //   const vendorId = selectedOption ? selectedOption.value : null;

  //   this.setState(state =>
  //     updateObject(state, {
  //       form: {
  //         ...state.form,
  //         vendor: { id: vendorId }
  //       }
  //     })
  //   );
  // };

  okInfoHandler = section => {
    if (section === "fieldError") {
      this.setState({
        info: {
          fieldError: false,
          fieldErrorMessage: ""
        }
      });
      return;
    }

    if (section === "deleteSuccess") {
      this.props.clearDeleteProductState();
      // this.props.history.replace({
      //   pathname: "/product"
      // });

      // CLOSE DIALOG AND BACK TO PRODUCT PAGE, REFRESH PRODUCTS
      this.closeHandler();
      this.props.getAllProducts();
      return;
    }

    if (section === "customError") {
      this.props.clearDeleteProductState();
    }

    if (section === "updateSuccess") {
      this.props.clearUpdateProductState();
    }
  };

  render() {
    const {
      showAddedInfo,
      showCannotAddInfo,
      showAddCategoryDialog
      // form
    } = this.state;
    // const {
    //   name,
    //   description,
    //   vendor: { value: vendor },
    //   category: { value: category }
    // } = form;

    const { show } = this.props;

    const { fieldError, fieldErrorMessage } = this.state.info;

    let deleteCustomError =
      !this.props.deleteProductSuccess && !isStringEmpty(this.props.error);
    let deleteSuccess =
      this.props.deleteProductSuccess && isStringEmpty(this.props.error);
    let updateSuccess =
      this.props.updateProductSuccess && isStringEmpty(this.props.error);

    let infoDialogs = null;

    if (show) {
      infoDialogs = (
        <Aux>
          <InfoDialog
            show={fieldError}
            message={fieldErrorMessage}
            okHandler={() => this.okInfoHandler("fieldError")}
          />
          <InfoDialog
            show={deleteCustomError}
            message={this.props.error}
            okHandler={() => this.okInfoHandler("customError")}
          />
          <InfoDialog
            show={deleteSuccess}
            message={lang.generalSuccess}
            okHandler={() => this.okInfoHandler("deleteSuccess")}
          />
          <InfoDialog
            show={updateSuccess}
            message={lang.generalSuccess}
            okHandler={() => this.okInfoHandler("updateSuccess")}
          />
        </Aux>
      );
    }

    return (
      <Aux>
        {infoDialogs}
        <Dialog
          show={show}
          closeHandler={this.closeHandler}
          title={lang.viewProduct}
          Actions={() => (
            <Actions
              handler={{
                editHandler: this.editHandler,
                cancelEditHandler: this.cancelEditHandler,
                saveChangesHandler: this.saveChangesHandler,
                deleteProductHandler: this.deleteProductHandler
              }}
              edit={this.state.edit}
            />
          )}
          Content={() => (
            <div className={classes.ViewDialog}>
              <Content
                handler={{
                  inputOnChangeHandler: this.inputOnChangeHandler,
                  // vendorOnChangeHandler: this.vendorOnChangeHandler,
                  categoryOnChangeHandler: this.categoryOnChangeHandler,
                  updateProductById: this.props.updateProductById
                }}
                viewData={{
                  ...this.state.form
                }}
                products={this.props.products}
                vendors={this.props.vendors}
                categories={this.props.categories}
                edit={this.state.edit}
              />
            </div>
          )}
        />
      </Aux>
    );
  }
}

const mapStateToProps = state => {
  return {
    categories: state.categoryReducer.categories,
    products: state.productReducer.products,
    vendors: state.vendorReducer.vendors,
    error: state.productReducer.error,
    deleteProductSuccess: state.productReducer.deleteProductSuccess,
    updateProductSuccess: state.productReducer.updateProductSuccess
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateProductById: (id, data) =>
      dispatch(acProduct.updateProductById(id, data)),
    deleteProductById: id => dispatch(acProduct.deleteProductById(id)),
    getAllProducts: _ => dispatch(acProduct.getAllProducts()),
    clearDeleteProductState: _ => dispatch(acProduct.clearDeleteProductState()),
    clearUpdateProductState: _ => dispatch(acProduct.clearUpdateProductState())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewDialog);
