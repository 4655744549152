import React, { Component, Fragment } from "react";

import Select from "react-select";
// import { colourOptions, flavourOptions } from "./docs/data";

export default class SearchableSelect extends Component {
  state = {
    isClearable: true,
    isSearchable: true
  };

  render() {
    let { options, onChange, defaultValue, isDisabled, value } = this.props;
    const { isClearable, isSearchable } = this.state;

    options = options || [];
    return (
      <Fragment>
        <Select
          isDisabled={isDisabled || false}
          className="basic-single"
          classNamePrefix="select"
          defaultValue={defaultValue || null}
          isClearable={isClearable}
          isSearchable={isSearchable}
          name="color"
          options={options}
          onChange={onChange}
        />
      </Fragment>
    );
  }
}
