import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import App from "./App";
// import './index.css';
import "./index.scss";
import * as serviceWorker from "./serviceWorker";
import { baseUrl } from "./share/js/config.json";
import navigationReducer from "./stores/Navigation/stores/reducers";
import {
  authReducer,
  userReducer,
  vendorReducer,
  productReducer,
  categoryReducer,
  reportReducer,
  reportStatusReducer,
  productReportStatusReducer
} from "./stores/reducers/index";

// console.log works only in development
if (process.env.NODE_ENV != "development") {
  console.log = () => {};
}

const rootReducer = combineReducers({
  navigationReducer,
  authReducer,
  userReducer,
  vendorReducer,
  productReducer,
  categoryReducer,
  reportReducer,
  reportStatusReducer,
  productReportStatusReducer
});

const composerEnhancers =
  (process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;

const middleware = [thunk];

const store = createStore(
  rootReducer,
  composerEnhancers(applyMiddleware(...middleware))
);

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
