import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import IosRemove from "react-ionicons/lib/IosRemove";

import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import green from "@material-ui/core/colors/green";
import TableHead from "./TableHead/TableHead";
import EnhancedTableToolbar from "./TableToolbar/TableToolbar";
import Toolbar from "./TableToolbar/TableToolbar";
import { lang } from "../../../share/js/localization";
import {
  toCaps,
  getFormattedDate,
  isStringEmpty,
  toLower,
  isObjEmpty
} from "../../../share/js/utility";
import TextBtn from "../../../components/TextBtn/TextBtn";
import { TableFooter } from "@material-ui/core";
import Input from "../../Settings/Form/Input/Input";
import CustomReactToPrint from "../../../components/CustomReactToPrint/CustomReactToPrint";
import Aux from "../../../hoc/Aux/Aux";
import ReportFormPrint from "../Dialog/ReportFormPrint/ReportFormPrint";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  {
    id: "id",
    numeric: false,
    disablePadding: false,
    // label: "Dessert (100g serving)"
    label: toCaps("id")
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: toCaps("status")
  },
  {
    id: "entry date",
    numeric: false,
    disablePadding: false,
    label: toCaps(lang.entryDate)
  },
  {
    id: "customer name",
    numeric: false,
    disablePadding: false,
    label: toCaps(lang.customerName)
  },

  {
    id: "actions",
    action: true,
    disablePadding: false,
    label: toCaps(lang.actions)
  }
];

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  table: {
    // minWidth: 800
  },
  tableWrapper: {
    overflowX: "auto"
  }
});

class EnhancedTable extends React.Component {
  state = {
    order: "asc",
    orderBy: "name",
    selected: [],
    page: 0,
    rowsPerPage: 5
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }

    this.setState({ order, orderBy });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
    // this.setState({ selected: [id] });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  // ONLY FOR fetchReportByIdToFormFormat()
  productServiceObj = _ => ({
    id: 0,
    name: "",
    serialNumber: "",
    category: { id: 0, name: "" }
    // vendor: { id: 0, name: "" }
  });

  fetchReportByIdToFormFormat = (
    fetchedReportById,
    fetchedProducts,
    fetchedVendors
  ) => {
    // console.log("fetchedReportById:::::", fetchedReportById);
    // console.log("fetchedProducts:::::", fetchedProducts);

    const report = fetchedReportById;
    const allproducts = fetchedProducts;
    const allvendors = fetchedVendors;

    if (!isObjEmpty(report) && (allproducts && allproducts.length > 0)) {
      let reportId,
        statusId,
        technician,
        entryDate,
        customer,
        description,
        products;

      const fetched = report;

      const { fname, lname, phones, emails } = fetched.customer;
      const parsedCustomerPhones = phones
        ? phones.map(phone => phone.phone_number)
        : [];
      const parsedCustomerEmails = emails
        ? emails.map(email => email.email)
        : [];

      reportId = fetched.report_id;
      statusId = fetched.report_status.id;
      technician = fetched.technician;
      entryDate = fetched.entry_at;
      customer = [
        {
          fname,
          lname,
          phones: parsedCustomerPhones,
          emails: parsedCustomerEmails
        }
      ];
      description = fetched.description;

      if (allproducts.length > 0) {
        const blacklistProductReportId = [];

        const parsedProducts = report.products
          .map(product => {
            let changeProduct = null;
            let changeProductData = null;

            // NOT NULL && NOT 0
            if (product.change_id != null && product.change_id != 0) {
              blacklistProductReportId.push(product.change_id);
              changeProduct = report.products.filter(
                f => f.id == product.change_id
              )[0];

              changeProductData = allproducts.filter(f => {
                return f.id == changeProduct.product_id;
              })[0];
            }

            const oriProductData = allproducts.filter(p => {
              return p.id == product.product_id;
            })[0];

            let vendorData = allvendors.filter(v => {
              return v.id == product.vendor_id;
            })[0];

            vendorData = vendorData || { id: 0, value: 0, name: "", label: "" };

            const oriProduct = {
              filterId: product.id,
              value: oriProductData.id, // for <RSelect />
              label: oriProductData.name, // for <RSelect />

              buyDate: product.buy_date,
              returnDate: product.return_date,
              explanationDescription: product.explanation_description,
              problemDescription: product.problem_description,
              complementDescription: product.complement_description,

              bill: product.bill,
              serialNumber: product.serialnumber,
              status: { id: product.report_product_status_id },
              id: oriProductData.id,
              name: oriProductData.name,
              category: {
                id: oriProductData.category.id,
                name: oriProductData.category.name
              },
              vendor: {
                ...vendorData,
                value: vendorData.id,
                label: vendorData.name
              }
            };

            if (changeProduct == null) {
              changeProduct = this.productServiceObj();
            } else {
              changeProduct = {
                value: changeProductData.id, // for <RSelect />
                label: changeProductData.name, // for <RSelect />

                serialNumber: changeProduct.serialnumber,
                id: changeProductData.id,
                name: changeProductData.name,
                category: {
                  id: changeProductData.category.id,
                  name: changeProductData.category.name
                },
                vendor: {
                  // id: changeProductData.vendor.id,
                  // name: changeProductData.vendor.name
                }
              };
            }

            return {
              ...JSON.parse(JSON.stringify(oriProduct)),
              changeProduct: JSON.parse(JSON.stringify(changeProduct))
            };
          })
          .filter(
            product => !blacklistProductReportId.includes(product.filterId)
          );

        // console.log(
        //   "parsedProducts::::",
        //   parsedProducts,
        //   blacklistProductReportId
        // );

        products = [...parsedProducts];

        console.log("fetched::::", products);
      }

      return {
        id: reportId,
        entryDate: entryDate,
        technician: technician,
        description: description,
        status: {
          id: statusId
        },
        contactPersons: customer,
        products: products
      };
    }

    return null;
  };

  render() {
    let data = this.props.reports.map(report => {
      /*
        from server: 
        {
          id: 1
          creator: {id: 3, username: "admin123"}
          customer: {id: 55, fname: "", lname: "", phones: Array(1), emails: Array(1)}
          description: null
          entry_at: "2019-05-11T06:05:31.000Z"
          products: []
          report_id: "15575547102293"
          report_status: {id: 1, title: "buka"}
          technician: "wqeqweqwe"
        }
      
      */

      const {
        id: dbReportId,
        report_id,
        report_status,
        entry_at,
        customer,
        products
      } = report;

      console.log("products::::::::", products);

      // NOTE: parsedProducts from database, so we use snake_case
      const parsedProducts =
        products.length > 0
          ? [
              ...products.map(product => ({
                id: product.product_id,
                serialnumber: product.serialnumber,
                report_product_status_id: product.report_product_status_id,
                vendor_id: product.vendor_id // NEED THIS, to be able to filter by vendor
              }))
            ]
          : [];
      return {
        print: { ...report },
        products: parsedProducts,
        dbReportId: dbReportId,
        id: report_id,
        status: { id: report_status.id, title: report_status.title },
        entryDate: new Date(entry_at),
        customerName: `${customer.fname} ${customer.lname}`,
        actions: ["view"]
      };
    });
    const { classes, searchTableData } = this.props;
    let { order, orderBy, selected, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    data = this.filterData(searchTableData, data);

    let componentToPrint = <div />;

    // console.log("tabledata:::::::::", data);

    let reportsPrintFormat = null;
    if (data.length > 0) {
      reportsPrintFormat = data.map(d => {
        const reportFormFormat = this.fetchReportByIdToFormFormat(
          d.print,
          this.props.products,
          this.props.vendors
        );

        const {
          id: reportId,
          status: { id: statusId },
          technician,
          entryDate,
          contactPersons: customer,
          description,
          products
        } = reportFormFormat;

        const inputValue = {
          id: reportId,
          statusId: statusId,
          technician: technician,
          entryDate: new Date(entryDate),
          contactPersons: customer,
          description: description || "",
          products: products,

          // for select-options
          reportStatuses: this.props.reportStatuses,
          productReportStatuses: this.props.productReportStatuses
        };

        return inputValue;
      });
    }

    // CONFIGURE PRINT FORM DATA
    const parsedInputValueToPrintFormat = reportPrintFormat => {
      const {
        id,
        statusId,
        technician,
        entryDate,
        contactPersons,
        description,
        products,

        // for select-options
        reportStatuses,
        productReportStatuses
      } = reportPrintFormat;

      // bill: product.bill,

      //           buy_date: product.buy_date,
      //           return_date: product.return_date,
      //           explanation_description: product.explanation_description,
      //           problem_description: product.problem_description,
      //           complement_description: product.complement_description

      // console.log("reportFormFormat::::", reportPrintFormat);

      const parsedProducts = products.map(p => {
        let change = {};
        let original = {};

        const originalStatus = productReportStatuses.filter(
          prs => prs.id == p.status.id
        )[0];

        if (originalStatus && toLower(originalStatus.title) == "garansi") {
          if (p.changeProduct) {
            change = {
              name: p.changeProduct.name,
              category: p.changeProduct.category.name,
              // vendor: p.changeProduct.vendor.name,
              serialNumber: p.changeProduct.serialNumber
            };
          }
        }

        original = {
          name: p.name,
          category: p.category.name,
          vendor: p.vendor.name,
          serialNumber: p.serialNumber,
          status: originalStatus ? originalStatus.title : "",

          buyDate: p.buyDate ? getFormattedDate(new Date(p.buyDate)) : "",
          returnDate: p.returnDate
            ? getFormattedDate(new Date(p.returnDate))
            : "",
          explanationDescription: p.explanationDescription,
          problemDescription: p.problemDescription,
          complementDescription: p.complementDescription
        };

        console.log("original::::::", products);

        if (originalStatus && toLower(originalStatus.title) == "servis") {
          original.bill = `Rp ${p.bill || 0}`;
        }

        if (originalStatus && toLower(originalStatus.title) == "garansi") {
          return {
            original,
            change
          };
        }

        return {
          original
        };
      });

      // console.log("products:::::::", parsedProducts);

      return {
        id: `${id}`,
        entryDate: getFormattedDate(entryDate),
        technician: technician,
        status: reportStatuses.filter(rs => rs.id == statusId)[0]
          ? reportStatuses.filter(rs => rs.id == statusId)[0].title
          : "",
        description: description,
        products: parsedProducts,
        customer: contactPersons[0]
      };
    };

    let printData = null;
    if (reportsPrintFormat && reportsPrintFormat.length > 0) {
      printData = reportsPrintFormat.map(reportPrintFormat =>
        parsedInputValueToPrintFormat(reportPrintFormat)
      );

      // console.log("data:::::", printData);

      componentToPrint = printData.map((r, index) => (
        <div key={`print_form_${index}`}>
          <ReportFormPrint data={r} />
        </div>
      ));
    }

    return (
      <Aux>
        {/* {componentToPrint} */}
        <Input
          style={{ justifyContent: "flex-end", flexDirection: "row" }}
          noMarginLR
        >
          <CustomReactToPrint componentToPrint={componentToPrint} />
        </Input>
        <Paper className={classes.root}>
          {/* <Toolbar
          numSelected={selected.length}
          title={this.props.toolbarTitle || "asd"}
        /> */}
          <div className={classes.tableWrapper}>
            <Table className={classes.table} aria-labelledby="tableTitle">
              <TableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                // onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
                rows={rows}
              />
              <TableBody>
                {stableSort(data, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map(n => {
                    const isSelected = this.isSelected(n.id);

                    console.log("asdasdass", n);
                    return (
                      <TableRow
                        hover
                        // onClick={event => this.handleClick(event, n.id)}
                        role="checkbox"
                        aria-checked={isSelected}
                        tabIndex={-1}
                        key={n.id}
                        selected={isSelected}
                      >
                        {/* <TableCell padding="checkbox">
                        <CustomCheckbox checked={isSelected} />
                      </TableCell> */}
                        <TableCell align="left">{n.id}</TableCell>
                        <TableCell align="left">{n.status.title}</TableCell>
                        <TableCell align="left">
                          {getFormattedDate(n.entryDate)}
                        </TableCell>
                        <TableCell align="left">{n.customerName}</TableCell>
                        {n.actions.map(action => {
                          if (action == "view") {
                            return (
                              <TableCell align="right" key={"actions" + n.id}>
                                <TextBtn
                                  onClick={() => {
                                    this.props.viewHandler(n.dbReportId);
                                  }}
                                  title={lang.view}
                                  fit
                                  style={{
                                    btn: { backgroundColor: "#ec407a" }
                                  }}
                                />
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    // colSpan={0}
                    // style={{ display: "flex" }}
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    // component="div"
                    backIconButtonProps={{
                      "aria-label": "Previous Page"
                    }}
                    nextIconButtonProps={{
                      "aria-label": "Next Page"
                    }}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </Paper>
      </Aux>
    );
  }

  filterData(searchObj, tableReportsData) {
    /* 
    
    searchObj: {
      id: "",
      technician: "",
      status: {
        id: 0
      },
      entryDate: [new Date(), new Date()],
      customerName: "",
      product: {
        id: 0,
        status: {
          id: 0
        },
        serialNumber: ""
      }
    },
    
    */
    const {
      id: reportId,
      status: { id: reportStatusId },
      entryDate,
      customerName,
      product: {
        id: productId,
        serialNumber: productSerialNumber,
        status: { id: productStatusId }
      },
      vendor: { id: vendorId }
    } = searchObj;
    const startEntryDateInMillis = entryDate[0]
      ? new Date(entryDate[0]).setHours(0, 0, 0, 0)
      : null;
    const endEntryDateInMillis = entryDate[1]
      ? new Date(entryDate[1]).setHours(24, 0, 0, 0)
      : null; // midnight

    // filter reportId
    if (!isStringEmpty(reportId)) {
      const regex = RegExp(`.*${reportId}.*`, "gi");
      tableReportsData = tableReportsData.filter(report => {
        regex.lastIndex = 0; // reset regex to enable reuse
        return regex.test(report.id);
      });
    }

    // filter customerName
    if (!isStringEmpty(customerName)) {
      const regex = RegExp(`.*${customerName}.*`, "gi");
      tableReportsData = tableReportsData.filter(report => {
        regex.lastIndex = 0; // reset regex to enable reuse
        return regex.test(report.customerName);
      });
    }

    // filter reportStatus
    if (reportStatusId != 0) {
      tableReportsData = tableReportsData.filter(report => {
        return reportStatusId == report.status.id;
      });
    }

    // filter date
    if (startEntryDateInMillis || endEntryDateInMillis) {
      tableReportsData = tableReportsData.filter(report => {
        let result = true;
        const entryDateInMillis = new Date(report.entryDate).getTime();

        if (
          startEntryDateInMillis &&
          entryDateInMillis < startEntryDateInMillis
        ) {
          result = false;
        }

        if (endEntryDateInMillis && entryDateInMillis > endEntryDateInMillis) {
          result = false;
        }

        return result;
      });
    }

    if (productId != 0 && productId != null) {
      tableReportsData = tableReportsData.filter(report => {
        // entryDate validate

        for (let i = 0; i < report.products.length; i += 1) {
          const product = report.products[i];

          if (productId == product.id) {
            return true;
          }
        }

        return false;
      });
    }

    if (productStatusId != 0 && productStatusId != null) {
      tableReportsData = tableReportsData.filter(report => {
        for (let i = 0; i < report.products.length; i += 1) {
          const product = report.products[i];

          if (productStatusId == product.report_product_status_id) {
            return true;
          }
        }

        return false;
      });
    }

    if (!isStringEmpty(productSerialNumber)) {
      tableReportsData = tableReportsData.filter(report => {
        for (let i = 0; i < report.products.length; i += 1) {
          const product = report.products[i];

          if (product.serialnumber.includes(productSerialNumber)) {
            return true;
          }
        }

        return false;
      });
    }

    if (vendorId != 0 && vendorId != null) {
      tableReportsData = tableReportsData.filter(report => {
        // console.log("vendorId:::::", vendorId, report);
        for (let i = 0; i < report.products.length; i += 1) {
          const product = report.products[i];

          if (vendorId == product.vendor_id) {
            return true;
          }
        }

        return false;
      });
    }

    return tableReportsData;
  }
}

// "properties" past into "this component"
EnhancedTable.propTypes = {
  // classes: PropTypes.object.isRequired
  // usernameOrEmail: PropTypes.string.isRequired
};

export default withStyles(styles)(EnhancedTable);
