import * as actionTypes from "../actions/types";
import { updateObject } from "../../share/js/utility";

const initialState = {
  categories: [],
  category: {},
  loading: false,
  error: null,
  postCategorySuccess: false,
  deleteCategorySuccess: false,
  updateCategorySuccess: false
};

// GET CATEGORY
const fetching = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
    postCategorySuccess: false,
    deleteCategorySuccess: false
  });
};

const getAllCategoriesSuccess = (state, action) => {
  const { categories } = action.payload;

  return updateObject(state, {
    categories,
    loading: false,
    error: null
  });
};

const getAllCategoriesFail = (state, action) => {
  const { error } = action.payload;

  return updateObject(state, {
    categories: [],
    error: error,
    loading: false
  });
};
const getCategorySuccess = (state, action) => {
  const { category } = action.payload;

  return updateObject(state, {
    category,
    loading: false,
    error: null
  });
};

const getCategoryFail = (state, action) => {
  const { error } = action.payload;

  return updateObject(state, {
    category: {},
    error: error,
    loading: false
  });
};

// DELETE CATEGORY
const deleteCategory = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
    deleteCategorySuccess: false
  });
};

const deleteCategorySuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    deleteCategorySuccess: true
  });
};

const deleteCategoryFail = (state, action) => {
  const { error } = action.payload;

  return updateObject(state, {
    loading: false,
    error: error,
    deleteCategorySuccess: false
  });
};

const clearDeleteCategoryState = state => {
  return updateObject(state, {
    loading: false,
    error: null,
    deleteCategorySuccess: false
  });
};

// UPDATE CATEGORY
const updateCategory = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
    updateCategorySuccess: false
  });
};

const updateCategorySuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    updateCategorySuccess: true
  });
};

const updateCategoryFail = (state, action) => {
  const { error } = action.payload;

  return updateObject(state, {
    loading: false,
    error: error,
    updateCategorySuccess: false
  });
};

const clearUpdateCategoryState = state => {
  return updateObject(state, {
    loading: false,
    error: null,
    updateCategorySuccess: false
  });
};

// POST CATEGORY
const postCategory = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null,
    postCategorySuccess: false
  });
};

const postCategorySuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    postCategorySuccess: true
  });
};
const postCategoryFail = (state, action) => {
  const { error } = action.payload;

  return updateObject(state, {
    loading: false,
    error: error,
    postCategorySuccess: false
  });
};

const clearPostCategoryState = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    postCategorySuccess: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_CATEGORIES_SUCCESS:
      return getAllCategoriesSuccess(state, action);
    case actionTypes.GET_ALL_CATEGORIES_FAIL:
      return getAllCategoriesFail(state, action);
    case actionTypes.GET_CATEGORY_SUCCESS:
      return getCategorySuccess(state, action);
    case actionTypes.GET_CATEGORY_FAIL:
      return getCategoryFail(state, action);
    case actionTypes.CATEGORY_FETCHING:
      return fetching(state, action);

    case actionTypes.CLEAR_DELETE_CATEGORY_STATE:
      return clearDeleteCategoryState(state);
    case actionTypes.DELETE_CATEGORY:
      return deleteCategory(state, action);
    case actionTypes.DELETE_CATEGORY_SUCCESS:
      return deleteCategorySuccess(state, action);
    case actionTypes.DELETE_CATEGORY_FAIL:
      return deleteCategoryFail(state, action);

    case actionTypes.CLEAR_UPDATE_CATEGORY_STATE:
      return clearUpdateCategoryState(state);
    case actionTypes.UPDATE_CATEGORY:
      return updateCategory(state, action);
    case actionTypes.UPDATE_CATEGORY_SUCCESS:
      return updateCategorySuccess(state, action);
    case actionTypes.UPDATE_CATEGORY_FAIL:
      return updateCategoryFail(state, action);

    case actionTypes.CLEAR_POST_CATEGORY_STATE:
      return clearPostCategoryState(state, action);
    case actionTypes.POST_CATEGORY:
      return postCategory(state, action);
    case actionTypes.POST_CATEGORY_SUCCESS:
      return postCategorySuccess(state, action);
    case actionTypes.POST_CATEGORY_FAIL:
      return postCategoryFail(state, action);
    default:
      break;
  }

  return state;
};

export default reducer;
