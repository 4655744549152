import React, { Component } from "react";

import { NativeSelect, InputBase, withStyles } from "@material-ui/core";

const BootstrapInput = withStyles(theme => ({
  root: {
    "label + &": {
      // marginTop: theme.spacing.unit * 3
      // margin: 0,
    }
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: "1rem",
    width: "auto",
    // height: "100%",
    padding: "4px 26px 4px 12px",
    // paddingLeft: "12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)"
    }
  }
}))(InputBase);

const Select = props => {
  return (
    <NativeSelect
      value={props.value}
      onChange={props.onChange}
      input={<BootstrapInput name="language" id="language" />}
      style={{ height: "100%" }}
      disabled={props.disabled}
    >
      {props.options.map((option, index) => {
        return (
          <option key={index} value={option.value}>
            {option.text}
          </option>
        );
      })}
    </NativeSelect>
  );
};

export default Select;
