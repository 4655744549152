import React, { Component } from "react";
import { lang } from "../../../../share/js/localization";
import Aux from "../../../../hoc/Aux/Aux";
import TextBtn from "../../../../components/TextBtn/TextBtn";
import { toCaps } from "../../../../share/js/utility";
import { primaryBtnColor } from "../../../../share/style/_variables";

const Actions = props => {
  return (
    <Aux>
      {/* <Button onClick={this.handleClose} color="primary">
              Save changes
            </Button> */}

      <TextBtn
        title={toCaps(lang.add)}
        onClick={props.handler.addProductHandler}
        fit
        style={{
          grouper: { justifyContent: "center" },
          btn: { background: primaryBtnColor }
        }}
      />
    </Aux>
  );
};

export default Actions;
