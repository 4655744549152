// actions => do JOBS, reducers => change STATE base on action

import * as actionTypes from "./types";
import axios from "../../share/js/axios";
import axiosDef from "axios";
import { TOKEN, USER_ID, EXPIRATION_DATE } from "../../share/js/constants";

export const authenticating = () => {
  return {
    type: actionTypes.AUTH_AUTHENTICATING
  };
};

const fail = data => {
  return {
    type: actionTypes.AUTH_FAIL,
    payload: { error: data }
  };
};

export const clearError = _ => {
  return {
    type: actionTypes.AUTH_CLEAR_ERROR
  };
};

//  {
//   username: username,
//   [TOKEN]: token,
//   [USER_ID]: id,
//   email: email
// }
export const success = data => {
  saveObjectToLocalStorage(data);

  return {
    type: actionTypes.AUTH_SUCCESS,
    payload: { ...data }
  };
};

export const setAuthRedirectPath = path => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    payload: { path }
  };
};

// export const changePassword = (currentPassword, newPassword) => {
//   return dispatch => {
//     const config = {
//       headers: {
//         "Content-Type": "application/json",
//         "x-auth": localStorage.getItem(TOKEN)
//       }
//     };

//     axios(
//       "/users",
//       {
//         currentPassword,
//         newPassword
//       },
//       config
//     )
//       .then(res => {
//         console.log("res /change:", res);
//         // dispatch(success(authData));
//       })
//       .catch(err => {
//         console.log("err /changePassword:", err);

//         // dispatch(fail(err.response.data));
//       });
//   };
// };

export const signIn = (usernameOrEmail, password) => {
  return dispatch => {
    dispatch(authenticating());

    // console.log("usernameOrEmail", usernameOrEmail, password);

    axios
      .post("/login", {
        usernameOrEmail,
        password
      })
      .then(res => {
        let { id, username, email, token, isAdmin } = res.data;
        console.log("res.data::::::::::", res.data);

        const authData = {
          username: username,
          [TOKEN]: token,
          [USER_ID]: id,
          email: email,
          isAdmin: isAdmin
        };
        // const authDataForLocalStorage = {
        //   [TOKEN]: token,
        //   [USER_ID]: id
        // };

        dispatch(success(authData));
      })
      .catch(err => {
        let errMessage =
          "Something wrong with the network or server, please try again.";

        if (!err.response) {
          // network error
          errMessage =
            "Something wrong with the network or server, please try again.";
        } else {
          errMessage = err.response.data.data.sqlMessage;
        }

        dispatch(fail(errMessage));
      });
  };
};

export const signOut = () => {
  clearLocalStorage();

  return {
    type: actionTypes.AUTH_SIGN_OUT
  };
};

export const checkAuthState = _ => {
  return dispatch => {
    // if (currentPath) {
    //   dispatch({
    //     type: actionTypes.SET_AUTH_REDIRECT_PATH,
    //     payload: {
    //       redirectPath: currentPath
    //     }
    //   });
    // }

    dispatch(authenticating());

    const token = localStorage.getItem(TOKEN);

    if (!token) {
      dispatch(signOut());
    } else {
      const userId = localStorage.getItem(USER_ID);
      const username = localStorage.getItem("username");
      const email = localStorage.getItem("email");
      const isAdmin = localStorage.getItem("isAdmin");

      dispatch(
        success({
          [TOKEN]: token,
          [USER_ID]: userId,
          username: username,
          email: email,
          isAdmin: isAdmin
        })
      );
    }
  };
};

const clearLocalStorage = () => {
  localStorage.removeItem(TOKEN);
  localStorage.removeItem(USER_ID);
  localStorage.removeItem("isAdmin");
  localStorage.removeItem("email");
  localStorage.removeItem("username");
  // localStorage.removeItem(EXPIRATION_DATE);
};

const saveObjectToLocalStorage = obj => {
  for (const key in obj) {
    localStorage.setItem(key, obj[key]);
  }
};
