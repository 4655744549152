import React, { Component } from "react";
import { connect } from "react-redux";
import IosMoreOutline from "react-ionicons/lib/IosMoreOutline";
import IosAdd from "react-ionicons/lib/IosAdd";
import IosRemove from "react-ionicons/lib/IosRemove";
import MdCreate from "react-ionicons/lib/MdCreate";

import Aux from "../../hoc/Aux/Aux";

import classes from "./Dashboard.scss";
import SubMenu from "./SubSection/SubMenu/SubMenu";
import CrudSubSection from "./SubSection/CrudSubSection";
import { toCaps } from "../../share/js/utility";
import { lang } from "../../share/js/localization";

class Dashboard extends Component {
  addHandler = section => {
    let path = section;

    if (section === "user") path = "settings";

    let pushObj = {
      pathname: `/${path}/add`
    };

    if (section === "product") {
      pushObj = {
        pathname: `/${path}`,
        state: { openAddProductDialog: true }
      };
    }

    if (section === "user") {
      pushObj = {
        pathname: `/${path}`,
        state: { openAddUserDialog: true }
      };
    }

    this.props.history.push(pushObj);
  };

  goToHandler = section => {
    let path = section;

    if (section === "user") path = "settings";

    this.props.history.push({
      pathname: `/${path}`
    });
  };

  componentDidMount() {
    // scroll to top of the page
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className={classes.Dashboard}>
        <div className={classes.SubSectionsTitle}>
          <h4>shortcut</h4>
        </div>
        {/* <div className={classes.Section}>x</div> */}
        <div className={classes.SubSections}>
          <CrudSubSection
            title={toCaps(lang.product)}
            addHandler={() => this.addHandler("product")}
            goToHandler={() => this.goToHandler("product")}
            // deleteHandler={this.deleteProductHandler}
            // editHandler={this.editProductHandler}
          />
          <CrudSubSection
            title={toCaps(lang.vendor)}
            addHandler={() => this.addHandler("vendor")}
            goToHandler={() => this.goToHandler("vendor")}
          />
          <CrudSubSection
            title={toCaps(lang.report)}
            addHandler={() => this.addHandler("report")}
            goToHandler={() => this.goToHandler("report")}
          />
          {this.props.isAdmin == 1 && (
            <CrudSubSection
              title={toCaps(lang.user)}
              addHandler={() => this.addHandler("user")}
              goToHandler={() => this.goToHandler("user")}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAdmin: state.authReducer.isAdmin || 0
  };
};

export default connect(mapStateToProps)(Dashboard);
